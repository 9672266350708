import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Productos para mascotas</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>PRODUCTOS PARA MASCOTAS</h1>
                        <video src="./Media/gato.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>
                            España es un país amante de los animales, y el creciente número de mascotas ha llevado a un aumento
                            significativo en la demanda de productos y servicios para ellos y desde la entrada en vigor de la ley de
                            bienestar animal, cobran más sentido las tiendas de productos para nuestros peludos.
                        </p>
                    </div>

                    <div className="textimgtext" id="textimgtext">
                        <p>Desde PANACEA estamos
                            en búsqueda constante para obtener alianzas con todos los participantes en el sector, por el momento os
                            dejamos aquí un listado por orden alfabético de algunas de las mejores tiendas de productos para mascotas en
                            España, donde se puede encontrar desde alimentos de alta calidad hasta accesorios únicos y servicios
                            especializados:</p>
                        <div>
                            <img src="./Media/gato/fila-1-columna-1.jpg" alt="" />
                            <p><a href="https://guaw.com/">GUAW</a> (antes PETULUKU)<br />

                                Cadena de tiendas que se enfoca en ofrecer productos de calidad y un excelente servicio al cliente. Son
                                conocidos por sus completas secciones de alimentos, juguetes y accesorios, así como por su compromiso
                                con el bienestar animal a través de campañas de adopción y eventos comunitarios.
                            </p>
                        </div>
                        <div>
                            <img src="Media/gato/fila-2-columna-1.jpg" alt="" />
                            <p><a href="https://www.kiwoko.com/">KIWOKO</a><br />
                                Es una de las cadenas más grandes de tiendas de productos para mascotas en España. Con más de 130
                                tiendas
                                repartidas por todo el país, ofrecen una amplia variedad de productos para todo tipo de mascotas,
                                incluidos perros, gatos, roedores, aves, reptiles y peces. Además de alimentos y juguetes, también
                                proporcionan servicios de peluquería y clínicas veterinarias. Cuentan además con programas de
                                fidelización con descuentos y promociones.
                            </p>
                        </div>
                        <div>
                            <img src="Media/gato/fila-3-columna-1.jpg" alt="" />
                            <p><a href="https://www.mascotasavila.com/">MASCOTAS ÁVILA</a><br />

                                Tienda familiar que se ha ganado un lugar en el corazón de los amantes de las mascotas en España. Con
                                más de 25 años de experiencia, ofrecen un trato personalizado y una selección de productos
                                cuidadosamente escogidos. Además, proporcionan servicio de asesoramiento nutricional y tienen una
                                peluquería canina muy recomendable.
                            </p>
                        </div>
                    </div>

                    <div className="parrafo">
                        <p>
                            Las tiendas de productos para mascotas en España están cada vez más diversificadas y especializadas,
                            ofreciendo
                            una apmplia gama de productos y servicios para satisfacer las necesidades de todas las mascotas y sus
                            dueños.
                            Todas se destacan por su compromiso con la calidad y el bienestar animal.<br /><br /> Sea cual sea tu
                            necesidad, seguro que
                            encontrarás la tienda que te ofrezca lo mejor para tu mascota.
                        </p>
                    </div>

                    <div className="textimgtext" id="textimgtext2">
                        <div>
                            <img src="./Media/perro/fila-1-columna-1.jpg" alt="" />
                            <p><a href="https://www.tiendanimal.es/">TIENDANIMAL</a><br />

                                Cuentan con más de 50 tiendas físicas y una potente plataforma online, se han convertido en un referente
                                para los turores de mascotas. Su catálogo incluye alimentos de marcas premium, suplementos, accesorios y
                                productos de higiene. También organizan eventos y charlas para fomentar el bienestar animal.
                            </p>
                        </div>
                        <div>
                            <img src="Media/perro/fila-2-columna-1.jpg" alt="" />
                            <p><a href="https://www.miscota.es/">MISCOTA</a><br />

                                Combinan la experiencia de compra online con la posibilidad de recoger los productos en tiendas físicas
                                asociadas. Su plataforma ofrece una amplia gama de productos para todas las mascotas, y cuentan con
                                promociones frecuentes que atraen a muchos compradores. Además, su blog proporciona consejos útiles
                                sobre el cuidado de mascotas.
                            </p>
                        </div>
                        <div>
                            <img src="Media/perro/fila-3-columna-1.jpg" alt="" />
                            <p><a href="https://www.zooplus.es/">ZOOPLUS</a><br />

                                Es una tienda online que ha ganado popularidad por su amplio catálogo y precios competitivos. Aunque no
                                cuenta con tiendas físicas en España, su presencia online es muy
                                fuerte, permitiendo a los clientes acceder a una variedad de productos internacionales. Son conocidos
                                por su eficiente servicio de entrega y su excelente atención al cliente.
                            </p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}