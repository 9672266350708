import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";

export default function () {
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Publicaciones</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleblue.css" />
                </Helmet>
                <div>
                    <div className="videocontainer2">
                        <a href="#Noticia">
                            <div className="videocontainer">
                                <h1>PUBLICACIONES</h1>
                                <video src="./Media/news.mp4" autoPlay muted loop playsInline></video>
                            </div>
                        </a>
                        <a href="#Blog">
                            <div className="videocontainer">
                                <h1>BLOG</h1>
                                <video src="./Media/news.mp4" autoPlay muted loop playsInline></video>
                            </div>
                        </a>
                    </div>

                    <div className="noticia" id="Noticia">
                        <div>
                            <img src="/Media/noticias/n1.png" alt="" />
                            <p>Amanda Montiel en LinkedIn: #valencia #dana #solidaridad
                                LOS LÍDERES DEL FUTURO SE HACEN EN EL PRESENTE Orgullosa de ver como los más pequeños se vuelcan a
                                ayudar a
                                su gente y de la inocencia pero a la vez…</p>
                            <a href="https://www.linkedin.com/posts/amanda-montiel_valencia-dana-solidaridad-activity-7259868630740258816-2KTZ?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="/Media/noticias/n2.png" alt="" />
                            <p>LinkedIn Noticias on LinkedIn: De las cerca de 494.000 personas que fallecieron en España en 2020, más
                                de…

                                De las cerca de 494.000 personas que fallecieron en España en 2020, más de 183.000 eran susceptibles de…
                            </p>
                            <a href="https://www.linkedin.com/posts/linkedin-noticias-espana_caerncer-humanicen-humano-activity-7254075495217061889-01TV?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>

                    <div className="noticia">
                        <div>
                            <img src="/Media/noticias/n3.png" alt="" />
                            <p>Alimentando el Cambio en LinkedIn: Esto te interesa Si eres profesional o universitario del ámbito de…

                                Esto te interesa Si eres profesional o universitario del ámbito de la Salud y la Nutrición, regístrate
                                en nuestra revista online para estar informado de…</p>
                            <a href="https://www.linkedin.com/posts/alimentando-el-cambio_alimentando-el-cambio-activity-7246868487942393858-F0CJ?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="/Media/noticias/n4.png" alt="" />
                            <p>Enrique Rodríguez en LinkedIn: Llega la IA para la biotecnología. Soy un recién llegado a esto de la…

                                Llega la IA para la biotecnología. Soy un recién llegado a esto de la Inteligencia Artificial sin
                                embargo, en conversaciones privadas, con algunos…
                            </p>
                            <a href="https://www.linkedin.com/posts/enrique-rodriguez-foodsandbeverages_llega-la-ia-para-la-biotecnolog%C3%ADa-soy-activity-7252558987978489856-cy8h?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>

                    <div className="noticia">
                        <div>
                            <img src="/Media/noticias/n5.png" alt="" />
                            <p>LinkedIn Top Startups 2024: las 20 empresas emergentes más prometedoras de España

                                Descubre las LinkedIn Top Startups 2024 de España, una clasificación respaldada por datos exclusivos de…
                            </p>
                            <a href="https://www.linkedin.com/pulse/linkedin-top-startups-2024-las-20-empresas-emergentes-7stzc"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="/Media/noticias/n6.png" alt="" />
                            <p>Tecnología sanitaria: incorporar innovación para lograr un sistema eficiente

                                Expertos en contratación pública sanitaria e industria coinciden en que la calidad debe ser determinante
                                en la compra pública de tecnología sanitaria innovadora…
                            </p>
                            <a href="https://www.eldiario.es/edcreativo/tecnologia-sanitaria-incorporar-innovacion-lograr-sistema-eficiente_1_11647348.html"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>

                    <div className="noticia">
                        <div>
                            <img src="/Media/noticias/n7.png" alt="" />
                            <p>10 cosas que debes saber sobre Yuka

                                Hay preguntas que siempre nos hacen sobre Yuka. Y la primera es, a menudo: «¿Cómo gana dinero Yuka?»Por eso, decidimos hacer un pequeño video para responder ...
                            </p>
                            <a href="https://www.youtube.com/watch?app=desktop&v=YamAvtFbKug&feature=youtu.be&ab_channel=Yuka"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="/Media/noticias/n8.png" alt="" />
                            <p>La aceleradora de empresas de Juan Roig creará con Google y Cofares una inteligencia artificial farmacéutica

                                Tratarán de crear un asistente conversacional multimodal que ayude a los profesionales a identificar medicamentos de venta libre y productos de sal...
                            </p>
                            <a href="https://www.eldebate.com/espana/comunidad-valenciana/20240911/aceleradora-empresas-juan-roig-creara-google-cofares-inteligencia-artificial-farmaceutica_226627.html"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>

                    <div className="noticia">
                        <div>
                            <img src="https://media.licdn.com/dms/image/v2/D561AAQHo-dhiTvmWWQ/storylineheaderimage-shrink_400_400/storylineheaderimage-shrink_400_400/0/1725547656767?e=2147483647&v=beta&t=JGlzUvULyAQmKQYUaXKJktfQi2ZLAqEn2csaamBNN00" alt="" />
                            <p>Menos futuros ingenieros, más futuros sanitarios | LinkedIn

                                Las ingenierías han perdido más de 30.000 matriculados en diez años, mientras que la rama que más crece es la de las Ciencias de la Salud. Aunque las ciencias sociales y jurídicas son imbatibles.
                            </p>
                            <a href="https://www.linkedin.com/news/story/menos-futuros-ingenieros-ms-futuros-sanitarios-6157588/"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="/Media/noticias/n10.png" alt="" />
                            <p>LinkedIn Noticias en LinkedIn: La sanidad pública sigue siendo el recurso fundamental para la mayoría de…

                                La sanidad pública sigue siendo el recurso fundamental para la mayoría de españoles ante un problema preocupante de salud, como asegura el profesor de…
                            </p>
                            <a href="https://www.linkedin.com/posts/linkedin-noticias-espana_sanidad-gobierno-trabajo-activity-7236308767254994944-ymeg?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>

                    <div className="noticia">
                        <div>
                            <img src="/Media/noticias/n11.png" alt="" />
                            <p>Marina Specht Blum en LinkedIn:

                                “¿Qué sentido tiene retrasar la muerte sino mejoramos la vida?” Es la pregunta que se hace el doctor Peter Attia, ex cirujano oncólogo, ex consultor de
                            </p>
                            <a href="https://www.linkedin.com/posts/marinaspecht_qu%C3%A9-sentido-tiene-retrasar-la-muerte-activity-7229772571804635136-aZW0?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="/Media/noticias/n12.png" alt="" />
                            <p>Alvin Foo en LinkedIn: Shared from Hypefury

                                The pharma companies love big corporations selling junk food as this will drive up various diseases that demand more drugs being sold. Consume natural and…
                            </p>
                            <a href="https://www.linkedin.com/posts/alvinfsc_shared-from-hypefury-ugcPost-7229712706981748736-Q1ZC?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>

                    <div className="noticia">
                        <div>
                            <img src="https://www.marketingdirecto.com/wp-content/uploads/2024/07/Diseno-sin-titulo-54-1.jpg" alt="" />
                            <p>Lo que la medicina me enseñó de marketing - Marketing Directo

                                Laura Riestra, Directora de Insights & Measurement de Arena España reflexiona sobre la importancia del largo plazo en las marcas.
                            </p>
                            <a href="https://www.marketingdirecto.com/punto-de-vista/la-columna/lo-que-medicina-ensena-del-marketing"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="/Media/noticias/n14.png" alt="" />
                            <p>MarketingDirecto.com en LinkedIn:

                                La ONG Salud por Derecho - Right to Health Foundation presenta su nueva campaña de sensibilización de la mano de McCann Worldgroup Spain destacando los…
                            </p>
                            <a href="https://www.linkedin.com/posts/marketingdirecto_la-ong-salud-por-derecho-right-to-health-activity-7219401270694969345-2zAH?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>

                    <div className="noticia">
                        <div>
                            <img src="/Media/noticias/n15.png" alt="" />
                            <p>Bogdán Gaone en LinkedIn:

                                Otro nuevo testimonio (doble, madre e hija) sobre la sanación a distancia. Cansancio crónico, dermatitis, psoriasis, bloqueo digestivo ¡Comparte el vídeo si…
                            </p>
                            <a href="https://www.linkedin.com/posts/gaone_otro-nuevo-testimonio-doble-madre-e-hija-activity-7213270545449029634-ok5l?utm_source=share&utm_medium=member_ios"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                        <div>
                            <img src="https://static.eldiario.es/clip/beea6afa-85d1-4049-9fb3-1ca7feb4c725_16-9-discover-aspect-ratio_default_0.webp" alt="" />
                            <p>
                                El Gobierno avanza en una nueva política farmacéutica que da más peso al Estado frente a la industria
                            </p>
                            <a href="https://www.eldiario.es/sociedad/gobierno-aprueba-nuevas-reglas-juego-dan-peso-frente-industria-farmaceutica_1_11888780.html"
                                target="_blank" rel="noopener noreferrer">Ver más</a>
                        </div>
                    </div>



                    <div className="videocontainer" id="Blog">
                        <h1>BLOG</h1>
                        <video src="./Media/news.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}