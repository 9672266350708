import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <Header />
            <Helmet>
                <title>PANACEA - Inicio</title>
                <link rel="stylesheet" href="/utils/style.css" />
            </Helmet>
            <div>
                <div className="videocontainerindex">
                    <div>
                        <h1>PANACEA</h1>
                        <h2>El valor de tu salud</h2>
                        <img src="Media/logo-panacea.png" id="foto" alt="" />
                        <h3>"DONDE HAY AMOR POR LA MEDICINA<br />
                            HAY AMOR POR LA HUMANIDAD"<br />
                            (Hipócrates)</h3>
                    </div>
                    <video src="./Media/redes.mp4" autoPlay muted loop playsInline></video>
                </div>

                <div className="parrafo">
                    <h2>¿QUÉ ES PANACEA?</h2>
                    <p>Un punto de encuentro online, único, donde tienes acceso a:</p>
                </div>

                <div className="texttocenter" id="alt1">
                    <div>
                        <h2>TERAPEUTAS</h2>
                    </div>
                    <div>
                        <h3>PROFESIONALES</h3>
                    </div>
                    <div>
                        <h2>ESPECIALISTAS</h2>
                    </div>
                </div>
                
                <div className="parrafo">
                    <p>También intervienen el resto de los participantes del sector médico
                        (laboratorios, seguros, asociaciones, apps especializadas, educación...)
                        con información actualizada en investigación.</p>
                </div>
                <Footer />
            </div>
        </>
    )
}