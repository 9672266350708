import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Nutrición para mascotas</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>NUTRICIÓN PARA GATOS</h1>
                        <video src="./Media/gato.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="animatedtitle">
                        <h2>Consejos de Nutrición</h2>
                        <h3>para tu gato</h3>
                    </div>

                    <div className="parrafo">
                        <p>
                            Alimentar a tu gato con la dieta adecuada puede ayudar a mantenerlo sano en el futuro. Comprender cuánto
                            alimentarlo darle es clave para mantenerlo en un peso saludable.<br /><br />

                            La alimentación adecuada no solo les proporciona todos los nutrientes que necesitan, sino que también puede
                            ayudar a mejorar su salud dental y regular su sistema digestivo.
                        </p>
                    </div>

                    <div className="parrafoleft">
                        <p id="parrafoleft">
                            <b>¿Cuál es la mejor dieta para un gato?</b><br />

                            Desafortunadamente, no hay una respuesta única, ya que la dieta "correcta" varía según las necesidades de tu
                            gato. Todos los gatos son carnívoros obligados, lo que significa que necesitan vitaminas, minerales y
                            proteínas específicas (por ejemplo, taurina y arginina) que solo se obtienen de la carne. Sin estos
                            nutrientes esenciales, tu gato puede enfermar gravemente; una deficiencia de taurina puede causar problemas
                            cardíacos y oculares graves e incluso ceguera.<br /><br />

                            <b>Cómo elegir el alimento adecuado para tu gato</b><br />

                            Hay varios tipos de comida para gatos disponibles, pero una comida equilibrada y de alta calidad siempre es
                            mejor que una alternativa de baja calidad. Sea cual sea el tipo que elijas, mira la lista de ingredientes.
                            Tu veterinario estará encantado de asesorarte sobre la dieta de tu gato teniendo en cuenta su edad, estilo
                            de vida y cualquier condición de salud existente.
                        </p>
                    </div>

                    <div className="parrafoformat">
                        <div>
                            <h2><b>&iquest;Qu&eacute; alimentos humanos son venenosos para los gatos?</b></h2>
                            <h3>Puede ser tentador compartir trozos de tus golosinas con tu gato, pero antes de hacerlo,
                                aseg&uacute;rate
                                de saber qu&eacute; alimentos pueden ser t&oacute;xicos para ellos.<br /><br /> La siguiente lista no es
                                exhaustiva,
                                y te recomendamos encarecidamente que consultes con el veterinario.</h3>
                            <ul>
                                <li>Cebolla</li>
                                <li>Ajo</li>
                                <li>Leche y productos l&aacute;cteos</li>
                                <li>Chocolate</li>
                                <li>Uvas y pasas</li>
                                <li>Comida para perros</li>
                            </ul>
                        </div>
                    </div>

                    <div className="parrafoleft">
                        <p id="parrafoleft">
                            <b>¿Debo darle a mi gato comida húmeda o seca?</b><br />
                            Muchos gatos son notoriamente quisquillosos cuando se trata de comida, por lo que, si bien hay beneficios
                            tanto en la comida húmeda como en la seca, la decisión puede deberse en última instancia a sus preferencias.
                            La comida seca es cómoda de almacenar y tiende a ser mejor para la salud dental de tu gato, ya que la
                            textura dura ayuda a eliminar la placa y el sarro, evitando que se acumulen alrededor de la línea de las
                            encías. También contiene mucha menos agua y tiene una mayor concentración de nutrientes, por lo que no es
                            necesaria tanta cantidad.<br /><br />
                            Por otro lado, los gatos a menudo no beben suficiente agua, por lo que la comida húmeda puede ayudar a
                            mantenerlos hidratados, también es mucho más fácil de comer para los gatos que se están recuperando de una
                            lesión u operación. Lo ideal, es que en torno a un 20% de la alimentación de tu gato sea un formato
                            húmedo.<br /><br />
                            <b>¿Debo seguir con la misma comida durante toda su vida?</b><br />
                            Las necesidades nutricionales de tu gato cambian a medida que envejece, por lo que es posible que debas
                            modificar su dieta. Por ejemplo, los gatitos menores de un año necesitarán una dieta acorde a su estilo de
                            vida enérgico al tiempo que apoye el desarrollo saludable del cerebro, los ojos y los huesos. Del mismo
                            modo, los gatos mayores de once años deberán cambiar a un alimento que mejore su movilidad y función
                            cerebral.

                        </p>
                    </div>

                    <div className="parraforight">
                        <p id="parraforight">
                            <b>¿Puede la dieta ayudar con los problemas de salud en los gatos?<br /></b>
                            Puede ser que tu gato necesite una dieta especial para ayudar a combatir los efectos de afecciones de salud
                            como diabetes, problemas renales, artrosis, problemas de la piel, afecciones digestivas o alergias
                            alimentarias. Si tu gato desarrolla alguna de estas afecciones a lo largo de su vida, es posible que tengas
                            que cambiar su dieta para adaptarla a sus necesidades. Como siempre, tu veterinario estará encantado de
                            recomendarte la mejor opción.<br /><br />
                            Si necesitas cambiar su dieta por cualquier motivo, es importante que lo hagas gradualmente, ya que un
                            cambio repentino puede alterar su estómago y hacer que pierda el apetito. Comienza agregando una pequeña
                            cantidad de la comida nueva a su comida habitual, aumenta gradualmente la cantidad de comida nueva (mientras
                            disminuyes la cantidad de la otra comida) en el transcurso de una semana hasta que coma solo la comida
                            nueva.

                        </p>
                    </div>

                    <div className="parrafoleft">
                        <h2>¿Cuánto debo alimentar a mi gato?</h2>
                        <p id="parrafoleft">
                            La cantidad que tu gato debe comer se relaciona con su raza, edad y tamaño. Todos los alimentos para gatos
                            tienen pautas en el envase y este suele ser un buen punto de partida. A continuación, tendrás que vigilar su
                            peso para saber si está comiendo demasiado o muy poco. A grandes rasgos, debes ser capaz de sentir, pero no
                            ver, las últimas dos o tres costillas cuando le miras desde un lado, y una cintura clara cuando miras desde
                            arriba. Tu veterinario también revisará el peso de tu gato en su revisión de salud para asegurarse de que
                            sea el correcto.<br /><br />
                            Una vez que hayas decidido la cantidad adecuada de comida para tu gato, es buena idea pesarla al comienzo
                            del día para evitar la sobrealimentación. Los gatos suelen estar sobrealimentados, lo que provoca un aumento
                            de peso y otros problemas de salud, por lo que este es un paso clave para mantener un peso saludable.

                        </p>
                    </div>

                    <div className="texttable">
                        <h2>¿Con qué frecuencia debo alimentar a mi gato?</h2>
                        <h3>Una vez que hayas decidido cuánto debe comer tu gato, puedes dividirlo en pequeñas comidas a lo largo del
                            día. Hay tres enfoques diferentes que puedes adoptar:</h3>
                        <div id="texttable">
                            <p>
                                • Alimentación con control de porciones donde divides su cantidad diaria en horarios de comida
                                establecidos, generalmente dos por día</p>
                            <p>
                                • Alimentación a demanda en la que la comida (generalmente pienso seco) está a su disposición durante
                                todo el día</p>
                            <p>
                                • Alimentación programada en la que los alimentos se ponen a su disposición durante un tiempo específico
                                y se retiran</p>
                        </div>
                        <h3>La alimentación con control de porciones suele ser la mejor opción para la mayoría de los gatos, ya que
                            puede evitar que coman en exceso y brinda a sus tutores la oportunidad de vigilar los hábitos alimenticios
                            de sus gatos. Si prefieres la alimentación a demanda en lugar de los horarios estrictos de las comidas, es
                            importante medir su cantidad diaria recomendada al comienzo del día y rellenar el tazón a partir de esa
                            cantidad. Una vez que se ha terminado, no añadir más.
                            Si tienes más de un gato, es importante asegurarte de que todos reciben su parte. No es raro que un gato
                            coma rápido y robe la comida al otro.
                            La constancia es clave, así que sigue siempre la rutina de alimentación que hayas elegido.
                        </h3>
                    </div>

                    <div className="parrafoleft">
                        <h2>¿Cuántas golosinas puedo darle a mi gato?</h2>
                        <p id="parrafoleft">
                            No hay nada de malo en darle alguna que otra golosina, pero es importante no excederse, ya que pueden ser
                            altamente calóricas. Elige siempre golosinas que sean bajas en azúcar y carbohidratos y limita la cantidad
                            que le das; las golosinas no deben constituir más del cinco al diez por ciento de la dieta de tu gato. Las
                            golosinas más grandes se pueden dividir en pedazos más pequeños, lo que significa que puedes darle a tu gato
                            recompensas sabrosas sin sobrealimentarlo.<br /><br />
                            Si decides darle una golosina a tu gato, es importante que reduzcas ligeramente su comida habitual para
                            equilibrar su ingesta diaria.
                        </p>
                    </div>

                    <div className="parraforight">
                        <h2>No te olvides de su hidratación</h2>
                        <p id="parraforight">
                            El agua es una parte esencial de la nutrición de tu gato, y no beber lo suficiente acarrea graves
                            consecuencias. Muchos gatos no beben tanto como deberían, por lo que es posible que debas buscar formas de
                            alentarlos activamente a mantenerse hidratados.<br /><br />
                            Si su cuenco de agua es profundo y estrecho, sus bordes pueden tocar los bigotes del gato, lo que puede
                            resultar incómodo, así que cambia a un cuenco más ancho y menos profundo. Asegúrate de que su agua esté
                            siempre fresca, limpia y disponible para fomentar el consumo regular de agua. Otra buena idea es colocar
                            cuencos de agua en diferentes lugares de tu casa.<br /><br />
                            Si tu gato aún no bebe lo suficiente, darle comida húmeda puede complementar su ingesta de agua.
                            En algunos casos, la falta de sed puede ser signo de una afección subyacente.<br /><br /> Habla con tu veterinario si
                            tienes alguna inquietud.

                        </p>
                    </div>

                    <div className="parrafoleft">
                        <h2>¿Puedo darle de beber leche a mi gato?</h2>
                        <p id="parrafoleft">
                            Es un error común pensar que los gatos necesitan beber leche. Tienden a ser intolerantes a la lactosa, por
                            lo
                            que darles leche puede causarles problemas digestivos.<br /><br />
                            Los gatos solo necesitan agua para hidratarse e incluso la leche para gatos especialmente formulada no es
                            buena
                            ya que por su alto contenido calórico puede causar problemas de peso. Cíñete a mucha agua, fresca y limpia,
                            y no
                            te equivocarás.<br /><br />
                            Si te preocupa la dieta de tu gato o tienes preguntas sobre el alimento adecuado, habla con tu veterinario.
                        </p>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}