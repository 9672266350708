import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/scriptazul';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Terapias Completas</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleblue.css" />
                </Helmet>
                <div>
                    <div className="videocontainerout">
                        <div className="videocontainer">
                            <h1>TERAPIAS</h1>
                            <p>TODA LA INFORMACIÓN SOBRE TERAPIAS</p>
                            <video src="./Media/cuadradosazules.mp4" autoPlay muted loop playsInline></video>
                        </div>
                    </div>

                    <div className="parrafo">
                        <p>Profesionales con terapias y soluciones médicas<br/> dentro del marco legal
                            y como opción a la medicina oficial:</p><br/>
                    </div>

                    <div className="bigcards" id="bigcards">
                            <div>
                                <a href="#INTEGRALES">
                                    <section>
                                        <img src="./Media/complementaria.png" />
                                        <p>Integrales</p>
                                    </section>
                                </a>
                            </div>
                        <div>
                            <a href="#FISIO">
                                <section>
                                    <img src="./Media/alternativas.png" alt="" />
                                    <p>Fisio-Biológicas</p>
                                </section>
                            </a>
                        </div>
                        <div>
                            <a href="#CUERPO">
                                <section>
                                    <img src="./Media/integrativas.png" alt="" />
                                    <p>Cuerpo</p>
                                </section>
                            </a>
                        </div>
                        <div>
                            <a href="#MENTE">
                                <section>
                                    <img src="./Media/alternativas.png" alt="" />
                                    <p>Mente</p>
                                </section>
                            </a>
                        </div>
                        <div>
                            <a href="#ENERGIAS">
                                <section>
                                    <img src="./Media/complementaria.png" alt="" />
                                    <p>Energía</p>
                                </section>
                            </a>
                        </div>
                    </div>

                    <div className="animatedtitle" id="INTEGRALES">
                        <h2>3.1. Sistemas m&eacute;dicos integrales o completos:</h2>
                        <h3>(Sanaci&oacute;n evolucionada a lo largo
                            del
                            tiempo en distintas culturas y partes del mundo)</h3>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/homeopathy"
                                target="_blank"><strong>Homeopat&iacute;a</strong></a>:
                            </p>
                            <ol>
                                <li>Considera que la sustancia que causa enfermedad en grandes dosis, suministrada en muy peque&ntilde;a
                                    cantidad, estimula los mecanismos innatos curativos del organismo.</li>
                                <li>Se pretende restablecer el flujo de la fuerza vital del cuerpo seg&uacute;n las
                                    caracter&iacute;sticas &uacute;nicas de cada persona (incluida la personalidad, el estilo de vida,
                                    s&iacute;ntomas y estado general de la salud.</li>
                                <li>Remedios derivados de sustancias naturales (vegetales, animales y minerales).</li>
                                <li>De venta libre o con receta en Estados Unidos donde est&aacute;n clasificados por la <a
                                    href="https://www.fda.gov/about-fda/fda-en-espanol" target="_blank"><strong>FOOD AND DRUG
                                        ADMINISTRATION</strong></a>&nbsp;(FDA):</li>
                                <li>No exigencia:
                                    <ol>
                                        <li>Identidad y potencia de cada principio activo.</li>
                                        <li>Demostraci&oacute;n de su eficacia.</li>
                                        <li>L&iacute;mites en la utilizaci&oacute;n de alcohol.</li>
                                    </ol>
                                </li>
                                <li>Exigencia:
                                    <ol>
                                        <li>Identificaci&oacute;n como producto &ldquo;homeop&aacute;tico&rdquo;.</li>
                                        <li>Un modo de empleo.</li>
                                        <li>Instrucciones para su uso seguro.</li>
                                        <li>Principio activo y cantidad de diluci&oacute;n.</li>
                                    </ol>
                                </li>
                                <li>Usos:
                                    <ul>
                                        <li>Trastornos, alergias, s&iacute;ntomas respiratorios, problemas digestivos, dolores
                                            musculoesquel&eacute;ticos y v&eacute;rtigo.</li>
                                        <li>Efectos secundarios:
                                            <ul>
                                                <li>Pocos riesgos.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/naturopat%c3%ada"><strong>Medicina
                                    naturista o Naturopat&iacute;a</strong></a><strong>:</strong></p>
                            <ol>
                                <li>Basada en el poder curativo de la naturaleza y el propio cuerpo.</li>
                                <li>Filosof&iacute;a integral de tratamiento de la persona.</li>
                                <li>No se tratan s&oacute;lo los s&iacute;ntomas, sino que se buscan igualmente las causas de la
                                    enfermedad.</li>
                                <li>Prevenci&oacute;n y tratamiento de enfermedades mediante un estilo de vida saludable.</li>
                                <li>Tipos de terapias (generalmente combinaci&oacute;n de varias):
                                    <ul>
                                        <li>Dieta</li>
                                        <li>Suplementos nutricionales.</li>
                                        <li>Hierbas medicinales.</li>
                                        <li>Acupuntura</li>
                                        <li>Terapias f&iacute;sicas (calor o fr&iacute;o, ultrasonograf&iacute;a y masaje).</li>
                                        <li>Terapias cuerpo-mente.</li>
                                        <li>Terapia de ejercicio.</li>
                                        <li>Hidroterapia (aplicaciones de agua caliente o fr&iacute;a en movimiento).</li>
                                    </ul>
                                </li>
                                <li>Aplicaciones principales:
                                    <ul>
                                        <li>Cardiopat&iacute;as, dolor, diabetes de tipo 2 y trastornos mentales y cr&oacute;nicos.</li>
                                    </ul>
                                </li>
                                <li>Puntos de semejanza con Ayurveda y la medicina tradicional china.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/traditional-chinese-medicine-tcm"><strong>Medicina
                                    tradicional china</strong></a>:</p>
                            <ol>
                                <li>Medicina milenaria originada en China.</li>
                                <li>La enfermedad es el resultado del desequilibrio de la fuerza vital (qi, pronunciado
                                    &ldquo;chee&rdquo;) a trav&eacute;s del cuerpo, y se reestructura equilibrando el yin (fuerzas
                                    negativas) y el yang (fuerzas positivas), que se manifiestan en el cuerpo como fr&iacute;o y calor,
                                    interno y externo, deficiencia y exceso.</li>
                                <li>Pr&aacute;cticas m&aacute;s habituales:
                                    <ul>
                                        <li>Acupuntura</li>
                                        <li>Hierbas medicinales.</li>
                                        <li>Qi gong.</li>
                                        <li>Dieta</li>
                                        <li>Masaje</li>
                                    </ul>
                                </li>
                                <li>Usos medicinales:
                                    <ul>
                                        <li>S&iacute;ndrome del intestino irritable.</li>
                                        <li>S&iacute;ndrome de Tourette.</li>
                                        <li>Quimioterapia.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/ayurveda"><strong>Ayurveda</strong></a>:
                            </p>
                            <ol>
                                <li>Sistema m&eacute;dico tradicional de la India, originado hace m&aacute;s de 4.000 a&ntilde;os.</li>
                                <li>La enfermedad es el resultado del desequilibrio de la fuerza vital del cuerpo o prana.
                                    <ol>
                                        <li>El equilibrio de esta fuerza vital viene determinado por el equilibrio de las tres
                                            cualidades corporales llamadas doshas: vata, pitta y kapha.</li>
                                        <li>La mayor&iacute;a de las personas tienen un dosha dominante, y el equilibrio
                                            espec&iacute;fico entre los tres doshas es &uacute;nico para cada persona.</li>
                                    </ol>
                                </li>
                                <li>Los profesionales de la salud eval&uacute;an a las personas pregunt&aacute;ndoles sobre sus
                                    s&iacute;ntomas, comportamiento y estilo de vida, observando su aspecto general, incluidos los ojos,
                                    la lengua y la piel, tom&aacute;ndoles el pulso y analizando la orina y las heces.</li>
                                <li>Tras determinar el equilibrio de los doshas, los terapeutas dise&ntilde;an un tratamiento
                                    espec&iacute;ficamente adaptado a cada persona utilizando:
                                    <ul>
                                        <li>Dieta</li>
                                        <li>Hierbas</li>
                                        <li>Masajes</li>
                                        <li>Meditaci&oacute;n.</li>
                                        <li>Yoga</li>
                                        <li>Limpieza interna (eliminaci&oacute;n terap&eacute;utica), con enemas en el recto o con
                                            lavado nasal, para restablecer el equilibrio dentro del cuerpo y con la naturaleza.</li>
                                    </ul>
                                </li>
                                <li>Usos medicinales:
                                    <ul>
                                        <li>Rinitis al&eacute;rgica.</li>
                                        <li>Esquizofrenia</li>
                                        <li>Afecciones neurol&oacute;gicas.</li>
                                        <li>Dolor</li>
                                        <li>Artritis</li>
                                        <li>Diabetes</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="animatedtitle" id="FISIO">
                        <h2>3.2. Prácticas fisio-biológicas:</h2>
                        <h3>(Uso de fuentes naturales como vitaminas, comidas o dietas especiales, suplementos alimenticios o productos
                            botánicos)</h3>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://www.genesiscare.com/es/apoyo-al-paciente/blog/que-es-la-fitoterapia#:~:text=La%20fitoterapia%20es%20la%20ciencia,trav%C3%A9s%20de%20las%20plantas%20medicinales."><strong>Fitoterapia</strong></a><strong><u>:
                                </u></strong></p>
                            <ol>
                                <li>Uso de plantas con una finalidad terap&eacute;utica para aliviar los s&iacute;ntomas, prevenir
                                    enfermedades o restablecer el estado de salud.</li>
                                <li>En el mundo hay m&aacute;s de 100.000 especies de plantas con propiedades curativas, y el 10% se
                                    consideran terap&eacute;uticas.</li>
                                <li>Como ejemplo 5 plantas y sus aplicaciones:
                                    <ol>
                                        <li>Lavanda:
                                            <ul>
                                                <li>Alivio del dolor de cabeza, prevenci&oacute;n del insomnio y potenciaci&oacute;n del
                                                    descanso.</li>
                                            </ul>
                                        </li>
                                        <li>Romero:
                                            <ul>
                                                <li>Indicado para dolores musculares, articulares o neuralgias.</li>
                                            </ul>
                                        </li>
                                        <li>Tomillo:
                                            <ul>
                                                <li>Alivio de dolencias leves, comunes o problemas respiratorios.</li>
                                                <li>Propiedades antioxidantes.</li>
                                                <li>Fuente de minerales (calcio, hierro y manganeso) y fibra.</li>
                                            </ul>
                                        </li>
                                        <li>Eucalipto:
                                            <ul>
                                                <li>Tratamiento de la gripe, resfriados y catarros, molestias por congesti&oacute;n
                                                    nasal y tratamiento de la tos.</li>
                                                <li>Ayuda contra las manchas de piel.</li>
                                            </ul>
                                        </li>
                                        <li>Aloe Vera:
                                            <ul>
                                                <li>Especialmente indicado en los tratamientos asociados a la piel:
                                                    <ul>
                                                        <li>Alivio de quemaduras al eliminar r&aacute;pidamente las c&eacute;lulas
                                                            muertas y estimular el crecimiento saludable de la piel, gracias a contar
                                                            con fosfato de manosa, agente de crecimiento de los tejidos y cicatrizante.
                                                        </li>
                                                        <li>Efecto rejuvenecedor al producir fibras fibrobl&aacute;sticas que se
                                                            encuentran en la dermis y son responsables de la producci&oacute;n del
                                                            col&aacute;geno (act&uacute;an de 7 a 8 veces m&aacute;s de su velocidad
                                                            normal).</li>
                                                        <li>Nutriente, al ser rico en minerales como el calcio, magnesio,
                                                            f&oacute;sforo, potasio, zinc o cobre.</li>
                                                        <li>Restauraci&oacute;n de los niveles h&iacute;dricos en pieles deshidratadas.
                                                        </li>
                                                        <li>Acci&oacute;n limpiadora, gracias a las saponinas que penetran en los poros
                                                            y eliminan suciedades.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/dietoterapia-o-terapia-nutricional"><strong>Terapia
                                    nutricional:</strong></a></p>
                            <ol>
                                <li>Pr&aacute;ctica con base biol&oacute;gica, es decir, en base a sustancias naturales para mejorar la
                                    salud, mediante diferentes tipos de dieta:
                                    <ol>
                                        <li>Macrobi&oacute;tica:
                                            <ul>
                                                <li>Esencialmente verduras, granos integrales, frutas y cereales).</li>
                                            </ul>
                                        </li>
                                        <li>Paleo:
                                            <ul>
                                                <li>Su nombre proviene de los alimentos consumidos en el Paleol&iacute;tico (Comida
                                                    cazada o recolectada).</li>
                                                <li>Se eliminan los alimentos no disponibles en la &eacute;poca puesto que se cree que
                                                    el humano no puede metabolizarlos bien.</li>
                                                <li>Rica en prote&iacute;na, carbohidratos m&iacute;nimos, frutas y verduras frescas sin
                                                    almid&oacute;n, consumici&oacute;n de fibra y m&aacute;s grasas.</li>
                                            </ul>
                                        </li>
                                        <li>Mediterr&aacute;nea:
                                            <ul>
                                                <li>Consumo de aceite de oliva virgen como grasa principal y presencia preponderante de
                                                    alimentos frescos de temporada, cereales, verduras y hortalizas, pescado, huevos y
                                                    l&aacute;cteos, frente a otras fuentes grasas de origen animal o procesada.</li>
                                                <li>Estilo de vida que alienta a socializar durante la comida, con amigos y familia,
                                                    incorporando el movimiento diario.</li>
                                                <li>Seg&uacute;n Navarra biomed, la Universidad P&uacute;blica de Navarra, la
                                                    Universidad de Harvard y la Universidad de las Am&eacute;ricas, con la
                                                    colaboraci&oacute;n de la Universidad de Castilla-La Mancha y la Aut&oacute;noma de
                                                    Madrid, en un estudio reciente publicado en <a
                                                        href="https://www.sciencedirect.com/science/article/pii/S216183132300296X?via%3Dihub"><strong>ADVANCES
                                                            IN NUTRITION</strong></a>, por primera vez se confirma
                                                    cient&iacute;ficamente que la dieta mediterr&aacute;nea reduce la prevalencia de la
                                                    obesidad en menores de edad.</li>
                                            </ul>
                                        </li>
                                        <li>Baja en carbohidratos:
                                            <ul>
                                                <li>El cuerpo humano convierte los carbohidratos en glucosa en el torrente
                                                    sangu&iacute;neo, que es la principal fuente de energ&iacute;a del cuerpo.</li>
                                                <li>Cuando el cuerpo se ve privado de suficientes carbohidratos para alimentar el
                                                    cuerpo, entra en un estado de cetosis donde las reservas de grasa se descomponen y
                                                    se convierten en la energ&iacute;a necesaria.</li>
                                                <li>Una dieta que proh&iacute;ba o minimice el consumo de carbohidratos est&aacute;
                                                    tratando de forzar al sistema a ese estado de cetosis para quemar grasa
                                                    r&aacute;pidamente.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>Utilizaci&oacute;n:
                                    <ul>
                                        <li>Tratamiento y prevenci&oacute;n de enfermedades como c&aacute;ncer o trastornos
                                            cardiovasculares, diabetes tipo 2, mejora del rendimiento atl&eacute;tico, del sue&ntilde;o
                                            y del intelecto.</li>
                                        <li>Promover el bienestar general.</li>
                                        <li>Desintoxicaci&oacute;n del cuerpo (neutralizando o eliminando toxinas).</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://www.merckmanuals.com/es-us/hogar/SearchResults?query=suplementos+nutricionales+y+vitaminas"><strong>Tratamientos
                                    con suplementos nutricionales y vitaminas:</strong></a></p>
                            <ol>
                                <li>Gama de productos destinados a ofrecer un extra nutricional o vitam&iacute;nico a los nutrientes
                                    habituales de la dieta.</li>
                                <li>Se hace mediante pastillas o comprimidos, en polvo o l&iacute;quidos.</li>
                                <li>A considerar en caso de:
                                    <ul>
                                        <li>Embarazo (Yodo y &Aacute;cido F&oacute;lico).</li>
                                        <li>Osteoporosis (Calcio y Vitamina D).</li>
                                        <li>Bajos niveles de Vitamina B12.</li>
                                        <li>Cirug&iacute;a bari&aacute;trica.</li>
                                        <li>Patolog&iacute;a gastrointestinal que evite la absorci&oacute;n natural.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/terapia-con-quelantes-o-de-quelaci%C3%B3n?query=quelantes"><strong>Terapia
                                    con quelantes o de quelaci&oacute;n: </strong></a></p>
                            <ol>
                                <li>Pr&aacute;ctica con base biol&oacute;gica que describe una reacci&oacute;n qu&iacute;mica en la que
                                    ciertas mol&eacute;culas se unen a &aacute;tomos met&aacute;licos (como calcio, cobre, hierro o
                                    plomo).</li>
                                <li>Los f&aacute;rmacos quelantes, como el &aacute;cido etilendiaminotetraac&eacute;tico (EDTA), se unen
                                    con metales para que puedan excretarse del cuerpo.</li>
                                <li>Muchos trastornos son consecuencia de la presencia de un exceso de metal en el cuerpo, incluso
                                    cuando las personas afectadas no han estado expuestas al metal y los an&aacute;lisis de sangre no
                                    indican concentraciones elevadas de metal. Por lo tanto, tratan muchos trastornos diferentes con
                                    f&aacute;rmacos quelantes.</li>
                                <li>Usos comunes en medicina convencional:
                                    <ul>
                                        <li>Tratamiento para el envenenamiento por plomo.</li>
                                        <li>Sobredosis de hierro.</li>
                                        <li>Intoxicaciones por metales pesados.</li>
                                    </ul>
                                </li>
                                <li>&nbsp;Otros usos:
                                    <ul>
                                        <li>Eliminaci&oacute;n del calcio para tratar la ateroesclerosis (placas de material graso en
                                            las paredes de las arterias, produciendo una disminuci&oacute;n u obstrucci&oacute;n del
                                            riego sangu&iacute;neo), y por lo tanto para prevenir los infartos de miocardio o accidentes
                                            cerebrovasculares.</li>
                                    </ul>
                                </li>
                            </ol>

                        </div>
                    </div>

                    <div className="animatedtitle" id="CUERPO">
                        <h2>3.3. Prácticas de manipulación sobre el cuerpo:</h2>
                        <h3>(Trabajo con una o más partes del cuerpo)</h3>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://www.topdoctors.es/diccionario-medico/osteopatia-tratamiento"><strong>Osteopat&iacute;a:</strong></a>
                            </p>
                            <ol>
                                <li>Especialidad orientada a restaurar y mantener el equilibrio del cuerpo para conseguir su
                                    recuperaci&oacute;n y la conservaci&oacute;n de su capacidad de autorregulaci&oacute;n.</li>
                                <li>Tratamientos mediante t&eacute;cnicas manuales no invasivas con una visi&oacute;n hol&iacute;stica
                                    del cuerpo humano.</li>
                                <li>Se detectan, tratan y previenen problemas de salud con la manipulaci&oacute;n f&iacute;sica, para
                                    aumentar la movilidad de las articulaciones, aliviar la tensi&oacute;n muscular y mejorar el
                                    suministro de sangre a los tejidos conjuntivos del paciente.</li>
                                <li>Enfermedades tratadas:
                                    <ul>
                                        <li>Artrosis</li>
                                        <li>Dolor de cabeza o migra&ntilde;a.</li>
                                        <li>Dolores articulares.</li>
                                        <li>Dolor de espalda.</li>
                                        <li>Problemas digestivos (hernias, &uacute;lceras o estre&ntilde;imiento).</li>
                                        <li>Lesiones deportivas.</li>
                                        <li>Mareos</li>
                                        <li>V&eacute;rtigos.</li>
                                        <li>Incontinencia urinaria.</li>
                                        <li>Ci&aacute;tica.</li>
                                        <li>Escoliosis</li>
                                        <li>Cifosis</li>
                                        <li>Sinusitis</li>
                                        <li>Mala oclusi&oacute;n dental.</li>
                                        <li>Problemas respiratorios.</li>
                                        <li>Diarrea</li>
                                        <li>Hiperactividad</li>
                                        <li>Trastornos del sue&ntilde;o.</li>
                                    </ul>
                                </li>
                                <li>Similar a la Fisioterapia, aunque en este caso no se utilizan agentes naturales como fr&iacute;o,
                                    calor, agua, luz o electricidad para tratar el dolor.</li>
                                <li>Registro en el Consejo General de Osteopat&iacute;a.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/es-us/professional/temas-especiales/medicina-integradora-alternativa-y-complementaria/quiropraxia?query=quiropraxia"><strong>Quiropraxia</strong>:</a>
                            </p>
                            <ol>
                                <li>Pr&aacute;ctica basada en la manipulaci&oacute;n de la columna vertebral (sistema
                                    musculoesquel&eacute;tico) en el entendimiento de que hay una relaci&oacute;n entre &eacute;sta y la
                                    funci&oacute;n del sistema nervioso como clave para mantener o restablecer el flujo de
                                    energ&iacute;a vital y la salud.</li>
                                <li>Los quiropr&aacute;cticos tambi&eacute;n pueden proporcionar terapias f&iacute;sicas (en forma de
                                    calor o fr&iacute;o, estimulaci&oacute;n el&eacute;ctrica y estrategias de rehabilitaci&oacute;n),
                                    masajes o acupresi&oacute;n.</li>
                                <li>Usos:
                                    <ul>
                                        <li>Dolor lumbar, cefalea, dolor cervical y dolor neurol&oacute;gico debido a compresi&oacute;n
                                            nerviosa.</li>
                                    </ul>
                                </li>
                            </ol>

                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://tressalud.com/exactamente-en-que-consiste-el-quiromasaje/"><strong>Quiromasaje</strong></a><strong>:</strong>
                            </p>
                            <ol>
                                <li>Conjunto de t&eacute;cnicas con el prop&oacute;sito de manipular los tejidos grasos, los musculares
                                    y la piel de un paciente con el prop&oacute;sito de calmar sus dolores.</li>
                                <li>Es el resultado de la combinaci&oacute;n de diferentes estilos como elmasaje sueco&nbsp;o
                                    el&nbsp;turco&nbsp;al que se le agregan una serie de manipulaciones concretas dando como resultado
                                    un tratamiento considerablemente m&aacute;s efectivo.</li>
                                <li>Es un g&eacute;nero demasoterapia, pero considerablemente m&aacute;s avanzado y concreto,
                                    complementado con sesiones de acupuntura e, inclusive, aromaterapia con el prop&oacute;sito de curar
                                    al paciente de la forma m&aacute;s r&aacute;pida posible.</li>
                                <li>Se emplea para:
                                    <ul>
                                        <li>Calmar el dolor de los pacientes que vengan derivados de la presencia de puntos gatillo.
                                        </li>
                                        <li>Suprimir contracturas musculares, tendinitis y otras nosolog&iacute;as de clase
                                            musculoesquel&eacute;tica.</li>
                                        <li>Producir relajaci&oacute;n y favorecer el sue&ntilde;o.</li>
                                        <li>Reforzar los tejidos de la piel y del resto de &oacute;rganos.</li>
                                        <li>Proporcionar mayor vigor al organismo.</li>
                                        <li>Aliviar el cansancio.</li>
                                        <li>Prevenir lesiones.</li>
                                        <li>Eliminar c&eacute;lulas muertas.</li>
                                        <li>Aportar calma, relajaci&oacute;n, disminuci&oacute;n del dolor y seguridad.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a href="https://www.topdoctors.es/diccionario-medico/drenaje-linfatico"><strong>Drenaje
                                linf&aacute;tico:</strong></a></p>
                            <ol>
                                <li>Masaje suave y repetitivo, m&aacute;s lento que el habitual, que act&uacute;a sobre los vasos
                                    linf&aacute;ticos con el fin de<strong>eliminar el l</strong><strong>&iacute;</strong><strong>quido
                                        estancado (intersticial y linf</strong><strong>&aacute;</strong><strong>tico</strong>).</li>
                                <li>Acci&oacute;n antiedematosa y analg&eacute;sica sobre las fibras musculares lisas, adem&aacute;s de
                                    efecto relajante en el caso de m&uacute;sculos tensos o hipert&oacute;nicos.</li>
                                <li>Indicado para:
                                    <ul>
                                        <li>Atenuar el llamado efecto piel de naranja de la<a
                                            href="https://www.topdoctors.es/diccionario-medico/celulitis">celulitis</a>.</li>
                                        <li>Tratar edemas&nbsp;postquir&uacute;rgicos o cicatrices.</li>
                                        <li>Aumento de la respuesta del sistema inmunol&oacute;gico,&nbsp;mejorando as&iacute; la
                                            respuesta de las defensas&nbsp;en las zonas tratadas.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/reflexolog%C3%ADa"><strong>Reflexolog&iacute;a:</strong></a>
                            </p>
                            <ol>
                                <li>Presi&oacute;n manual aplicada a &aacute;reas espec&iacute;ficas de la palma de la mano, el pie o la
                                    oreja, en la creencia de que estas &aacute;reas corresponden a diferentes &oacute;rganos o sistemas
                                    corporales.</li>
                                <li>Con la estimulaci&oacute;n se trata de eliminar la obstrucci&oacute;n de la energ&iacute;a como
                                    responsable del dolor o los s&iacute;ntomas en esa zona del cuerpo.</li>
                            </ol>

                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a href="https://es.wikipedia.org/wiki/Shiatsu"><strong>Shiatsu:</strong></a></p>
                            <ol>
                                <li>Aplicaci&oacute;n de presi&oacute;n digital, utilizando las palmas de las manos y los pulgares, por
                                    cortos periodos de tiempo a lo largo de los meridianos (canales de la energ&iacute;a vital).</li>
                                <li>Se incluyen estiramientos, movilizaciones y manipulaciones articulares y otras t&eacute;cnicas de
                                    masaje.</li>
                                <li>Asociado habitualmente a profesionales invidentes, siendo una figura popular de la cultura japonesa.
                                </li>
                                <li>&nbsp;Utilizaci&oacute;n:
                                    <ul>
                                        <li>Prevenci&oacute;n y tratamiento de enfermedades.</li>
                                        <li>Eliminaci&oacute;n de factores que producen fatiga.</li>
                                    </ul>
                                </li>
                                <li>La pr&aacute;ctica est&aacute; aprobada por el Ministerio de Sanidad y Bienestar en Jap&oacute;n
                                    desde 1957.</li>
                                <li>Desde 2007, y gracias a un estudio del Instituto de Atenci&oacute;n a la Salud de la Universidad
                                    deLeeds (Reino Unido), se aboga por su regulaci&oacute;n, ya que su aplicaci&oacute;n produce relax,
                                    calma y mejor &aacute;nimo en pacientes con enfermedades como el c&aacute;ncer, y una mejora
                                    significativa en personas con altos niveles de estr&eacute;s y problemas musculares y/o articulares.
                                </li>
                                <li>En Espa&ntilde;a no es una pr&aacute;ctica regulada y en este momento se encuentra en
                                    evaluaci&oacute;n dentro del marco del programa <a
                                        href="https://www.conprueba.es/informate"><strong>CONPRUEBA</strong></a>&nbsp;iniciado por el
                                    Ministerio de Sanidad, Consumo y Bienestar Social, y del Ministerio de Ciencia.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a href="https://es.wikipedia.org/wiki/Moxibusti%C3%B3n"><strong>Sotau/Gua
                                Sha/Moxibustion</strong></a><strong><u>:</u></strong></p>
                            <ol>
                                <li>Tratamiento en el que se utilizan hojas pulverizadas, secadas y trituradas de la plantaArtemisia
                                    Abrotanum (abr&oacute;tano macho) hasta convertirlas en polvo, a las que se les da forma de cigarro
                                    denominado&nbsp;&ldquo;moxa&rdquo;, que se queman cerca de la piel del paciente o se usa
                                    indirectamente con agujas de acupuntura.</li>
                                <li>Juega un importante papel en los sistemas m&eacute;dicos de China, Jap&oacute;n, Corea, Vietnam,
                                    Tibet y Mongolia.</li>
                                <li>En 2010, la Unesco la declar&oacute; Patrimonio Cultural Inmaterial de la Humanidad.</li>
                                <li>&nbsp;Aplicaciones:
                                    <ul>
                                        <li>Entibiar regiones y puntos de acupuntura, con la intenci&oacute;n de estimular el organismo
                                            a trav&eacute;s de puntos concretos ayudando a estimular el flujo de sangre y Qi.</li>
                                        <li>Utilizaci&oacute;n en hospitales p&uacute;blicos para ayudar al posicionamiento "de cabeza"
                                            del feto que se presenta "de nalgas" con parto dist&oacute;cico, al liberar estr&oacute;geno
                                            placentario y prostaglandinas.</li>
                                        <li>C&aacute;ncer.</li>
                                        <li>Accidente cerebrovascular.</li>
                                        <li>Colitis ulcerosa.</li>
                                        <li>Hipertensi&oacute;n.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a href="https://es.wikipedia.org/wiki/Aromaterapia"><strong>Aromaterapia:</strong></a></p>
                            <ol>
                                <li>Uso de materiales arom&aacute;ticos, incluidos losaceites esenciales (producto obtenido a partir de
                                    materia prima vegetal) y otros compuestos arom&aacute;ticos, con el objetivo de mejorar el bienestar
                                    psicol&oacute;gico o f&iacute;sico.</li>
                                <li>Sus propiedades terap&eacute;uticas, espirituales, higi&eacute;nicas y rituales se remontan a las
                                    civilizaciones antiguas, incluidos los chinos, los indios, los egipcios, los griegos y los romanos.
                                </li>
                                <li>Se ha utilizado como:
                                    <ul>
                                        <li>Antis&eacute;ptico, antiinflamatorio y analg&eacute;sico.</li>
                                        <li>M&eacute;todo para reducir los niveles de ansiedad durante el proceso del parto, en
                                            c&aacute;ncer y en estados de depresi&oacute;n.</li>
                                    </ul>
                                </li>
                                <li>Usos populares m&aacute;s utilizados:
                                    <ul>
                                        <li>Aceite de menta: Problemas digestivos.</li>
                                        <li>Aceite de romero: Estimulante mental y para combatir dolores musculares.</li>
                                        <li>Aceite de lavanda: Dolores de cabeza, insomnio, quemaduras y dolor generalizado.</li>
                                        <li>Aceite de s&aacute;ndalo: Depresi&oacute;n, ansiedad y tensi&oacute;n nerviosa.</li>
                                        <li>Aceite de &aacute;rbol de te: Problemas respiratorios, fungicida, antibacterial y antiviral.
                                        </li>
                                    </ul>
                                </li>
                                <li>Los profesionales de aromaterapia con licencia no recomiendan auto recetarse debido a la naturaleza
                                    altamente t&oacute;xica de algunos aceites esenciales.</li>
                            </ol>

                        </div>
                    </div>

                    <div className="animatedtitle" id="MENTE">
                        <h2>3.4. Técnicas de la mente y cuerpo:</h2>
                        <h3>(Se combina concentración mental, respiración y movimiento del cuerpo para relajar cuerpo y mente)</h3>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://medlineplus.gov/spanish/ency/patientinstructions/000876.htm#:~:text=El%20yoga%20es%20una%20pr%C3%A1ctica,espiritual%20hace%20miles%20de%20a%C3%B1os."><strong>Yoga:</strong></a>
                            </p>
                            <ol>
                                <li>Pr&aacute;ctica que conecta cuerpo, respiraci&oacute;n y mente a trav&eacute;s de:
                                    <ul>
                                        <li>Posturas f&iacute;sicas,</li>
                                        <li>Ejercicios de respiraci&oacute;n y meditaci&oacute;n para mejorar la salud general.</li>
                                    </ul>
                                </li>
                                <li>Se desarroll&oacute; como una pr&aacute;ctica espiritual hace miles de a&ntilde;os.</li>
                                <li>Hoy d&iacute;a, la mayor&iacute;a de las personas en Occidente lo utilizan como ejercicio o para
                                    reducir el estr&eacute;s.</li>
                                <li>Todos los estilos de yoga incluyen tres componentes b&aacute;sicos:
                                    <ol>
                                        <li>Respiraci&oacute;n:Concentrarse en la respiraci&oacute;</li>
                                        <li>Posturas:Una serie de movimientos que ayudan a:
                                            <ul>
                                                <li>Aumentar la fortaleza.</li>
                                                <li>La flexibilidad.</li>
                                                <li>El equilibrio.</li>
                                            </ul>
                                        </li>
                                        <li>Meditaci&oacute;n:Un breve periodo a la finalizaci&oacute;n, con el fin de tranquilizar la
                                            mente y ayudar a relajarse.</li>
                                    </ol>
                                </li>
                                <li>Existen muchos tipos o estilos distintos y var&iacute;an desde suaves a intensos. Entre los
                                    m&aacute;s populares est&aacute;n:
                                    <ul>
                                        <li>Ashtanga o yoga de poder:
                                            <ul>
                                                <li>Movimientos r&aacute;pidos de una postura u otra. Ejercicio m&aacute;s exigente.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Bikram o yoga caliente:
                                            <ul>
                                                <li>Serie de 26 poses en una habitaci&oacute;n con una temperatura entre 35&deg;Cy
                                                    37.8&deg;C).</li>
                                                <li>El objetivo es calentar y estirar los m&uacute;sculos, ligamentos y tendones, y
                                                    purificar el cuerpo a trav&eacute;s del sudor.</li>
                                            </ul>
                                        </li>
                                        <li>Hatha yoga:
                                            <ul>
                                                <li>En ocasiones, &eacute;ste se utiliza como un t&eacute;rmino general para definir
                                                    esta pr&aacute;ctica.</li>
                                                <li>Normalmente incluye tanto respiraci&oacute;n como posturas.</li>
                                            </ul>
                                        </li>
                                        <li>Integral:
                                            <ul>
                                                <li>Tipo suave que puede incluir ejercicios de respiraci&oacute;n, c&aacute;nticos y
                                                    meditaci&oacute;n.</li>
                                            </ul>
                                        </li>
                                        <li>Iyengar:
                                            <ul>
                                                <li>Atenci&oacute;n especial en la alineaci&oacute;n precisa del cuerpo.</li>
                                                <li>Tambi&eacute;n puede mantener las posturas por per&iacute;odos prolongados de
                                                    tiempo.</li>
                                            </ul>
                                        </li>
                                        <li>Kundalini:
                                            <ul>
                                                <li>Enfatiza los efectos de la respiraci&oacute;n sobre las posturas.</li>
                                                <li>El objetivo es liberar la energ&iacute;a de la parte inferior del cuerpo de manera
                                                    que pueda trasladarse hacia arriba.</li>
                                            </ul>
                                        </li>
                                        <li>Viniyoga:
                                            <ul>
                                                <li>Se adaptan posturas a las necesidades y habilidades de cada persona, y coordina la
                                                    respiraci&oacute;n y las posturas.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Beneficios:
                                    <ul>
                                        <li>Mejora del nivel general del estado f&iacute;sico, postura y flexibilidad.</li>
                                        <li>Reducci&oacute;n de la presi&oacute;n arterial y su frecuencia card&iacute;aca</li>
                                        <li>Relajaci&oacute;n.</li>
                                        <li>Reducci&oacute;n del estr&eacute;s.</li>
                                        <li>Mejora en la confianza personal.</li>
                                        <li>Mejora en la coordinaci&oacute;n y concentraci&oacute;n.</li>
                                        <li>Ayuda para dormir.</li>
                                        <li>Reducci&oacute;n de la ansiedad.</li>
                                        <li>Alivio o reducci&oacute;n del dolor de espalda.</li>
                                        <li>Evita la ansiedad.</li>
                                        <li>Recomendado contra la depresi&oacute;n.</li>
                                    </ul>
                                </li>
                                <li>A considerar:
                                    <ul>
                                        <li><a href="https://rimyi.org/"><strong>RAMAMANI IYENGAR MEMORIAL YOGA INSTITUTE:</strong></a>
                                            <ul>
                                                <li>Hogar del yoga Iyengar establecido por Yogacharya B.K.S. Iyengar en la ciudad de
                                                    Pune, India, en 1975.</li>
                                                <li>Considerado el Instituto madre del yoga Iyengar en todo el mundo.</li>
                                                <li>Ofrece clases de yoga, talleres y programas de formaci&oacute;n para profesores de
                                                    yoga.</li>
                                            </ul>
                                        </li>
                                        <li>&nbsp;<a href="https://www.schumacheryoga.com/"><strong>JOHN SCHUMACHER UNITY WOODS
                                            YOGA</strong></a>:
                                            <ul>
                                                <li>El centro tiene como objetivo instruir en yoga a un p&uacute;blico lo m&aacute;s
                                                    amplio posible.</li>
                                                <li>Cursos especiales y talleres con algunos de los mejores maestros del mundo y
                                                    programas que cubren varios aspectos de la ciencia y el arte del yoga.</li>
                                                <li>Clases de yoga para estudiantes de todos los niveles en posturas (asana) y
                                                    respiraci&oacute;n (pranayama).</li>
                                                <li>John Schumacher es profesor, con 50 a&ntilde;os de pr&aacute;ctica, de yoga Iyengar
                                                    avanzado.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/meditation"><strong>Meditaci&oacute;n</strong></a><strong>:</strong>
                            </p>
                            <ol>
                                <li>T&eacute;cnica mediante la cual las personas regulan su atenci&oacute;n o se enfocan
                                    sistem&aacute;ticamente en aspectos particulares de la experiencia interna o externa.</li>
                                <li>Puede implicar sentarse o descansar en silencio, a menudo con los ojos cerrados o la mirada hacia
                                    abajo.</li>
                                <li>En ocasiones se repite una frase, a manera de mantra, destinada a ayudar a la persona a
                                    concentrarse.</li>
                                <li>La meditaci&oacute;n trascendental y la meditaci&oacute;n de atenci&oacute;n plena se han estudiado
                                    cient&iacute;ficamente.</li>
                                <li><a href="https://www.heart.org/"><strong>AMERICAN HEART ASSOCIATION</strong></a>&nbsp;ha respaldado
                                    el uso de la meditaci&oacute;n como una intervenci&oacute;n de bajo riesgo y bajo costo en la
                                    prevenci&oacute;n de enfermedades cardiovasculares.</li>
                                <li>Se est&aacute;n estudiando sus funciones en las afecciones m&eacute;dicas cr&oacute;nicas.</li>
                                <li>La mayor&iacute;a de las pr&aacute;cticas se han desarrollado dentro de un contexto espiritual y con
                                    el objetivo de:
                                    <ul>
                                        <li>Crecimiento espiritual</li>
                                        <li>Transformaci&oacute;n personal</li>
                                        <li>Experiencia trascendental.</li>
                                    </ul>
                                </li>
                                <li>Puede ser eficaz independientemente de los antecedentes culturales, espirituales o religiosos de las
                                    personas.</li>
                                <li>&nbsp;Beneficios:
                                    <ol>
                                        <li>Alivio en:
                                            <ul>
                                                <li>Estr&eacute;s, ansiedad y depresi&oacute;n.</li>
                                                <li>Insomnio</li>
                                                <li>Dolor</li>
                                                <li>S&iacute;ntomas de trastornos cr&oacute;nicos como el c&aacute;ncer.</li>
                                                <li>Zumbido en los o&iacute;dos (tinnitus).</li>
                                                <li>Disfunci&oacute;n sexual.</li>
                                            </ul>
                                        </li>
                                        <li>Efectos favorables sobre la actividad cerebral.Por ejemplo:
                                            <ul>
                                                <li>Puede aumentar la actividad en partes del cerebro asociadas con la claridad mental.
                                                </li>
                                                <li>Los trastornos neurol&oacute;gicos, por ejemplo, la enfermedad de Parkinson.</li>
                                            </ul>
                                        </li>
                                        <li>Induce a:
                                            <ul>
                                                <li>Relajaci&oacute;n f&iacute;sica.</li>
                                                <li>Calma mental.</li>
                                                <li>Estados emocionales favorables.</li>
                                                <li>La ecuanimidad.</li>
                                            </ul>
                                        </li>
                                        <li>La meditaci&oacute;n tiene muchos efectos beneficiosos sobre la salud del:
                                            <ul>
                                                <li>Coraz&oacute;n</li>
                                                <li>Los vasos sangu&iacute;neos (cardiovasculares).</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a href="https://cuidateplus.marca.com/ejercicio-fisico/diccionario/tai-chi.html"><strong>Tai
                                Chi</strong></a><strong>:</strong></p>
                            <ol>
                                <li>Arte marcialque practican millones de personas en todo el mundo debido a los m&uacute;ltiples
                                    beneficios que ofrece, tanto a nivel corporal como mental, adem&aacute;s de la parte de
                                    meditaci&oacute;n que incluye.</li>
                                <li>Los resultados son evidentes en poco tiempo porque, aunque se trata de un ejercicio que se realiza
                                    lentamente, y de forma suave, con &eacute;l se pueden trabajar todos los m&uacute;sculos del cuerpo
                                    y, adem&aacute;s se tienen en cuenta otros factores como:
                                    <ul>
                                        <li>La fuerza</li>
                                        <li>La flexibilidad</li>
                                        <li>El equilibrio.</li>
                                    </ul>
                                </li>
                                <li>Efectivo en la mejora de la condici&oacute;n f&iacute;sica, en el tratamiento de enfermedades
                                    circulatorias e incluso a la hora de retrasar el envejecimiento.</li>
                                <li>Su particularidad consiste en combinar de forma arm&oacute;nica los tres fundamentos en los que se
                                    sustenta, y que son:
                                    <ol>
                                        <li>Elmovimiento corporal.</li>
                                        <li>Laconcentraci&oacute;n mental.</li>
                                        <li>Realizar la respiraci&oacute;n consciente.</li>
                                    </ol>
                                </li>
                                <li>Modalidades:
                                    <ul>
                                        <li>El estilo Yang:
                                            <ul>
                                                <li>Movimientos largos, suaves y en los que no haya una alteraci&oacute;n del ritmo de
                                                    respiraciones o de la fuerza que se ejerce.</li>
                                                <li>Especialmente recomendado para los principiantes o para personas de mayor edad.</li>
                                            </ul>
                                        </li>
                                        <li>El estilo Chen:
                                            <ul>
                                                <li>Se pasa de la relajaci&oacute;n a la explosi&oacute;n en un solo movimiento, sin que
                                                    haya una interrupci&oacute;n entre ambas partes, lo que hace que su
                                                    complicaci&oacute;n sea mayor.</li>
                                                <li>Ejercicios relativamente moderados, por lo que cualquier persona puede practicarlo
                                                    si alcanza la disciplina necesaria.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Beneficios:
                                    <ul>
                                        <li>A la circulaci&oacute;n de la sangre y la tensi&oacute;n arterial gracias a los movimientos
                                            suaves.</li>
                                        <li>A la digesti&oacute;n</li>
                                        <li>Activa el funcionamiento intestinal</li>
                                        <li>Al sistema nervioso:
                                            <ul>
                                                <li>La realizaci&oacute;n unificada de movimientos repercute en una tonificaci&oacute;n
                                                    equilibrada de este sistema, y favorece la capacidad de atenci&oacute;n y la calma.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Al equilibrio.</li>
                                        <li>La fuerza.</li>
                                        <li>La flexibilidad.</li>
                                        <li>La resistencia muscular.</li>
                                        <li>Ayuda a controlar elasma&nbsp;gracias a la manera en la que se realizan diferentes
                                            respiraciones profundas durante los movimientos.</li>
                                        <li>Influye en la capacidad aer&oacute;bica, que tiende a reducirse con el envejecimiento, pero
                                            puede llegar a mantenerse si se realiza ejercicio f&iacute;sico de forma regular.</li>
                                        <li>Disminuci&oacute;n del estr&eacute;s ya que, gracias a los factores de respiraci&oacute;n y
                                            de concentraci&oacute;n, hace m&aacute;s f&aacute;cil relajarse, y resulta adecuado para:
                                            <ul>
                                                <li>Mejorar la movilidad de diversas articulaciones como:
                                                    <ul>
                                                        <li>Los hombros.</li>
                                                        <li>Las rodillas.</li>
                                                        <li>La espalda.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>En otras disciplinas deportivas en las que se somete a la persona a una tensi&oacute;n
                                            excesiva y poco natural, se generan sobrecargas e incluso se pueden producir lesiones
                                            severas, mientras que en esta disciplina se protegen, fortalecen y se promueven posturas
                                            correctas.</li>
                                    </ul>
                                </li>
                                <li>Sobre el aspecto mental y la concentraci&oacute;n, los expertos en la disciplina aconsejan que no se
                                    practique nunca de manera autom&aacute;tica, mientras se piensa en otra cosa, sino que al tratarse
                                    de una composici&oacute;n de ejercicios relativamente compleja recomiendan que la mente este activa
                                    por completo y concentradaen los cambios de actividad o movimiento.</li>
                                <li>Otras modalidades:
                                    <ol>
                                        <li><u>Entre dos personas</u>:
                                            <ul>
                                                <li>En especial para ciertos ejercicios y movimientos, se requiere que los alumnos
                                                    dispongan de conocimientos previos sobre la disciplina y de una cierta soltura, de
                                                    manera que resulte m&aacute;s efectivo.</li>
                                                <li>Los ejercicios para practicar por parejas se suelen reservar para aquellas personas
                                                    que llevan un tiempo practicando este deporte.</li>
                                            </ul>
                                        </li>
                                        <li><u>Con la ayuda de mazas u otros artefactos</u>:
                                            <ul>
                                                <li>Al igual que la modalidad por parejas, esta se reserva a los alumnos m&aacute;s
                                                    avanzados, para evitar posibles riesgos.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/guided-imagery"><strong>Im&aacute;genes
                                    guiadas</strong></a><strong>:</strong></p>
                            <ol>
                                <li>Las im&aacute;genes guiadas implican el uso de im&aacute;genes mentales para:
                                    <ul>
                                        <li>Promover la relajaci&oacute;n y el bienestar</li>
                                        <li>Reducir el dolor</li>
                                        <li>Facilitar la curaci&oacute;n de una dolencia particular, como:
                                            <ul>
                                                <li>C&aacute;ncer.</li>
                                                <li>Trauma psicol&oacute;gico.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Pueden ser autodirigidas o guiadas por un profesional, a veces en grupo.Por ejemplo:
                                    <ul>
                                        <li>A una persona con c&aacute;ncer se le puede pedir que imagine un ej&eacute;rcito de
                                            gl&oacute;bulos blancos luchando contra las c&eacute;lulas cancerosas.&nbsp;</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/biofeedback"><strong>Biorretroalimentaci&oacute;n<u>[i]</u>:</strong></a>
                            </p>
                            <ol>
                                <li>M&eacute;todo para poner los procesos biol&oacute;gicos inconscientes bajo control consciente.</li>
                                <li>Se utilizan dispositivos electr&oacute;nicos para medir y transmitir el proceso mental:
                                    <ul>
                                        <li>Frecuencia Cardiaca</li>
                                        <li>Presi&oacute;n arterial.</li>
                                        <li>Tensi&oacute;n muscular.</li>
                                    </ul>
                                </li>
                                <li>Con la ayuda de un terapeuta las personas pueden comprender por qu&eacute; cambian estas funciones y
                                    pueden aprender a regularlas, disminuyendo as&iacute; los efectos de afecciones.</li>
                                <li>Usos medicinales:
                                    <ul>
                                        <li>Tratar el dolor, incluido el dolor de cabeza.</li>
                                        <li>Dolor abdominal cr&oacute;nico.</li>
                                        <li>Estr&eacute;s.</li>
                                        <li>Ansiedad</li>
                                        <li>Insomnio</li>
                                        <li>Incontinencia fecal o urinaria.</li>
                                        <li>Trastorno por d&eacute;ficit de atenci&oacute;n/hiperactividad.</li>
                                        <li>Lesi&oacute;n cerebral traum&aacute;tica.</li>
                                        <li>Tinnitus</li>
                                        <li>S&iacute;ndrome de Raynaud.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a href="https://www.hellinger.com/es/constelacion-familiar/"><strong>Constelaciones
                                familiares:</strong></a></p>
                            <ol>
                                <li>M&eacute;todo psicoterap&eacute;utico que considera que las personas son capaces de percibir de
                                    forma inconsciente patrones y estructuras en los sistemas de los que formamos parte, a los que
                                    denomina constelaciones, y donde se puede incluir la familia, el equipo de trabajo (empresas u
                                    organizaciones) o, por ejemplo, los grupos de amigos, llegando a considerar a la Humanidad entera
                                    como una familia.</li>
                                <li>El impacto que generan esas relaciones familiares queda memorizado, transmiti&eacute;ndose
                                    generacionalmente los problemas f&iacute;sicos, emocionales y psicol&oacute;gicos de las personas
                                    como si fuese un esquema afectivo y cognitivo que afecta a la conducta.</li>
                                <li>Todos pertenecemos a varios sistemas en el momento presente y a lo largo de nuestra vida. En ese
                                    contexto nos podemos entender de una forma m&aacute;s clara y multidimensional revisando nuestra
                                    forma de actuar seg&uacute;n nuestra experiencia presente y pasada.</li>
                                <li>Parte de considerar la dependencia positiva entre las personas, en tanto que para desarrollarse cada
                                    cual depende de la ayuda que da o recibe de otros.</li>
                                <li>Se encuadra dentro de las terapias existenciales donde el paciente realiza una profunda
                                    introspecci&oacute;n a su psique.</li>
                                <li>El objetivo es hacer que las fuerzas y los patrones obstructivos sean visibles y perceptibles con
                                    enfoque en las preguntas:
                                    <ul>
                                        <li>&iquest;Qu&eacute; podr&iacute;a dar soporte al sistema?</li>
                                        <li>&iquest;Qu&eacute; recursos hay disponibles?</li>
                                        <li>&iquest;Qu&eacute; cambios son posibles?</li>
                                    </ul>
                                </li>
                                <li>Los principios en los que se fundamenta son:
                                    <ul>
                                        <li>El de pertenencia (nadie puede estar excluido del sistema).</li>
                                        <li>El de orden (el primero que llega al sistema tiene m&aacute;s derecho que el segundo).</li>
                                        <li>El de compensaci&oacute;n (el equilibrio entre el dar y el recibir en las relaciones
                                            humanas).</li>
                                    </ul>
                                </li>
                                <li>Es una interacci&oacute;n estructurada entre el aporte te&oacute;rico y una gran orientaci&oacute;n
                                    pr&aacute;ctica, demostraci&oacute;n, ejercitaci&oacute;n propia y posterior reflexi&oacute;n para
                                    encontrar el propio lugar en el sistema, aumentando el conocimiento de los roles de cada cual y la
                                    expansi&oacute;n de los recursos para superar los conflictos producidos en el pasado.</li>
                                <li>Se nutre del An&aacute;lisis Transaccional de <a
                                    href="https://es.wikipedia.org/wiki/Tri%C3%A1ngulo_dram%C3%A1tico_de_Karpman"><strong>STEPHEN
                                        KARPMAN</strong></a><strong>&nbsp;</strong>(el tri&aacute;ngulo dram&aacute;tico), la <a
                                            href="https://www.mayoclinic.org/es-es/tests-procedures/family-therapy/about/pac-20385237"><strong>TERAPIA
                                                FAMILIAR</strong></a>, la <a
                                                    href="https://es.wikipedia.org/wiki/Fenomenolog%C3%ADa_(filosof%C3%ADa)#:~:text=La%20fenomenolog%C3%ADa%20(del%20griego%20antiguo,estructuras%20de%20la%20experiencia%20humana."><strong>FENOMENOLOG&Iacute;A</strong></a>,
                                    la <a
                                        href="https://www.topdoctors.es/diccionario-medico/terapia-gestalt#:~:text=La%20Terapia%20Gestalt%20est%C3%A1%20enfocada,%E2%80%9D%20y%20%E2%80%9Cpara%20qu%C3%A9%E2%80%9D."><strong>TERAPIA
                                            GESTALT</strong></a>, la <a
                                                href="https://psicologiaymente.com/clinica/terapia-primal-arthur-janov"><strong>TEOR&Iacute;A
                                                    DEL GRITO PRIMAL</strong></a>, las <a
                                                        href="https://psicologiaymente.com/clinica/terapia-humanista"><strong>TERAPIAS
                                                            HUMAN&Iacute;STICAS</strong></a>, las <a
                                                                href="https://itadsistemica.com/terapia-familiar/8-conceptos-clave-terapia-familiar-sistemica/#:~:text=La%20Terapia%20Sist%C3%A9mica%20es%20un,otros%20grupos%20de%20pertenencia%20significativos."><strong>TERAPIAS
                                                                    SISTEM&Aacute;TICAS</strong></a>&nbsp;y de la <a
                                                                        href="https://www.euroinnova.edu.es/blog/que-es-la-sanacion-cuantica"><strong>CU&Aacute;NTICA</strong></a>,
                                    o en conceptos desarrollados por <a
                                        href="https://es.wikipedia.org/wiki/Carl_Gustav_Jung"><strong>CARL
                                            JUNG</strong></a><strong>&nbsp;</strong>(inconsciente colectivo) o <a
                                                href="https://es.wikipedia.org/wiki/Rupert_Sheldrake"><strong>RUPERT
                                                    SHELDRAKE</strong></a>&nbsp;(resonancia m&oacute;rfica o campos morfogen&eacute;ticos
                                    vinculados a la creencia en la telepat&iacute;a y la percepci&oacute;n extrasensorial).</li>
                                <li>Desde la d&eacute;cada de los setenta, ha tenido un lugar especial en el contexto consultivo y
                                    terap&eacute;utico en &aacute;reas de habla alemana e internacionales, de hecho, se crea en
                                    Alemania, y el t&eacute;rmino viene de Familien-Aufstellungen, cuya traducci&oacute;n literal es
                                    &ldquo;Configuraciones de familias&rdquo;, si bien fue traducido al ingl&eacute;s como
                                    &ldquo;Constelaciones&rdquo; y as&iacute; se ha divulgado.</li>
                                <li>La <a
                                    href="https://www.hellinger.com/es/formacion/#accordion-para-quin-es-indicado-el-curso-de-diplomado"><strong>ESCUELA
                                        HELLINGER</strong></a><strong>&nbsp;</strong>es el principal centro de capacitaci&oacute;n
                                    integral y est&aacute; reconocida por la Administraci&oacute;n alemana (en este caso de la Alta
                                    Baviera). Sus ra&iacute;ces se encuentran en:
                                    <ul>
                                        <li>El modelo de proceso de cambios desarrollado a trav&eacute;s de estudios cl&iacute;nicos por
                                            <a href="https://es.wikipedia.org/wiki/Virginia_Satir"><strong>VIRGINIA SATIR</strong></a>,
                                            donde intervienen cuatro etapas: viejos status quo, elemento externo, caos e
                                            integraci&oacute;n.
                                        </li>
                                        <li>El psicodrama de <a href="https://es.wikipedia.org/wiki/Jacob_Levy_Moreno"><strong>JACOB
                                            LEVY MORENO</strong></a>, donde un grupo de personas realiza una
                                            representaci&oacute;n (como si fuese una obra de teatro) donde se realiza una
                                            radiograf&iacute;a de lo que est&aacute; sucediendo como problema y se busca la
                                            soluci&oacute;n al conflicto, utilizando la improvisaci&oacute;n, partiendo de la idea de
                                            que a trav&eacute;s de la espontaneidad y la creatividad se da una liberaci&oacute;n donde
                                            se despiertan las emociones produci&eacute;ndose una catarsis, como fen&oacute;meno donde
                                            una persona se conoce m&aacute;s a s&iacute; misma.</li>
                                        <li>El psicoan&aacute;lisis de <a
                                            href="https://es.wikipedia.org/wiki/Sigmund_Freud"><strong>SIGMUND
                                                FREUD</strong></a>&nbsp;y sus disc&iacute;pulos, que, igualmente persegu&iacute;a
                                            que las experiencias bloqueadas emergiesen a la consciencia para que el paciente las
                                            interpretara y se las pudiese apropiar, buscando dar estatus cient&iacute;fico al concepto
                                            de lo inconsciente.</li>
                                    </ul>
                                </li>
                                <li>Adem&aacute;s de en el cuidado de la salud, se aplica en el derecho (con la denominaci&oacute;n de
                                    &ldquo;Derecho Sist&eacute;mico&rdquo;, en la pedagog&iacute;a y la educaci&oacute;n.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://institutodyn.com/kinesiologia-tipos-beneficios-curso/"><strong>Kinesiolog&iacute;a</strong></a>:
                            </p>
                            <ol>
                                <li>Terapia que estudia losmovimientos del cuerpo&nbsp;con el prop&oacute;sito de&nbsp;encontrar el
                                    origen de los dolores, ya sean f&iacute;sicos o emocionales.</li>
                                <li>Mediante la manipulaci&oacute;n de los m&uacute;sculos del paciente se averigua cual es el origen de
                                    su malestar y c&oacute;mo debe ser tratado para equilibrar el funcionamiento del cuerpo.</li>
                                <li>Se perciben todas las dimensiones (f&iacute;sica, mental y espiritual) del ser humano como algo
                                    integral, relacionando las diversas patolog&iacute;as entre ellas, y que nuestro cuerpo est&aacute;
                                    conectado a nuestras emociones, lo que se conoce comoenfermedad psicosom&aacute;tica.</li>
                                <li>Una afecci&oacute;n o dolor est&aacute; causado por un desequilibro entre cuerpo y mente, que
                                    provoca alteraciones en el sistema inmunol&oacute;gico, bajando las defensas y favoreciendo la
                                    aparici&oacute;n de problemas de salud por situaciones de:
                                    <ul>
                                        <li>Malestar emocional y mental.</li>
                                        <li>Estr&eacute;s.</li>
                                        <li>Depresi&oacute;n.</li>
                                        <li>Ansiedad (especialmente estomacal).</li>
                                    </ul>
                                </li>
                                <li>Tipos de kinesiolog&iacute;a:
                                    <ol>
                                        <li>Kinesiolog&iacute;a deportiva:
                                            <ul>
                                                <li>Estudia el movimiento de los diversos grupos musculares con el fin de tratar
                                                    lesiones deportivas, mejorar la estructura musculoesquel&eacute;tica y desarrollar
                                                    la relaci&oacute;n entre el sistema esquel&eacute;tico, muscular y nervioso.</li>
                                            </ul>
                                        </li>
                                        <li>Tratamiento kinesiol&oacute;gico preventivo:
                                            <ul>
                                                <li>Su objetivo es detectar posibles patolog&iacute;as en una etapa temprana para
                                                    aplicar los tratamientos adecuados, evitando as&iacute; las posibles complicaciones
                                                    que se puedan derivar.</li>
                                            </ul>
                                        </li>
                                        <li>&nbsp;Tratamiento kinesiol&oacute;gico aplicado:
                                            <ul>
                                                <li>Se detectan y tratan todas las sintomatolog&iacute;as relacionadas con el sistema
                                                    locomotor.</li>
                                            </ul>
                                        </li>
                                        <li>Tratamiento kinesiol&oacute;gico activo:
                                            <ul>
                                                <li>El paciente forma parte del proceso de curaci&oacute;n y debe realizar diversas
                                                    tareas, como ejercicios, estiramientos y llevar a cabo las recomendaciones del
                                                    profesional.</li>
                                            </ul>
                                        </li>
                                        <li>Kinesiolog&iacute;a pasiva:
                                            <ul>
                                                <li>La responsabilidad del tratamiento y de la recuperaci&oacute;n dependen de las
                                                    acciones realizadas por el profesional:
                                                    <ul>
                                                        <li>Movilizaci&oacute;n.</li>
                                                        <li>Quiropr&aacute;ctica.</li>
                                                        <li>Quiromasaje.</li>
                                                        <li>Acupuntura.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Patolog&iacute;as tratadas:
                                            <ul>
                                                <li>Estr&eacute;s, ansiedad y depresi&oacute;n.</li>
                                                <li>Dolores articulares y tensiones musculares.</li>
                                                <li>Fatiga, cansancio y desequilibrios del sue&ntilde;o.</li>
                                                <li>D&eacute;ficit de nutrientes.</li>
                                                <li>Fobias y miedos.</li>
                                                <li>Beneficios:
                                                    <ul>
                                                        <li>Tratamiento integral<strong>,</strong>pudiendo curar diversas dolencias con
                                                            un solo tratamiento.</li>
                                                        <li>Adem&aacute;s del dolor cura su origen, evitando as&iacute; que vuelva a
                                                            aparecer ya que trata el foco de la causa.</li>
                                                        <li>Revierte el buen estado delsistema inmunol&oacute;gico.</li>
                                                        <li>Mejora la relaci&oacute;nmente-cuerpo-esp&iacute;ritu&nbsp;del paciente.
                                                        </li>
                                                        <li>Trata bloqueos energ&eacute;ticos, restableciendo as&iacute; los flujos de
                                                            energ&iacute;a.</li>
                                                        <li>Apto para todo tipo de edades y personas.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/hipnoterapia"><strong>Hipnoterapia</strong></a><strong>:</strong>
                            </p>
                            <ol>
                                <li>Inducci&oacute;n, mediante hipnosis o autohipnosis, a un estado de sugesti&oacute;n y
                                    atenci&oacute;n avanzada o aumentada.</li>
                                <li>Se busca cambiar un comportamiento y mejorar as&iacute; la salud en aquellas patolog&iacute;as donde
                                    los factores psicol&oacute;gicos influyen sobre los s&iacute;ntomas f&iacute;sicos:
                                    <ul>
                                        <li>Sofocos, fobias, s&iacute;ndromes dolorosos, adicci&oacute;n, depresi&oacute;n, ansiedad,
                                            tabaquismo, p&eacute;rdida de peso, s&iacute;ndrome del intestino irritable, cefaleas, asma
                                            o para mejorar la calidad de vida de las personas con c&aacute;ncer.</li>
                                    </ul>
                                </li>
                            </ol>

                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://es.wikipedia.org/wiki/Sofrolog%C3%ADa"><strong>Sofrolog&iacute;a</strong></a><strong>:</strong>
                            </p>
                            <ol>
                                <li>Conjunto de t&eacute;cnicas terap&eacute;uticas alternativas en el tratamiento de estr&eacute;s y
                                    otros problemas psicol&oacute;gicos.</li>
                                <li>Creada en la d&eacute;cada de los a&ntilde;os 60 por el psiquiatra colombiano <a
                                    href="https://es.wikipedia.org/wiki/Alfonso_Caycedo_Lozano"><strong>ALFONSO CAYCEDO
                                        LOZANO</strong></a>, quien fund&oacute; una escuela en Madrid (Espa&ntilde;a) para
                                    ense&ntilde;ar y difundir sus principios y pr&aacute;cticas.</li>
                                <li>En sus or&iacute;genes buscaba estimular el hemisferio derecho del cerebro mediante la
                                    utilizaci&oacute;n de t&eacute;cnicas provenientes del budismo, hatha-yoga y zen japon&eacute;s,
                                    generando un estado de hipnosis en el paciente, producto de la relajaci&oacute;n muscular, pero con
                                    un estado de alerta mental sin disociaci&oacute;n.</li>
                                <li>Ha evolucionado a t&eacute;cnicas o m&eacute;todos de relajaci&oacute;n (distintos del hipnotismo) y
                                    de cambio de estados de conciencia.</li>
                                <li>Cuenta con dos ramas, una m&eacute;dica y otra como disciplina aut&oacute;noma de la medicina donde
                                    considera, igualmente, el problema social, a medio camino entre la medicina y la psicolog&iacute;a
                                    aplicada.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a href="https://es.wikipedia.org/wiki/Musicoterapia"><strong>Musicoterapia</strong></a>:</p>
                            <ol>
                                <li>Uso de la m&uacute;sica y/o sus elementos (sonido, ritmo, melod&iacute;a, armon&iacute;a) para
                                    facilitar y promover la comunicaci&oacute;n, las relaciones, el aprendizaje, el movimiento, la
                                    expresi&oacute;n, la organizaci&oacute;n y otros objetivos terap&eacute;uticos relevantes, para
                                    as&iacute; satisfacer las necesidades f&iacute;sicas, emocionales, mentales, sociales y cognitivas
                                    de un paciente o grupo.</li>
                                <li>El fin &uacute;ltimo es desarrollar potencialidades y/o restaurar las funciones del individuo de
                                    manera tal que &eacute;ste pueda lograr una mejor integraci&oacute;n intra e interpersonal y,
                                    consecuentemente, una mejor calidad de vida a trav&eacute;s de la prevenci&oacute;n,
                                    rehabilitaci&oacute;n y tratamiento.</li>
                                <li>La investigaci&oacute;n, la pr&aacute;ctica, la educaci&oacute;n y la instrucci&oacute;n
                                    cl&iacute;nica est&aacute; basada en est&aacute;ndares profesionales seg&uacute;n los contextos
                                    culturales, sociales y pol&iacute;ticos.</li>
                                <li><a
                                    href="https://es.scribd.com/document/382670402/10-tipos-de-intervenciones-en-musicoterapia-Kenneth-Bruscia"><strong>KENNETH
                                        BRUSCIA</strong></a>, a finales de los a&ntilde;os 80, defini&oacute; la terapia como un
                                    proceso de cambio gradual hacia un estado deseado por el paciente, a trav&eacute;s de un proceso
                                    sistematizado y metodol&oacute;gico en tres etapas, que pueden llevarse a cabo de forma separada o
                                    simult&aacute;nea:
                                    <ol>
                                        <li>Diagn&oacute;stico:
                                            <ul>
                                                <li>Interpretaci&oacute;n cl&iacute;nica, descripci&oacute;n y determinaci&oacute;n de
                                                    un tratamiento espec&iacute;fico para el paciente o evaluaci&oacute;n para,
                                                    posteriormente, reevaluar y registrar diferencias.</li>
                                            </ul>
                                        </li>
                                        <li>Tratamiento:
                                            <ul>
                                                <li>Se direcciona el trabajo mediante estrategias para alcanzar las metas propuestas
                                                    sobre la base de las necesidades, problemas y recursos del paciente revelados
                                                    durante la etapa diagn&oacute;stica.</li>
                                                <li>Cada sesi&oacute;n involucra al cliente en alg&uacute;n tipo de estas cuatro
                                                    experiencias musicales:
                                                    <ol>
                                                        <li>Improvisaci&oacute;n: El paciente crea su propia m&uacute;sica cantando o
                                                            tocando un instrumento.</li>
                                                        <li>Recreaci&oacute;n: El cliente canta una canci&oacute;n, o toca una pieza
                                                            musical ya compuesta, de memoria o leyendo m&uacute;sica.</li>
                                                        <li>Composici&oacute;n: Se compone sobre la base de estructuras musicales.</li>
                                                        <li>Escucha: De m&uacute;sica grabada o en vivo.</li>
                                                    </ol>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Evaluaci&oacute;n:
                                            <ul>
                                                <li>Se determina si las condiciones del paciente se han modificado mediante el proceso
                                                    musico-terap&eacute;utico.</li>
                                            </ul>
                                        </li>
                                        <li>&nbsp;La musicoterapia se desarrolla profesionalmente tanto en el &aacute;mbito
                                            p&uacute;blico como privado, en abordajes grupales como individuales, en prevenci&oacute;n,
                                            promoci&oacute;n, recuperaci&oacute;n y rehabilitaci&oacute;n de la salud.</li>
                                        <li>Las metodolog&iacute;as de trabajo var&iacute;an de acuerdo a la poblaci&oacute;n y a las
                                            escuelas y constructos te&oacute;ricos que fundamenten el quehacer del musicoterapeuta.</li>
                                        <li>En Argentina se crea en 1967 la carrera de Musicoterapia en la <a
                                            href="https://www.uba.ar/download/academicos/carreras/lic-musicoterapia.pdf"><strong>UNIVERSIDAD
                                                DE BUENOS AIRES</strong></a>, extendi&eacute;ndose posteriormente a otras (Salvador,
                                            Maim&oacute;nides y la Universidad Abierta Interamericana).</li>
                                        <li>Espa&ntilde;a ha sido un pa&iacute;s impulsor, surgiendo en 1976 la <a
                                            href="http://feamt.es/"><strong>ASOCIACI&Oacute;N ESPA&Ntilde;OLA DE
                                                MUSICOTERAPIA</strong></a>.</li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a
                                href="https://www.saludterapia.com/glosario/d/6-arteterapia.html"><strong>Arteterapia</strong></a><strong>:</strong>
                            </p>
                            <ol>
                                <li>Utilizaci&oacute;n del arte como v&iacute;a terap&eacute;utica, y como t&eacute;cnica de desarrollo
                                    personal, de autoconocimiento y de expresi&oacute;n emocional.</li>
                                <li>No es necesario poseer ning&uacute;n trastorno psicol&oacute;gico, sino simplemente sentir la
                                    necesidad de explorarnos a trav&eacute;s del arte, sirviendo como proceso de cambio, v&iacute;a de
                                    autoconocimiento o para desarrollar la creatividad, percepci&oacute;n o intuici&oacute;n.</li>
                                <li>No se requiere de unas brillantes dotes art&iacute;sticas, ni que el resultado de la obra tenga un
                                    gran valor art&iacute;stico.</li>
                                <li>El mayor valor es lo que la persona rescata de su interior, lo que descubre y los cambios que se
                                    producen dentro de ella.</li>
                                <li>Observando la creaci&oacute;n y reflexionando sobre la misma es como el terapeuta y el paciente ven
                                    reflejado el mundo interno y establecen d&oacute;nde se encuentra el desequilibrio. Por tanto,
                                    requiere de un compromiso por parte del paciente, dado que es &eacute;l qui&eacute;n se ha de
                                    responsabilizar en su proceso sanador.</li>
                                <li>Se emplea en:
                                    <ul>
                                        <li>Trastornos psicol&oacute;gicos y psiqui&aacute;tricos.</li>
                                        <li>Miedos</li>
                                        <li>Bloqueos personales.</li>
                                        <li>Traumas del pasado.</li>
                                        <li>Estr&eacute;s</li>
                                        <li>Ansiedad</li>
                                        <li>Fibromialgia</li>
                                        <li>Trastornos alimenticios</li>
                                        <li>Fracaso escolar</li>
                                        <li>Trastornos de atenci&oacute;n.</li>
                                    </ul>
                                </li>
                                <li>Principales manifestaciones art&iacute;sticas utilizadas:
                                    <ul>
                                        <li>Pintura</li>
                                        <li>Teatro</li>
                                        <li>Modelado</li>
                                        <li>Collage</li>
                                        <li>Danza</li>
                                        <li>Escritura</li>
                                        <li>M&uacute;sica.</li>
                                    </ul>
                                </li>
                                <li>Cada vez se emplea m&aacute;s en residencias de ancianos, colectivos con problemas de
                                    integraci&oacute;n y colectivos con deficiencias ps&iacute;quicas.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="animatedtitle" id="ENERGIAS">
                        <h2>Técnicas sobre campos de energía:</h2>
                        <h3>(Equilibrio del flujo de la energía vital)</h3>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/acupuncture"><strong>Acupuntura</strong></a>:
                            </p>
                            <ol>
                                <li>Terapia de la medicina tradicional china, con alta aceptaci&oacute;n en Occidente.</li>
                                <li>Estimulaci&oacute;n de puntos espec&iacute;ficos del cuerpo, tambi&eacute;n conocidos como
                                    meridianos o v&iacute;as energ&eacute;ticas (+ de 2.000), mediante la inserci&oacute;n de agujas muy
                                    finas en la piel y los tejidos subyacentes, afectando a la fuerza vital que impregna el cuerpo o Qi
                                    (pronunciado &ldquo;chee&rdquo;) para restablecer el equilibro entre el yin (fuerzas negativas) y el
                                    yang (fuerzas positivas).</li>
                                <li>La Acupuntura M&eacute;dica obtiene efectos terap&eacute;uticos tanto a nivel local como
                                    sist&eacute;mico, tanto en patolog&iacute;as dolorosas como no dolorosas, y sus efectos se basan en
                                    la neuro modulaci&oacute;n ejercida a trav&eacute;s de las se&ntilde;ales transmitidas por el
                                    sistema nervioso y de los mediadores bioqu&iacute;micos intercelulares del sistema conectivo.</li>
                                <li>T&eacute;cnicas de estimulaci&oacute;n:
                                    <ul>
                                        <li>Presi&oacute;n.</li>
                                        <li>L&aacute;ser.</li>
                                        <li>Ultrasonidos</li>
                                        <li>Corriente el&eacute;ctrica de bajo voltaje.</li>
                                    </ul>
                                </li>
                                <li>Usos:
                                    <ul>
                                        <li>Alivio del dolor, incluso despu&eacute;s de intervenciones quir&uacute;rgicas o dentales.
                                        </li>
                                        <li>Alivio de las n&aacute;useas y los v&oacute;mitos que suelen producirse durante el embarazo
                                            o tras la cirug&iacute;a o la quimioterapia.</li>
                                        <li>Tratamiento de adicciones.</li>
                                        <li>S&iacute;ndrome del t&uacute;nel carpiano, fibromialgia, dolor de cabeza, lumbalgia, fatiga
                                            y dolor articular.</li>
                                    </ul>
                                </li>
                                <li>Profesionales de la medicina tradicional, especialistas del dolor, est&aacute;n formados y
                                    autorizados para realizar acupuntura.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a href="https://es.wikipedia.org/wiki/Chi_kung"><strong>Chi-kung (en chino)/Qi-Gong (en
                                pinyin)</strong></a><strong>:</strong></p>
                            <ol>
                                <li>Diversidad de t&eacute;cnicas asociadas a la medicina tradicional china que comprenden la mente, la
                                    respiraci&oacute;n y el ejercicio f&iacute;sico, mediante secuencias, movimientos y posturas suaves
                                    para cualquier practicante.</li>
                                <li>Chi o Qi significa &ldquo;flujo vital de energ&iacute;a&rdquo; y Kung o Gong, se refiere a &ldquo;la
                                    t&eacute;cnica&rdquo; relacionada con las artes marciales y las doctrinas del budismo, confucionismo
                                    y tao&iacute;smo.</li>
                                <li>A finales de 1985, la <a href="https://www.taijiquan.info/acqs.htm"><strong>ASOCIACI&Oacute;N CHINA
                                    DE LA CIENCIA CHIKUNG</strong></a>, declar&oacute; que muchas pruebas demuestran que un
                                    estudio cient&iacute;fico intensivo del Chikung conduce a un pleno desarrollo de las habilidades
                                    tanto mentales como f&iacute;sicas del ser humano.</li>
                                <li>Se busca:
                                    <ul>
                                        <li>Regular el cuerpo, a trav&eacute;s de una postura corporal correcta mediante la
                                            relajaci&oacute;n y la concentraci&oacute;n.</li>
                                        <li>Regular la respiraci&oacute;n, hasta que sea constante y sosegada.</li>
                                        <li>Regular la mente / coraz&oacute;n.</li>
                                    </ul>
                                </li>
                                <li>Se aplica entre otras opciones para:
                                    <ul>
                                        <li>Prevenir el dolor.</li>
                                        <li>Fortalecer las articulaciones.</li>
                                        <li>Reducir el riesgo de enfermedades del sistema cardiovascular.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat2">
                        <div id="format2">
                            <p><a
                                href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/reiki"><strong>Reiki</strong></a><strong>:</strong>
                            </p>
                            <ol>
                                <li>Medicina energ&eacute;tica originaria de Jap&oacute;n.</li>
                                <li>Manipulaci&oacute;n de la energ&iacute;a a trav&eacute;s de las manos, provocando un movimiento
                                    energ&eacute;tico en el cuerpo de la persona para promover la curaci&oacute;n.</li>
                                <li>Los practicantes no tocan a la persona, o hacen un contacto muy ligero.</li>
                                <li>Utilizaci&oacute;n:
                                    <ul>
                                        <li>Dolor, artritis, c&aacute;ncer y trastornos del estado de &aacute;nimo.</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="parrafoformat">
                        <div id="format">
                            <p><a href="https://blog.naturlider.com/que-es-la-terapia-biomagnetica-o-terapia-con-imanes/"><strong>Terapia
                                biomagn&eacute;tica o de campos magn&eacute;ticos</strong></a><a href="#_edn1"
                                    name="_ednref1"><strong><u>[i]</u></strong></a><strong>:</strong></p>
                            <ol>
                                <li>Terapia natural y segura, sin efectos secundarios o colaterales, no invasiva e indolora, que
                                    restablece el medio celular normal, mediante el uso de pares de imanes con una intensidad
                                    m&iacute;nima de 1.000 gauss (unidad de medida del campo magn&eacute;tico).</li>
                                <li>El objetivo es igualar el nivel de pH del organismo, un determinante cr&iacute;tico de la salud.
                                </li>
                                <li>Se tiene constancia de su utilizaci&oacute;n en el antiguo Egipto y en la Grecia cl&aacute;sica,
                                    siendo en el siglo XIX cuando se demuestra la polaridad presente en pr&aacute;cticamente todo el
                                    Universo:
                                    <ul>
                                        <li>La Tierra tiene polaridad, las c&eacute;lulas tienen polaridad, por lo tanto, el cuerpo
                                            humano tiene polaridad.</li>
                                    </ul>
                                </li>
                                <li>&iquest;C&oacute;mo funciona?:
                                    <ul>
                                        <li>La membrana celular contiene prote&iacute;nas que sirven como bombas i&oacute;nicas, las
                                            cuales, en estado de reposo, mantienen ciertosiones con cargas (&ldquo;+&rdquo; o
                                            &ldquo;-&ldquo;)&nbsp;dentro o fuera de la c&eacute;</li>
                                        <li>El interior de la c&eacute;lula tiene una carga negativa normalmente,&nbsp;poseyendo un
                                            potencial el&eacute;</li>
                                        <li>Para comunicarse y enviar se&ntilde;ales e informaci&oacute;n, las neuronas
                                            r&aacute;pidamente invierten las cargas en cada lado de la membrana, algo que se conoce
                                            comoDespolarizaci&oacute;n, lo que significa que cargas positivas se introducen en la
                                            c&eacute;lula.</li>
                                        <li>Posteriormente, dichas part&iacute;culas se expulsan al exterior de la c&eacute;lula y se
                                            restaura la carga negativa en el interior de esta, lo que se conoce
                                            comoRepolarizaci&oacute;&nbsp;</li>
                                        <li>Cada &oacute;rgano tiene su propio campo electromagn&eacute;tico, incluso cada
                                            c&eacute;lula, siendo el coraz&oacute;n el principal generador el&eacute;ctrico de nuestro
                                            cuerpo, creando con su bombeo incesante un campo magn&eacute;tico alrededor del mismo que va
                                            m&aacute;s all&aacute; de la piel, siendo capaz de proyectar se&ntilde;ales
                                            el&eacute;ctricas, sonoras, de presi&oacute;n, de calor, de luz, magn&eacute;ticas y
                                            electromagn&eacute;ticas.</li>
                                        <li>La sangre es un buen conductor de electricidad,adem&aacute;s,&nbsp;el sistema circulatorio
                                            transmite pulsos el&eacute;ctricos con cada latido.</li>
                                        <li>En comparaci&oacute;ncon&nbsp;el&nbsp;campo electromagn&eacute;tico del
                                            coraz&oacute;n,&nbsp;el del cerebro, es mucho menor, pero segrega una hormona que se llama
                                            neuromelanina, cuyas propiedades paramagn&eacute;ticas interaccionan con los campos
                                            magn&eacute;ticos. Se descubrieron hace pocos a&ntilde;os, y casualmente se encuentra en las
                                            neuronas.</li>
                                    </ul>
                                </li>
                                <li>Beneficios:
                                    <ul>
                                        <li>Equilibra el pH y crea un ambiente inh&oacute;spito para los pat&oacute;genos.</li>
                                        <li>Corrige las condiciones energ&eacute;ticas e i&oacute;nicas intracelulares.</li>
                                        <li>Act&uacute;a en enfermedades musculoesquel&eacute;ticas y en el tratamiento del dolor.</li>
                                        <li>Se enfoca en la causa ra&iacute;z del dolor/enfermedad en lugar de hacerlo &uacute;nicamente
                                            en los s&iacute;ntomas.</li>
                                        <li>No presenta efectos secundarios, siendo compatible con tratamientos convencionales y
                                            alternativos.</li>
                                        <li>Los resultados se pueden ver a menudo en unos pocos minutos, d&iacute;as o, a veces, en unas
                                            pocas semanas seg&uacute;n los casos.</li>
                                    </ul>
                                </li>
                            </ol>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}