import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
    const [submenuVisible, setSubmenuVisible] = useState(false);

    const toggleSubmenu = () => {
        setSubmenuVisible(prevState => !prevState);
    };

    return (
        <header className="desktop">
            <nav>
                <div className="headertop">
                    <img className="logoimg" src="Media/logo-panacea.png" alt="" />
                    <h1 className="logo"><Link to="/">PANACEA</Link></h1>
                </div>
                <ul className="menu">
                    <li className="menu-item"><Link to="/AcercaDe">Acerca De</Link></li>
                    <li className="menu-item"><Link to="/Mision">Misión</Link></li>
                    <li className="menu-item" onClick={toggleSubmenu}>
                        <a href="#">Servicios</a>
                        <ul id="submenuprincipal" className={`submenu ${submenuVisible ? 'visible' : ''}`}>
                            <li><Link to="/Sanitarios">Sanitarios</Link></li>
                            <li><Link to="/Terapias">Terapias</Link></li>
                            <li><Link to="/Tienda">Tienda</Link></li>
                            <li><Link to="/Eventos">Eventos</Link></li>
                            <li><Link to="/Publicaciones">News</Link></li>
                            <li><Link to="/Fitness">Wellness</Link></li>
                            <li><Link to="/Investigacion">Investigación</Link></li>
                            <li><Link to="/Seguros">Seguros</Link></li>
                            <li><Link to="/Mascotas">Mascotas</Link></li>
                            <li><Link to="/Emergency">Emergency</Link></li>
                            <li><Link to="/IA">IA</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/Contacto">Contacto</Link></li>
                </ul>
            </nav>
        </header>
    );
}
