import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeDropAnimation } from '../scripts/scriptnaranja';

export default function () {
    useEffect(() => {
        initializeDropAnimation();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Eventos</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleorange.css" />
                </Helmet>
                <div>
                    <div className="videocontainerout">
                        <div className="videocontainer">
                            <h1>EVENTOS</h1>
                            <video src="./Media/eventos.mp4" autoPlay muted loop playsInline></video>
                        </div>
                    </div>

                    <div className="parrafo">
                        <p>Encuentros propios de Panacea,
                        o convocados por empresas, profesionales, instituciones u organizaciones:</p>
                        <br />
                    </div>

                    <div className="bigcards" id="bigcards">
                        <div>
                            <img src="./Media/cursos.png"/>
                                <p>Cursos</p>
                        </div>
                        <div>
                            <img src="./Media/conferencias.png" alt=""/>
                                <p>Conferencias</p>
                        </div>
                        <div>
                            <img src="./Media/talleres.png" alt=""/>
                                <p>Talleres</p>
                        </div>
                        <div>
                            <img src="./Media/charlas.png" alt=""/>
                                <p>Charlas</p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}