// Observers para animaciones
export const rotate2 = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'rotate2 2s ease infinite';
        }
    });
});

export const appearright = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'appearright 1s ease forwards';
        }
    });
});

export const appearleft = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'appearleft 1s ease forwards';
        }
    });
});

export const unfade = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'unfade 2s ease';
        }
    });
});

// Función para inicializar animaciones
export const initializeAnimations = () => {
    // FOTO
    const foto = document.getElementById('foto');
    if (foto) {
        rotate2.observe(foto);
    }

    // ALT1
    const alt1 = document.getElementById('alt1');
    if (alt1) {
        const divs = alt1.querySelectorAll('div');
        appearright.observe(divs[0]); // Primer div
        appearleft.observe(divs[1]); // Segundo div
        appearright.observe(divs[2]); // Tercer div
    }

    // TEXTIMG
    const textimg = document.getElementById('textimg');
    if (textimg) {
        unfade.observe(textimg);
    }

    // ALT2
    const alt2 = document.getElementById('alt2');
    if (alt2) {
        const divs = alt2.querySelectorAll('div');
        appearright.observe(divs[0]); // Primer div
        appearleft.observe(divs[1]); // Segundo div
        appearright.observe(divs[2]); // Tercer div
    }

    // ALT3
    const alt3 = document.getElementById('alt3');
    if (alt3) {
        const divs = alt3.querySelectorAll('div');
        divs.forEach((div, index) => {
            if (index % 2 === 0) {
                appearright.observe(div); // Divs en posiciones pares
            } else {
                appearleft.observe(div); // Divs en posiciones impares
            }
        });
    }

    // PARRAFOLEFT
    const paragraphs = document.querySelectorAll('#parrafoleft');
    if (paragraphs) {
        paragraphs.forEach((p) => {
            appearright.observe(p);
        });
    }

    // TEXTTABLE
    const texttable = document.querySelectorAll('#texttable');
    if (texttable) {
        texttable.forEach((p) => {
            unfade.observe(p);
        });
    }

    // PARRAFORIGHT
    const parraforight = document.querySelectorAll('#parraforight');
    if (parraforight) {
        parraforight.forEach((p) => {
            appearleft.observe(p);
        });
    }

    // TEXTIMGTEXT
    const textimgtext = document.getElementById('textimgtext');
    if (textimgtext) {
        const cards = textimgtext.querySelectorAll('div');
        cards.forEach((card) => {
            appearright.observe(card);
        });
    }

    // TEXTIMGTEXT2
    const textimgtext2 = document.getElementById('textimgtext2');
    if (textimgtext2) {
        const cards = textimgtext2.querySelectorAll('div');
        cards.forEach((card) => {
            appearright.observe(card);
        });
    }
};
