import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Nutrición para mascotas</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>NUTRICIÓN PARA PERROS</h1>
                        <video src="./Media/gato.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="animatedtitle">
                        <h2>Consejos de Nutrición</h2>
                        <h3>para tu perro</h3>
                    </div>

                    <div className="parrafo">
                        <p>
                            Alimentar a tu perro con la dieta adecuada puede ayudar a mantenerlo sano en el futuro. Comprender cuánto
                            alimentarlo es clave para mantenerlo en un peso saludable. Es muy fácil sobrealimentar a un perro y esto
                            jugará un papel importante en su salud y bienestar general.
                        </p>
                    </div>

                    <div className="parrafoleft">
                        <p id="parrafoleft">
                            <b>¿Cuál es la mejor dieta?</b><br /> Desafortunadamente, no hay una respuesta única a esto, ya que la dieta
                            correcta varía según las necesidades de tu perro. Los perros son omnívoros, por lo que tienen la capacidad
                            de digerir tanto la carne como las
                            verduras. Por eso necesitan una dieta equilibrada de proteínas, grasas, fibra, minerales y vitaminas. Una
                            comida equilibrada y de alta calidad siempre es mejor que una alternativa de baja calidad.<br /><br />
                            <b>¿Es mejor la comida húmeda o seca?</b><br />
                            Hay varios tipos de comida para perros disponibles, pero la comida seca descrita como completa suele ser la
                            mejor opción. Esta contiene todos los nutrientes que tu perro necesita en una forma conveniente y fácil de
                            almacenar. También es mucho mejor para la salud dental de tu perro que la comida húmeda, ya que ayuda a
                            eliminar la placa y el sarro de los dientes. Además, contienen mucha menos agua y una mayor concentración de
                            nutrientes, lo que significa que no es necesario darles tanta cantidad.<br /><br /> La comida húmeda es más
                            fácil
                            de comer para los perros que se están recuperando de una lesión u operación y puede ser beneficiosa para los
                            animales con ciertas enfermedades.<br /><br />
                            Muchas personas optan por una mezcla de ambos, ya que a muchos perros les gusta más la comida
                            húmeda.<br /><br />
                            <b>Cómo elegir el alimento adecuado para tu perro</b><br />
                            Sea cual sea el tipo que elijas, mira siempre la lista de ingredientes. Estos se enumeran proporcionalmente
                            y, como regla general, la carne o el pescado debe ser el primer ingrediente, ya que es la mejor proteína
                            para el cuerpo de tu perro. Evita los alimentos que utilizan términos amplios como "derivado animal",
                            "harina" o "cereales" en sus primeros ingredientes, ya que generalmente indican un alimento de menor
                            calidad.<br /><br />
                            Tu veterinario puede asesorarte sobre la dieta de tu perro teniendo en cuenta su edad, estilo de vida y
                            cualquier condición de salud existente.


                        </p>
                    </div>

                    <div className="parrafoformat">
                        <div>
                            <h2>&iquest;Qu&eacute; alimentos humanos son venenosos para los perros?</h2>
                            <h3>Antes de dar a tu perro parte de tu comida, debes conocer qu&eacute; alimentos frecuentes son t&oacute;xicos
                                para ellos.&nbsp;</h3>
                            <h3>Algunos alimentos comunes que debes evitar dar a tu perro:</h3>
                            <ul>
                                <li>Frutos secos</li>
                                <li>Cebolla</li>
                                <li>Uvas</li>
                                <li>Chocolate</li>
                                <li>Puerro</li>
                                <li>Ajo</li>
                                <li>Huesos&nbsp;sobrantes</li>
                            </ul>
                        </div>
                    </div>

                    <div className="parrafoleft">
                        <p id="parrafoleft">
                            <b>¿Debo seguir con la misma comida durante toda su vida?</b><br />
                            Las necesidades nutricionales de tu perro cambian a medida que envejece, por lo que es posible que debas
                            modificar su dieta. Por ejemplo, los cachorros menores de un año necesitarán una dieta que alimente su
                            estilo de vida enérgico al tiempo que apoye el desarrollo saludable del cerebro, los ojos y los huesos. De
                            igual forma, los perros mayores de siete años deberán cambiar a un alimento que mejore su movilidad y
                            función cerebral.<br /><br />
                            <b>¿Puede la dieta ayudar con las condiciones de salud de los perros?</b><br />
                            Puede ser que tu perro necesite una dieta especial para ayudar a combatir los efectos de afecciones de salud
                            como problemas renales, dolor en las articulaciones y artritis, problemas de la piel, afecciones digestivas
                            o alergias alimentarias. Si tu perro desarrolla alguna de estas afecciones a lo largo de su vida, es posible
                            que tengas que cambiar su comida para adaptarla a sus necesidades. Como siempre, tu veterinario estará
                            encantado de recomendarte la mejor opción.<br /><br />
                            Si necesitas cambiar su dieta por cualquier motivo, es importante que lo hagas gradualmente, ya que un
                            cambio repentino puede alterar su estómago y hacer que pierda el apetito. Comienza agregando una pequeña
                            cantidad de la comida nueva a su comida habitual, aumenta gradualmente la cantidad de comida nueva (mientras
                            disminuyes la cantidad de la otra comida) en el transcurso de una semana hasta que coma solo la comida
                            nueva.
                        </p>
                    </div>

                    <div className="parraforight">
                        <p id="parraforight">
                            <b>¿Cuánto debo alimentar a mi perro?</b><br />
                            La cantidad que tu perro debe comer se relaciona con su raza, edad y tamaño. Todos los alimentos para perros
                            tienen pautas en su envase, y este suele ser un buen punto de partida. A continuación, tendrás que vigilar
                            su peso para saber si está comiendo demasiado o muy poco. A grandes rasgos, debes ser capaz de sentir, pero
                            no ver, las últimas dos o tres costillas cuando miras desde un lado, y una cintura clara cuando miras desde
                            arriba. Tu veterinario también revisará el peso de tu perro en su revisión de salud para asegurarse de que
                            sea el correcto.<br /><br />
                            Una vez que hayas decidido la cantidad adecuada de comida para tu perro, es buena idea pesarla al comienzo
                            del día para evitar la sobrealimentación.<br /><br />
                            <b>¿Con qué frecuencia debo alimentar a mi perro?</b><br />
                            Una vez que hayas decidido cuánto debe comer tu perro, puedes dividirlo en pequeñas comidas a lo largo del
                            día. Esto es flexible, pero dos o tres comidas al día ayudan a evitar la torsión gástrica (una enfermedad
                            potencialmente mortal) que se ha relacionado con la alimentación con una comida grande al día. Ciertas
                            razas, como el gran danés, el setter irlandes o el braco de Weimar, corren mayor riesgo de sufrir torsión
                            gástrica, por lo que se recomienda darles comidas más pequeñas.<br />
                            Algunas personas prefieren la alimentación a demanda en la que la comida (generalmente pienso seco) está a
                            su disposición durante todo el día a horarios estrictos de comidas, para que el perro elija cuándo y cuánto
                            comer. Si prefieres este método, es importante medir su cantidad diaria recomendada al comienzo del día y
                            rellenar el tazón a partir de esa cantidad. Una vez que se ha terminado, no añadir más.
                            La constancia es clave, así que sigue siempre la rutina de alimentación que hayas elegido.
                        </p>
                    </div>

                    <div className="parrafoleft">
                        <h2>¿Cuántas golosinas puedo darle a mi perro?</h2>
                        <p id="parrafoleft">
                            No hay nada de malo en una golosina de vez en cuando para recompensar el buen comportamiento de tu perro,
                            pero es importante no excederse ya que pueden ser altamente calóricas. Elige siempre golosinas que sean
                            bajas en azúcar y carbohidratos y limita la cantidad que le das. Las golosinas no deben constituir más del
                            cinco al diez por ciento de la dieta de tu perro. Las golosinas más grandes se pueden dividir en pedazos más
                            pequeños, lo que significa que puedes darle a tu perro algunas recompensas sabrosas sin sobrealimentarlo.
                            A muchos perros les gustan las frutas y verduras frescas que son mucho más bajas en calorías y, en general,
                            mejores para su mascota. Los trozos de manzana, los arándanos y las zanahorias pequeñas son algunos ejemplos
                            que suelen ser populares entre los perros. Aun así, es importante limitar estas golosinas, ya que pueden
                            tener un alto contenido de azúcares naturales.<br /><br />
                            Las frutas y verduras en pequeñas cantidades están bien, pero otras golosinas humanas pueden causar
                            problemas reales. La mayoría de los perros son maestros en "mendigar" cuando alguien está comiendo y una
                            pequeña golosina humana puede no parecer mucho, pero suma y puede provocar sobrepeso en el perro y que
                            desarrolle un gusto por la comida humana, exacerbando el comportamiento de mendicidad y los ojos de
                            cachorro.<br /><br />
                            Si decides darle una golosina a tu perro, es importante que reduzcas ligeramente su comida habitual para
                            equilibrar su ingesta diaria.<br /><br />
                        </p>
                    </div>

                    <div className="parraforight">
                        <h2>No te olvides de su hidratación</h2>
                        <p id="parraforight">
                            El agua es una parte esencial de la nutrición de tu perro, y no beber lo suficiente acarrea graves
                            consecuencias. La mayoría de los perros beben suficiente agua, pero es posible que algunos no, por lo que es
                            posible que debas buscar formas de alentarlos activamente a mantenerse hidratados.<br /><br />
                            El tamaño de su cuenco de agua puede influir en la cantidad de agua que beben los perros; los cuencos altos
                            y estrechos son los mejores para perros con orejas largas como los basset hounds y los spaniels; los cuencos
                            bajos y poco profundos para las razas pequeñas; y los cuencos grandes y anchos para las razas medianas y
                            grandes. Asegúrate de que su agua esté siempre fresca, limpia y disponible para fomentar el consumo regular
                            de agua. Si tu perro aún no bebe lo suficiente, darle comida húmeda puede complementar su ingesta de
                            agua.<br /><br />
                            En algunos casos, la falta de sed o el excesivo consumo de agua, pueden ser signo de una afección
                            subyacente. Si te preocupa la dieta de tu perro o tienes preguntas sobre el alimento adecuado, habla con tu
                            veterinario.
                        </p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}