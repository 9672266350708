import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Investigación</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>INVESTIGACIÓN</h1>
                        <video src="./Media/adn.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>¿Quieres conocer los últimos avances en la medicina?</p>
                        <p>Estás en lugar adecuado</p>
                    </div>

                    <div className="textimage">
                        <img src="./Media/investigacion.png" alt=""/>
                        <p id="textimg">Un sitio donde encontrar información relevante<br />
                            acerca de los últimos avances y descubrimientos del sector.</p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}