import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GlobalMusicButton from './GlobalMusicButton';
import ScrollToTop from './scripts/scrolltotop'; // Importa el componente ScrollToTop
import "./css/global.css";
import Home from './pages/Home';
import AcercaDe from './pages/AcercaDe';
import Mision from './pages/Mision';
import Contacto from './pages/Contacto';
import Sanitarios from './pages/Sanitarios';
import Terapias from './pages/Terapias';
import Tienda from './pages/Tienda';
import Eventos from './pages/Eventos';
import Publicaciones from './pages/Publicaciones';
import Fitness from './pages/Fitness';
import Investigacion from './pages/Investigacion';
import Seguros from './pages/Seguros';
import Mascotas from './pages/Mascotas';
import Emergency from './pages/Emergency';
import Ia from './pages/Ia';
import TerapiasCompleta from './pages/TerapiasCompleta';
import SegurosMascotas from './pages/SegurosMascotas';
import ProductosMascotas from './pages/ProductosMascotas';
import NutricionMascotas from './pages/NutricionMascotas';
import NutricionGatos from './pages/NutricionGatos';
import Nutricion from './pages/Nutricion';
import Politicas from './pages/Politicas';
import InformacionLegal from './pages/InformacionLegal';
import Cookies from './pages/Cookies';
import Creditos from './pages/Creditos';

export default function App() {
  return (
    <div>
      <GlobalMusicButton />
      {/* Enrutador */}
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/AcercaDe' element={<AcercaDe />} />
          <Route path='/Mision' element={<Mision />} />
          <Route path='/Contacto' element={<Contacto />} />
          <Route path='/Sanitarios' element={<Sanitarios />} />
          <Route path='/Terapias' element={<Terapias />} />
          <Route path='/Tienda' element={<Tienda />} />
          <Route path='/Eventos' element={<Eventos />} />
          <Route path='/Publicaciones' element={<Publicaciones />} />
          <Route path='/Fitness' element={<Fitness />} />
          <Route path='/Investigacion' element={<Investigacion />} />
          <Route path='/Seguros' element={<Seguros />} />
          <Route path='/Mascotas' element={<Mascotas />} />
          <Route path='/Emergency' element={<Emergency />} />
          <Route path='/IA' element={<Ia />} />
          <Route path='/TerapiasCompletas' element={<TerapiasCompleta />} />
          <Route path='/SegurosMascotas' element={<SegurosMascotas />} />
          <Route path='/ProductosMascotas' element={<ProductosMascotas />} />
          <Route path='/NutricionPerros' element={<NutricionMascotas />} />
          <Route path='/NutricionGatos' element={<NutricionGatos />} />
          <Route path='/Nutricion' element={<Nutricion />} />
          <Route path='/Politicas' element={<Politicas />} />
          <Route path='/InformacionLegal' element={<InformacionLegal />} />
          <Route path='/Cookies' element={<Cookies />} />
          <Route path='/Copyright' element={<Creditos />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
