export const drop = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'drop 1s ease';
        }
    });
});

export const unfade = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'unfade 2s ease forwards';
        }
    });
});

export const initializeDropAnimation = () => {
    // EVENTOS
    const bigcards = document.getElementById('bigcards');
    if (bigcards) {
        const cards = bigcards.querySelectorAll('div');

        // Verifica si el ancho de la ventana es mayor que 600px
        if (window.innerWidth > 600) {
            cards.forEach((card) => {
                drop.observe(card); // Aquí va tu animación u observador
            });
        } else {
            cards.forEach((card) => {
                unfade.observe(card); // Aquí va tu animación u observador
            });
        }
    }
};
