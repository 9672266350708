import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Investigación</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div class="parrafo">
                        <p>COPYRIGHT Y CRÉDITOS</p>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <p>Todo el contenido original de la web se encuentra bajo una&nbsp;<a
                                href="https://creativecommons.org/licenses/by-nc/4.0/deed.es">licencia Creative Common
                                Atribuci&oacute;n-NoComercial 4.0</a>.</p>
                            <p>Eso implica que los usuarios pueden:</p>
                            <ul>
                                <li><strong>Compartir</strong>&nbsp;&mdash; copiar y redistribuir el material en cualquier medio o
                                    formato</li>
                                <li><strong>Adaptar</strong>&nbsp;&mdash; remezclar, transformar y construir a partir del material</li>
                            </ul>
                            <p>Bajo los siguientes t&eacute;rminos</p>
                            <ul>
                                <li><strong>Atribuci&oacute;n</strong>&nbsp;&mdash;&nbsp;Usted debe dar&nbsp;cr&eacute;dito de manera
                                    adecuada, brindar un enlace a la propia web y el apartado donde aparezca el contenido compartido o
                                    adaptado, e&nbsp;indicar si se han realizado cambios. Puede hacerlo en cualquier forma razonable.
                                </li>
                                <li><strong>NoComercial</strong>&nbsp;&mdash; Usted no puede hacer uso del material
                                    con&nbsp;prop&oacute;sitos comerciales.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>Material de terceros:</u></strong></h2>
                            <p>En nuestra web utilizamos material (m&uacute;sica, im&aacute;genes y v&iacute;deos) de terceros. Siempre
                                intentando cumplir con las licencias que indican y citando sus autores.</p>
                            <p>Por favor, si encuentras una obra sin identificar o con una licencia err&oacute;nea, nos lo puedes
                                indicar por cualquiera de los&nbsp;<a href="https://panacea.com.es/Contacto">m&eacute;todos de
                                    contacto</a>&nbsp;que tenemos habilitados.</p>
                            <p><u>Listado:</u></p>
                            <p><a href="https://pixabay.com/es">pixabay</a><u>:</u></p>
                            <table class="styled-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><strong>P&Aacute;GINA</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>CREADOR</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>LINK</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/index.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/olenchic-16658974/">olenchic</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/part%C3%ADculas-geometr%C3%ADa-l%C3%ADneas-201735/">https://pixabay.com/es/videos/part%C3%ADculas-geometr%C3%ADa-l%C3%ADneas-201735/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/AcercaDe.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/digital_view-26370553/">Digital_View</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/red-conexi%C3%B3n-plexo-cibern%C3%A9tico-112257/">https://pixabay.com/es/videos/red-conexi%C3%B3n-plexo-cibern%C3%A9tico-112257/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/Misi&oacute;n.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/digital_view-26370553/">Digital_View</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/red-molecular-conexi%C3%B3n-plexo-112259/">https://pixabay.com/es/videos/red-molecular-conexi%C3%B3n-plexo-112259/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/servicios.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/nadebrag-20439910/">Nadebrag</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/pir%C3%A1mide-pelota-esfera-azul-3d-135956/">https://pixabay.com/es/videos/pir%C3%A1mide-pelota-esfera-azul-3d-135956/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/tommyvideo-3092371/">tommyvideo</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/fumar-arte-dise%C3%B1o-movimiento-121602/">https://pixabay.com/es/videos/fumar-arte-dise%C3%B1o-movimiento-121602/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/terapias.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/tommyvideo-3092371/">tommyvideo</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/dise%C3%B1o-tecnolog%C3%ADa-gr%C3%A1fico-moderno-100994/">https://pixabay.com/es/videos/dise%C3%B1o-tecnolog%C3%ADa-gr%C3%A1fico-moderno-100994/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/tienda.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/skarletmotion-6385614/">skarletmotion</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/l%C3%ADnea-geom%C3%A9trico-centelleo-ne%C3%B3n-143060/">https://pixabay.com/es/videos/l%C3%ADnea-geom%C3%A9trico-centelleo-ne%C3%B3n-143060/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/Publicaciones</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/tommyvideo-3092371/">tommyvideo</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/pizarra-digital-tecnolog%C3%ADa-datos-100941/">https://pixabay.com/es/videos/pizarra-digital-tecnolog%C3%ADa-datos-100941/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/fitness.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/juliush-3921568/">juliusH</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/bicicleta-ciclismo-raza-velocidad-81915/">https://pixabay.com/es/videos/bicicleta-ciclismo-raza-velocidad-81915/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/investigaci&oacute;n.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/tommyvideo-3092371/">tommyvideo</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/dns-digital-espacio-acortar-55615/">https://pixabay.com/es/videos/dns-digital-espacio-acortar-55615/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/seguros.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/digital_expert-22525715/">Digital_Expert</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/metaverso-meta-realidad-virtual-115035/">https://pixabay.com/es/videos/metaverso-meta-realidad-virtual-115035/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/mascotas.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/bangsanggar-17408685/">bangsanggar</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/gatos-corazones-l%C3%ADnea-arte-191052/">https://pixabay.com/es/videos/gatos-corazones-l%C3%ADnea-arte-191052/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/emergency.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/skarletmotion-6385614/">scarletmotion</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/l%C3%ADnea-geom%C3%A9trico-centelleo-ne%C3%B3n-144260/">https://pixabay.com/es/videos/l%C3%ADnea-geom%C3%A9trico-centelleo-ne%C3%B3n-144260/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/IA.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/riaz-baloch-27092933/">riaz-baloch</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/inteligencia-artificial-tecnolog%C3%ADa-183107/">https://pixabay.com/es/videos/inteligencia-artificial-tecnolog%C3%ADa-183107/</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/contacto.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://pixabay.com/es/users/tommyvideo-3092371/">tommyvideo</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://pixabay.com/es/videos/bokeh-naranja-antecedentes-puntos-5248/">https://pixabay.com/es/videos/bokeh-naranja-antecedentes-puntos-5248/</a>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <table class="styled-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><a href="http://www.freepik.es/">freepik</a><u>:</u></p>
                                            <p>&nbsp;</p>
                                        </td>
                                        <td>
                                            <p><u>&nbsp;</u></p>
                                        </td>
                                        <td>
                                            <p><u>&nbsp;</u></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><strong>P&Aacute;GINA</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>CREADOR</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>LINK</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><u>Olga_spb</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm%20#fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm
                                                #fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><u>Olga_spb</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm%20#fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm
                                                #fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><u>Olga_spb</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm%20#fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm
                                                #fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://www.freepik.es/autor/kreativkolors">Olga_spb</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm%20#fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm
                                                #fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://www.freepik.es/autor/kreativkolors">Olga_spb</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm%20#fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm
                                                #fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://www.freepik.es/autor/kreativkolors">Olga_spb</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm%20#fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm
                                                #fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><u>Olga_spb</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm%20#fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/set-iconos-planos-medicos_881286.htm
                                                #fromView=search&amp;page=1&amp;position=1&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/coleccion-logos-holisticos-dibujados-mano_%2012429640.htm#fromView=search&amp;page=2&amp;position=23&amp;uuid=c23a6215-1021-47d3-92b6-a205ffa621e2">https://www.freepik.es/vector-gratis/coleccion-logos-holisticos-dibujados-mano_
                                                12429640.htm#fromView=search&amp;page=2&amp;position=23&amp;uuid=c23a6215-1021-47d3-92b6-a205ffa621e2</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/coleccion-logos-holisticos-dibujados-mano_12429640.htm#fromView=search&amp;page=2&amp;position=23&amp;uuid=c23a6215-1021-47d3-92b6-a205ffa621e2">https://www.freepik.es/vector-gratis/coleccion-logos-holisticos-dibujados-mano_12429640.htm#fromView=search&amp;page=2&amp;position=23&amp;uuid=c23a6215-1021-47d3-92b6-a205ffa621e2</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/sanitarios.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/coleccion-logos-holisticos-dibujados-mano_12429640.htm#fromView=search&amp;page=2&amp;position=23&amp;uuid=c23a6215-1021-47d3-92b6-a205ffa621e2">https://www.freepik.es/vector-gratis/coleccion-logos-holisticos-dibujados-mano_12429640.htm#fromView=search&amp;page=2&amp;position=23&amp;uuid=c23a6215-1021-47d3-92b6-a205ffa621e2</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/eventos.html</p>
                                        </td>
                                        <td>
                                            <p><a href="https://www.freepik.es/autor/kreativkolors">kreativkolors</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/coleccion-botones-abstractos-coloridos_807881.htm#fromView=search&amp;page=4&amp;position=27&amp;uuid=2124afc8-4934-4550-829e-c9a9b57c1579">https://www.freepik.es/vector-gratis/coleccion-botones-abstractos-coloridos_807881.htm#fromView=search&amp;page=4&amp;position=27&amp;uuid=2124afc8-4934-4550-829e-c9a9b57c1579</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/eventos.html</p>
                                        </td>
                                        <td>
                                            <p><u>kreativkolors</u></p>
                                        </td>
                                        <td>
                                            <p><u>https://www.freepik.es/vector-gratis/coleccion-botones-abstractos-coloridos_807881.htm#fromView=search&amp;page=4&amp;position=27&amp;uuid=2124afc8-4934-4550-829e-c9a9b57c1579</u>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/eventos.html</p>
                                        </td>
                                        <td>
                                            <p><u>kreativkolors</u></p>
                                        </td>
                                        <td>
                                            <p><u>https://www.freepik.es/vector-gratis/coleccion-botones-abstractos-coloridos_807881.htm#fromView=search&amp;page=4&amp;position=27&amp;uuid=2124afc8-4934-4550-829e-c9a9b57c1579</u>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/eventos.html</p>
                                        </td>
                                        <td>
                                            <p><u>kreativkolors</u></p>
                                        </td>
                                        <td>
                                            <p><u>https://www.freepik.es/vector-gratis/coleccion-botones-abstractos-coloridos_807881.htm#fromView=search&amp;page=4&amp;position=27&amp;uuid=2124afc8-4934-4550-829e-c9a9b57c1579</u>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/Publicaciones</p>
                                        </td>
                                        <td>
                                            <p><a href="https://www.freepik.es/autor/starline">starline</a></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/logotipo-forma-burbuja-dialogo_805035.htm#fromView=search&amp;page=1&amp;position=29&amp;uuid=014f1593-5b3c-4c57-8e68-5260f67ef540">https://www.freepik.es/vector-gratis/logotipo-forma-burbuja-dialogo_805035.htm#fromView=search&amp;page=1&amp;position=29&amp;uuid=014f1593-5b3c-4c57-8e68-5260f67ef540</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/Publicaciones</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/fondo-bocetos-elementos-blog_949323.htm#fromView=search&amp;page=2&amp;position=10&amp;uuid=014f1593-5b3c-4c57-8e68-5260f67ef540">https://www.freepik.es/vector-gratis/fondo-bocetos-elementos-blog_949323.htm#fromView=search&amp;page=2&amp;position=10&amp;uuid=014f1593-5b3c-4c57-8e68-5260f67ef540</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/investigaci&oacute;n.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/diseno-plantilla-logotipo-ciencia_41602679.htm#fromView=search&amp;page=3&amp;position=14&amp;uuid=5e312dfa-e5fe-4c04-b49f-92ebfac17d4c">https://www.freepik.es/vector-gratis/diseno-plantilla-logotipo-ciencia_41602679.htm#fromView=search&amp;page=3&amp;position=14&amp;uuid=5e312dfa-e5fe-4c04-b49f-92ebfac17d4c</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/mascotas.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/coleccion-logotipos-perro_1719349.htm#fromView=search&amp;page=1&amp;position=34&amp;uuid=d623e06d-3521-438e-8c52-3dbfab2f30a1">https://www.freepik.es/vector-gratis/coleccion-logotipos-perro_1719349.htm#fromView=search&amp;page=1&amp;position=34&amp;uuid=d623e06d-3521-438e-8c52-3dbfab2f30a1</a>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/mascotas.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><u>https://www.freepik.es/vector-gratis/coleccion-logotipos-perro_1719349.htm#fromView=search&amp;page=1&amp;position=34&amp;uuid=d623e06d-3521-438e-8c52-3dbfab2f30a1</u>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/mascotas.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><u>https://www.freepik.es/vector-gratis/coleccion-logotipos-perro_1719349.htm#fromView=search&amp;page=1&amp;position=34&amp;uuid=d623e06d-3521-438e-8c52-3dbfab2f30a1</u>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/emergency.html</p>
                                        </td>
                                        <td>
                                            <p><u>freepik</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://www.freepik.es/vector-gratis/concepto-moderno-emergencia-diseno-plano_3099290.htm#fromView=search&amp;page=1&amp;position=2&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b">https://www.freepik.es/vector-gratis/concepto-moderno-emergencia-diseno-plano_3099290.htm#fromView=search&amp;page=1&amp;position=2&amp;uuid=d1c15175-6b6d-43c3-ad0b-974ee0bd3b1b</a>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <p><u>vecteezy.com:</u></p>
                            <p><u>&nbsp;</u></p>
                            <table class="styled-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p><strong>P&Aacute;GINA</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>CREADOR</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>LINK</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>/eventos.html</p>
                                            <p>&nbsp;</p>
                                        </td>
                                        <td>
                                            <p><a href="https://es.vecteezy.com/miembros/anggadwiwahyu">anggadwiwahyu</a></p>
                                            <p><u>&nbsp;</u></p>
                                        </td>
                                        <td>
                                            <p><a
                                                href="https://es.vecteezy.com/video/11671079-animacion-de-hud-de-fondo-abstracto-videos-en-perspectiva-4k">https://es.vecteezy.com/video/11671079-animacion-de-hud-de-fondo-abstracto-videos-en-perspectiva-4k</a>
                                            </p>
                                            <p><u>&nbsp;</u></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <p>M&uacute;sica:</p>
                            <table class="styled-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>Music from #Uppbeat (free for Creators!): License code: S6F36HL1QURWLK5F</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p><a
                                                href="https://uppbeat.io/t/dan-phillipson/making-progress">https://uppbeat.io/t/dan-phillipson/making-progress</a>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="parrafo">
                        <p>Fecha de Publicación: <br />
                            3 de junio de 2024
                        </p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}