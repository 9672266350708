import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <footer id="footer">

            <nav>
                <ul className="menu">
                    <li className="menu-item"><Link to="/AcercaDe">Acerca De</Link></li>
                    <li className="menu-item"><Link to="/Mision">Misión</Link></li>
                    <li className="menu-item"><a href="#">Servicios</a></li>
                    <li><Link to="/Contacto">Contacto</Link></li>
                </ul>
            </nav>

            <div className="footer1">
                <ul>
                <li><Link to="/Politicas">Política de privacidad</Link></li>
                <li><Link to="/InformacionLegal">Información legal</Link></li>
                <li><Link to="/Cookies">Política de cookies</Link></li>
                <li><Link to="/Copyright">Copyright y Créditos</Link></li>
                </ul>
            </div>

            <div className="redes">
                <img src="Media/logo-panacea.png" alt="" />
                <p>PANACEA</p>
            </div>
        </footer>
    )
}