import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Seguros para mascotas</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>SEGUROS PARA MASCOTAS</h1>
                        <video src="./Media/gato.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>En los últimos años, especialmente desde la aprobación en 2023 en España de la Ley de Bienestar animal, la
                            preocupación por la salud y el bienestar de los animales ha llevado a un aumento significativo en la
                            contratación de seguros veterinarios. Desde PANACEA tratamos de ofrecerte una guía, a modo de orientación,
                            sobre algunos de los seguros de salud para mascotas disponibles en España.</p>
                    </div>

                    <div className="animatedtitle">
                        <h2>¿QUÉ SON?</h2>
                        <h3>y como funcionan</h3>
                    </div>

                    <div className="parrafoleft">
                        <p id="parrafoleft">
                            Funcionan de manera similar a los seguros de salud para personas, ofrecen diferentes niveles de cobertura
                            dependiendo de qué póliza se contrate. Son pólizas que cubren los costes veterinarios en caso de
                            enfermedades, accidentes y, en algunos casos, cuidados preventivos. <br /><br />

                            Una de las principales ventajas de estos seguros es que los propietarios de mascotas no tendrán que
                            preocuparse por los costes inesperados de tratamientos veterinarios.<br /><br />

                            Con un seguro apropiado, los propietarios pueden acceder a tratamientos avanzados y especializados que de
                            otro modo se harían casi inaccesibles por el alto precio.
                        </p>
                    </div>

                    <div className="texttable">
                        <h2>¿Qué coberturas tienen?</h2>
                        <div id="texttable">
                            <p><b>Básica:</b><br />

                                Generalmente incluye enfermedades comunes y accidentes. Es la opción más asequible y ofrece una buena
                                protección para eventos inesperados.</p>
                            <p><b>Completa:</b><br />

                                Incluye enfermedades graves, cirugías, hospitalizaciones y algunos cuidados preventivos. Este tipo de
                                póliza es más cara pero proporciona una cobertura más amplia.</p>
                            <p><b>Cobertura de bienestar:</b><br />

                                Cubre cuidados preventivos como vacunas, desparasitaciones y chequeos anuales. Es ideal para mantener a
                                los animales en óptimas condiciones de salud.</p>
                        </div>
                    </div>

                    <div className="parrafoleft">
                        <h2>Antes de contratar cualquier seguro, es importante tener estas consideraciones en cuenta:</h2>
                        <p id="parrafoleft">
                            <b>Exclusiones y limitaciones</b><br />

                            Es crucial que los tutores comprendan qué está excluido de la cobertura. Algunas pólizas no cubren
                            enfermedades preexistentes, ciertos tratamientos especializados, o condiciones hereditarias.<br /><br />

                            <b>Período de carencia</b><br />

                            Muchas pólizas tienen un período de carencia antes de que la cobertura entre en vigor. Esto puede variar
                            desde unos pocos días hasta varias semanas.<br /><br />



                            <b>Límites de cobertura</b><br />

                            Los seguros pueden tener límites anuales, por incidente, o de por vida sobre cuánto pagarán. Es importante
                            elegir una póliza con límites que se ajusten a las necesidades de tu mascota.<br /><br />
                        </p>
                    </div>

                    <div className="parrafoformat">
                        <div>
                            <h2>A continuación os dejamos un listado por orden alfabético de algunas de las Aseguradoras que existen en
                                España</h2>
                            <h3><strong>Asisa</strong></h3>
                            <p><strong>Cobertura</strong>: Enfermedades, accidentes, cuidados preventivos.</p>
                            <p><strong>Deducible</strong>: Fijo.</p>
                            <p><strong>L&iacute;mite de Cobertura</strong>: 1.500&euro; anuales.</p>
                            <h3><strong>Barkibu</strong></h3>
                            <p><strong>Cobertura:</strong></p>
                            <ul>
                                <li><strong>Cobertura de accidentes y enfermedades</strong>: Cubre gastos veterinarios relacionados con
                                    accidentes y enfermedades.</li>
                                <li><strong>Cobertura de medicamentos</strong>: Incluye el costo de los medicamentos necesarios para
                                    tratar
                                    condiciones cubiertas.</li>
                                <li><strong>Cobertura preventiva (opcional)</strong>: Algunos planes ofrecen cobertura para cuidados
                                    preventivos como vacunaciones y chequeos anuales.</li>
                            </ul>
                            <p><strong>Beneficios adicionales</strong></p>
                            <ul>
                                <li><strong>Asesoramiento veterinario 24/7</strong>: Los tutores pueden acceder a consultas veterinarias
                                    en
                                    l&iacute;nea las 24 horas del d&iacute;a.</li>
                                <li><strong>Reembolso r&aacute;pido</strong>: Proceso de reembolso eficiente, generalmente dentro de
                                    unos
                                    pocos d&iacute;as.</li>
                                <li><strong>Cobertura internacional</strong>: Algunos planes cubren tratamientos en el extranjero.</li>
                            </ul>
                            <p><strong>Consideraciones</strong></p>
                            <ul>
                                <li><strong>Per&iacute;odo de espera</strong>: Var&iacute;a seg&uacute;n el tipo de cobertura, pero
                                    generalmente hay un per&iacute;odo de espera antes de que la cobertura entre en vigor.</li>
                                <li><strong>Exclusiones</strong>: Enfermedades preexistentes y ciertos tratamientos pueden no estar
                                    cubiertos.</li>
                            </ul>
                            <h3><strong>Mapfre</strong></h3>
                            <p><strong>Cobertura</strong>: Enfermedades, accidentes, medicamentos, terapias alternativas.</p>
                            <p><strong>Deducible</strong>: Opciones de 75&euro; a 200&euro;.</p>
                            <p><strong>L&iacute;mite de cobertura</strong>: 3.000&euro; anuales.</p>
                            <h3><strong>Santaluc&iacute;a</strong></h3>
                            <p><strong>Cobertura</strong>: Enfermedades, accidentes, cirug&iacute;as, hospitalizaciones.</p>
                            <p><strong>Deducible</strong>: Variable.</p>
                            <p><strong>L&iacute;mite de cobertura</strong>: 2.000&euro; anuales.</p>
                            <h3><strong>Santevet</strong></h3>
                            <p><strong>Cobertura</strong></p>
                            <ul>
                                <li><strong>Cobertura de accidentes y enfermedades</strong>: Cubre una amplia gama de condiciones y
                                    tratamientos.</li>
                                <li><strong>Cobertura de hospitalizaci&oacute;n y cirug&iacute;as</strong>: Incluye los costos asociados
                                    con
                                    hospitalizaciones y cirug&iacute;as necesarias.</li>
                                <li><strong>Cobertura de medicamentos y terapias</strong>: Cubre los medicamentos y tratamientos
                                    terap&eacute;uticos prescritos por el veterinario.</li>
                            </ul>
                            <p><strong>Beneficios adicionales</strong></p>
                            <ul>
                                <li><strong>Sin L&iacute;mite de edad para inscripci&oacute;n</strong>: Santevet acepta animales de
                                    cualquier edad.</li>
                                <li><strong>Asistencia veterinaria de urgencia</strong>: Cobertura para emergencias veterinarias.</li>
                                <li><strong>Reembolsos de hasta el 90%</strong>: Dependiendo del plan, se puede obtener hasta un 90% de
                                    reembolso sobre los costos cubiertos.</li>
                            </ul>
                            <p><strong>Consideraciones</strong></p>
                            <ul>
                                <li><strong>Deducibles y copagos</strong>: Var&iacute;an seg&uacute;n el plan, con opciones de
                                    deducibles
                                    bajos a altos.</li>
                                <li><strong>Per&iacute;odo de espera</strong>: Similar a otros seguros, hay un per&iacute;odo de espera
                                    antes de que algunas coberturas entren en vigor.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="parrafo">
                        <p>
                            Desde PANACEA estamos trabajando para ofrecerte en breve el mejor comparador de seguros de Salud para tu
                            mascota, donde podrás elegir la póliza que mejor se adapte a tus necesidades.
                        </p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}