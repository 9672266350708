import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/scriptazul';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Seguros</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleblue.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>SEGUROS</h1>
                        <video src="./Media/seguros.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>El catálogo más amplio sobre los seguros de salud
                            que mejor se adaptan a nuestras necesidades,
                            teniendo en cuenta todo tipo de variables
                            y con recomendaciones a través de una IA.</p>
                    </div>

                    <div className="textimgtext" id="textimgtext">
                        <p>En España existen multitud de Aseguradoras que permiten realizar una gran variedad de pólizas, y también
                            existen compañías especializadas en seguros de Salud. Para que puedas elegir con más conocimiento, desde
                            PANACEA hemos elaborado un listado por orden alfabético con las compañías más relevantes que ofrecen seguros
                            de Salud.</p>
                        <div>
                            <img src="./Media/fila-1-columna-1.jpg" alt="" />
                            <p>· Aegon <br />
                                · Allianz<br />
                                · Almudena<br />
                                · Antares<br />
                                · Asefa<br />
                                · Asisa<br />
                                · Asistencia Sanitaria<br />
                                · Asistencia Sanitaria Colegial<br />
                                · ASSSA<br />
                            </p>
                        </div>
                        <div>
                            <img src="Media/fila-2-columna-1.jpg" alt="" />
                            <p> · AXA<br />
                                · Caser<br />
                                · Catalana Occidente<br />
                                · Cigna<br />
                                · Clinicum Salut<br />
                                · Divina Pastora<br />
                                · DKV<br />
                                · Fiatc<br />
                                · Generali<br />
                            </p>
                        </div>
                        <div>
                            <img src="Media/fila-3-columna-1.jpg" alt="" />
                            <p> · GES<br />
                                · HNA<br />
                                · Ima Ibérica<br />
                                · IMQ - Igualatorio Médico Quirúrgico<br />
                                · La Fe<br />
                                · la Mutua General de Cataluña<br />
                                · Mapfre<br />
                            </p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}