import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/scriptazul';
import { Link } from 'react-router-dom';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Terapias</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleblue.css" />
                </Helmet>
                <div>
                    <div className="videocontainerout">
                        <div className="videocontainer">
                            <h1>TERAPIAS</h1>
                            <p>MAC - Medicina Alternativa y Complementaria</p>
                            <video src="./Media/cuadradosazules.mp4" autoPlay muted loop playsInline></video>
                        </div>
                    </div>

                    <div className="parrafo">
                        <br/>
                        <p>Profesionales con terapias y soluciones médicas<br /> dentro del marco legal
                            y como opción a la medicina oficial:
                        </p><br/>
                    </div>

                    <div className="bigcards" id="bigcards">
                        <div>
                            <a href="#INTEGRALES">
                                <section>
                                    <img src="./Media/complementaria.png" />
                                    <p>Integrales</p>
                                </section>
                            </a>
                        </div>
                        <div>
                            <a href="#FISIO">
                                <section>
                                    <img src="./Media/alternativas.png" alt="" />
                                    <p>Fisio-Biológicas</p>
                                </section>
                            </a>
                        </div>
                        <div>
                            <a href="#CUERPO">
                                <section>
                                    <img src="./Media/integrativas.png" alt="" />
                                    <p>Cuerpo</p>
                                </section>
                            </a>
                        </div>
                        <div>
                            <a href="#MENTE">
                                <section>
                                    <img src="./Media/alternativas.png" alt="" />
                                    <p>Mente</p>
                                </section>
                            </a>
                        </div>
                        <div>
                            <a href="#ENERGIA">
                                <section>
                                    <img src="./Media/complementaria.png" alt="" />
                                    <p>Energía</p>
                                </section>
                            </a>
                        </div>
                    </div>

                    <div className="texttable" id="INTEGRALES">
                        <h2>Sistemas médicos integrales o completos:</h2>
                        <div id="texttable">
                            <p><b><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/homeopathy"
                                target="_blank">Homeopatía:</a></b><br />
                                Considera que la sustancia que causa enfermedad en grandes dosis, suministrada en muy pequeña cantidad,
                                estimula los mecanismos innatos curativos del organismo.</p>
                            <p><b><a href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/naturopat%c3%ada"
                                target="_blank">Medicina naturista o Naturopatía</a></b><br />
                                Basada en el poder curativo de la naturaleza y el propio cuerpo.</p>
                            <p><b><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/traditional-chinese-medicine-tcm"
                                target="_blank">Medicina tradicional china :</a></b><br />
                                Medicina milenaria originada en China.</p>
                            <p><b><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/ayurveda"
                                target="_blank">Ayurveda:</a></b><br />
                                Sistema médico tradicional de la India, originado hace más de 4.000 años</p>
                        </div>
                        <Link to="/terapiasCompletas"><b>Ver más</b></Link>
                    </div>

                    <div className="parrafoleft" id="FISIO">
                        <h2>Prácticas fisio-biológicas:</h2>
                        <p id="parrafoleft"><b><a href="https://www.genesiscare.com/es/apoyo-al-paciente/blog/que-es-la-fitoterapia#:~:text=La%20fitoterapia%20es%20la%20ciencia,trav%C3%A9s%20de%20las%20plantas%20medicinales."
                            target="_blank">Fitoterapia:</a></b><br />
                            Uso de plantas con una finalidad terapéutica para aliviar los síntomas, prevenir enfermedades o
                            restablecer
                            el estado de salud.<br /><br />
                            <b><a href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/dietoterapia-o-terapia-nutricional"
                                target="_blank">Terapia nutricional:</a></b><br />
                            Práctica con base biológica, es decir, en base a sustancias naturales para mejorar la salud, mediante
                            diferentes tipos de dieta.<br /><br />
                            <b><a href="https://www.merckmanuals.com/es-us/hogar/SearchResults?query=suplementos+nutricionales+y+vitaminas"
                                target="_blank" rel="noopener noreferrer">Tratamientos con suplementos nutricionales y
                                vitaminas:</a></b><br />
                            Gama de productos destinados a ofrecer un extra nutricional o vitamínico a los nutrientes habituales de
                            la
                            dieta.<br /><br />
                            <b><a href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/terapia-con-quelantes-o-de-quelaci%C3%B3n?query=quelantes"
                                target="_blank" rel="noopener noreferrer">Terapia con quelantes o de quelación:</a></b><br />
                            Práctica con base biológica que describe una reacción química en la que ciertas moléculas se unen a
                            átomos
                            metálicos (como calcio, cobre, hierro o plomo).
                        </p>
                        <div className="seemore"><Link to="/terapiasCompletas"><b>Ver más</b></Link></div>
                    </div>

                    <div className="texttable" id="CUERPO">
                        <h2>Prácticas de manipulación sobre el cuerpo:</h2>
                        <div id="texttable">
                            <p><b><a href="https://www.topdoctors.es/diccionario-medico/osteopatia-tratamiento" target="_blank"
                                rel="noopener noreferrer">Osteopatía:</a></b><br />
                                Especialidad orientada a restaurar y mantener el equilibrio del cuerpo para conseguir su recuperación
                                y la conservación de su capacidad de autorregulación.</p>
                            <p><b><a href="https://es.wikipedia.org/wiki/Shiatsu" target="_blank"
                                rel="noopener noreferrer">Shiatsu:</a></b><br />
                                Aplicación de presión digital, utilizando las palmas de las manos y los pulgares, por cortos periodos
                                de tiempo a lo largo de los meridianos (canales de la energía vital).</p>
                            <p><b><a href="https://tressalud.com/exactamente-en-que-consiste-el-quiromasaje/" target="_blank"
                                rel="noopener noreferrer">Quiromasaje:</a></b><br />
                                Conjunto de técnicas con el propósito de manipular los tejidos grasos, los musculares y la piel de un
                                paciente con el propósito de calmar sus dolores.</p>
                            <p><b><a href="https://www.topdoctors.es/diccionario-medico/drenaje-linfatico" target="_blank"
                                rel="noopener noreferrer">Drenaje linfático:</a></b><br />
                                Masaje suave y repetitivo, más lento que el habitual, que actúa sobre los vasos linfáticos con el fin
                                de eliminar el líquido estancado (intersticial y linfático).</p>
                        </div>
                        <div className="separador" id="texttable">
                            <img src="./Media/separadorazul.jpg" alt="" />
                        </div>
                        <div id="texttable">
                            <p><b><a href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/reflexolog%C3%ADa"
                                target="_blank" rel="noopener noreferrer">Reflexología:</a></b><br />
                                Presión manual aplicada a áreas específicas de la palma de la mano, el pie o la oreja, en la creencia
                                de que estas áreas corresponden a diferentes órganos o sistemas corporales.</p>
                            <p><b><a href="https://es.wikipedia.org/wiki/Moxibusti%C3%B3n" target="_blank"
                                rel="noopener noreferrer">Sotau/Gua Sha/Moxibustion:</a></b><br />
                                Tratamiento en el que se utilizan hojas pulverizadas, secadas y trituradas de la planta Artemisia
                                Abrotanum (abrótano macho) hasta convertirlas en polvo, a las que se les da forma de cigarro denominado
                                “moxa”, que se queman cerca de la piel del paciente o se usa indirectamente con agujas de acupuntura.
                            </p>
                            <p><b><a href="https://www.merckmanuals.com/es-us/professional/temas-especiales/medicina-integradora-alternativa-y-complementaria/quiropraxia?query=quiropraxia"
                                target="_blank" rel="noopener noreferrer">Quiropraxia:</a></b><br />
                                Práctica basada en la manipulación de la columna vertebral (sistema musculoesquelético) en el
                                entendimiento de que hay una relación entre ésta y la función del sistema nervioso como clave para
                                mantener o restablecer el flujo de energía vital y la salud.</p>
                            <p><b><a href="https://es.wikipedia.org/wiki/Aromaterapia" target="_blank"
                                rel="noopener noreferrer">Aromaterapia:</a></b><br />
                                Uso de materiales aromáticos, incluidos los aceites esenciales (producto obtenido a partir de materia
                                prima vegetal) y otros compuestos aromáticos, con el objetivo de mejorar el bienestar psicológico o
                                físico.</p>
                        </div>
                        <Link to="/terapiasCompletas"><b>Ver más</b></Link>
                    </div>

                    <div className="parrafoleft" id="MENTE">
                        <h2>Técnicas de la mente y cuerpo:</h2>
                        <p id="parrafoleft"><b><a href="https://medlineplus.gov/spanish/ency/patientinstructions/000876.htm#:~:text=El%20yoga%20es%20una%20pr%C3%A1ctica,espiritual%20hace%20miles%20de%20a%C3%B1os."
                            target="_blank" rel="noopener noreferrer">Yoga:</a></b><br />
                            Práctica que conecta cuerpo, respiración y mente.<br /><br />

                            <b><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/meditation"
                                target="_blank" rel="noopener noreferrer">Meditación:</a></b><br />
                            Técnica mediante la cual las personas regulan su atención o se enfocan sistemáticamente en aspectos
                            particulares de la experiencia interna o externa.<br /><br />

                            <b><a href="https://cuidateplus.marca.com/ejercicio-fisico/diccionario/tai-chi.html" target="_blank"
                                rel="noopener noreferrer">Tai Chi:</a></b><br />
                            Arte marcial que practican millones de personas en todo el mundo debido a los múltiples beneficios
                            que ofrece, tanto a nivel corporal como mental, además de la parte de meditación que incluye.<br /><br />

                            <b><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/guided-imagery"
                                target="_blank" rel="noopener noreferrer">Imágenes guiadas:</a></b><br />
                            Las imágenes guiadas implican el uso de imágenes mentales para promover la relajación y el bienestar,
                            reducir el dolor o facilitar la curación de una dolencia particular.<br /><br />

                            <b><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/biofeedback"
                                target="_blank" rel="noopener noreferrer">Biorretroalimentación:</a></b><br />
                            Método para poner los procesos biológicos inconscientes bajo control consciente.<br /><br />

                            <b><a href="https://www.hellinger.com/es/constelacion-familiar/" target="_blank"
                                rel="noopener noreferrer">Constelaciones familiares:</a></b><br />
                            Método psicoterapéutico que considera que las personas son capaces de percibir de forma inconsciente
                            patrones y estructuras en los sistemas de los que formamos parte, a los que denomina constelaciones, y
                            donde se puede incluir la familia, el equipo de trabajo (empresas u organizaciones) o, por ejemplo, los
                            grupos de amigos, llegando a considerar a la Humanidad entera como una familia.<br /><br />

                            <b><a href="https://institutodyn.com/kinesiologia-tipos-beneficios-curso/" target="_blank"
                                rel="noopener noreferrer">Kinesiología</a>:</b><br />
                            Terapia que estudia los movimientos del cuerpo con el propósito de encontrar el origen de los
                            dolores, ya sean físicos o emocionales.<br /><br />

                            <b><a href="https://www.merckmanuals.com/es-us/hogar/temas-especiales/medicina-alternativa-complementaria-e-integrativa/hipnoterapia"
                                target="_blank" rel="noopener noreferrer">Hipnoterapia:</a></b><br />
                            Inducción, mediante hipnosis o autohipnosis, a un estado de sugestión y atención avanzada o
                            aumentada.<br /><br />

                            <b><a href="https://es.wikipedia.org/wiki/Sofrolog%C3%ADa" target="_blank"
                                rel="noopener noreferrer">Sofrología:</a></b><br />
                            Conjunto de técnicas terapéuticas alternativas en el tratamiento de estrés y otros problemas
                            psicológicos.<br /><br />

                            <b><a href="https://es.wikipedia.org/wiki/Musicoterapia" target="_blank"
                                rel="noopener noreferrer">Musicoterapia:</a></b><br />
                            Uso de la música y/o sus elementos (sonido, ritmo, melodía, armonía) para facilitar y promover la
                            comunicación, las relaciones, el aprendizaje, el movimiento, la expresión, la organización y otros
                            objetivos terapéuticos relevantes, para así satisfacer las necesidades físicas, emocionales, mentales,
                            sociales y cognitivas de un paciente o grupo.<br /><br />

                            <b><a href="https://www.saludterapia.com/glosario/d/6-arteterapia.html" target="_blank"
                                rel="noopener noreferrer">Arteterapia:</a></b><br />
                            Utilización del arte como vía terapéutica, y como técnica de desarrollo personal, de autoconocimiento
                            y de expresión emocional.
                        </p>
                        <div className="seemore"><Link to="/terapiasCompletas"><b>Ver más</b></Link></div>
                    </div>

                    <div className="imagestext" id="ENERGIA">
                        <h2>Técnicas sobre campos de energía:</h2>
                        <div>
                            <img src="./Media/dalle1.png" alt="" />
                            <p><b><a href="http://https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/acupuncture"
                                target="_blank" rel="noopener noreferrer">Acupuntura:</a></b><br />
                                Terapia de la medicina tradicional china, con alta aceptación en Occidente.</p>
                        </div>
                        <div>
                            <img src="./Media/dalle2.png" alt="" />
                            <p><b><a href="https://es.wikipedia.org/wiki/Chi_kung" target="_blank" rel="noopener noreferrer">Chi-kung
                                (en chino)/Qi-Gong (en pinyin):</a></b><br />
                                Diversidad de técnicas asociadas a la medicina tradicional china que comprenden la mente, la
                                respiración y el ejercicio físico, mediante secuencias, movimientos y posturas suaves para cualquier
                                practicante.</p>
                        </div>
                        <div>
                            <img src="./Media/dalle3.png" alt="" />
                            <p><b><a href="https://www.merckmanuals.com/home/special-subjects/integrative-complementary-and-alternative-medicine/reiki"
                                target="_blank" rel="noopener noreferrer">Reiki:</a> </b><br />
                                Medicina energética originaria de Japón.</p>
                        </div>
                        <div>
                            <img src="./Media/dalle4.png" alt="" />
                            <p><b><a href="https://blog.naturlider.com/que-es-la-terapia-biomagnetica-o-terapia-con-imanes/"
                                target="_blank" rel="noopener noreferrer">Terapia biomagnética o de campos
                                magnéticos:</a></b><br />
                                Terapia natural y segura, sin efectos secundarios o colaterales, no invasiva e indolora, que
                                restablece el medio celular normal, mediante el uso de pares de imanes con una intensidad mínima de
                                1.000 gauss (unidad de medida del campo magnético).</p>
                        </div>
                        <section className="seemore"><Link to="/terapiasCompletas"><b>Ver más</b></Link></section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}