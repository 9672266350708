// animations.js

// Observers para animaciones
export const appearright = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'appearright 1s ease';
        }
    });
});

export const drop = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'drop 1s ease';
        }
    });
});

export const unfade = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'unfade 2s ease forwards';
        }
    });
});

export const appearleft = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'appearleft 1s ease';
        }
    });
});

// Función para inicializar animaciones
export const initializeAnimations = () => {
    // SANITARIOS
    const cardsContainer = document.getElementById('cards');
    if (cardsContainer) {
        const cards = cardsContainer.querySelectorAll('div');
        cards.forEach((card) => {
            appearright.observe(card);
        });
    }

    // TERAPIAS
    const bigcards = document.getElementById('bigcards');
    if (bigcards) {
        const cards = bigcards.querySelectorAll('div');

        // Verifica si el ancho de la ventana es mayor que 600px
        if (window.innerWidth > 600) {
            cards.forEach((card) => {
                drop.observe(card); // Aquí va tu animación u observador
            });
        } else {
            cards.forEach((card) => {
                unfade.observe(card); // Aquí va tu animación u observador
            });
        }
    }

    const paragraphs = document.querySelectorAll('#parrafoleft');
    if (paragraphs) {
        paragraphs.forEach((p) => {
            appearright.observe(p);
        });
    }

    const texttable = document.querySelectorAll('#texttable');
    if (texttable) {
        texttable.forEach((p) => {
            unfade.observe(p);
        });
    }

    // ENERGÍA
    const alt1 = document.getElementById('ENERGIA');
    if (alt1) {
        const divs = alt1.querySelectorAll('div');
        appearleft.observe(divs[0]); // Primer div
        appearright.observe(divs[1]); // Segundo div
        appearleft.observe(divs[2]); // Tercer div
        appearright.observe(divs[3]); // Cuarto div
    }

    // SEGUROS
    const textimgtext = document.getElementById('textimgtext');
    if (textimgtext) {
        const cards = textimgtext.querySelectorAll('div');
        cards.forEach((card) => {
            appearright.observe(card);
        });
    }

    // TERAPIASFULL
    const format = document.querySelectorAll('#format');
    if (format) {
        format.forEach((div) => {
            unfade.observe(div);
        });
    }

    const format2 = document.querySelectorAll('#format2');
    if (format2) {
        format2.forEach((div) => {
            appearright.observe(div);
        });
    }
};
