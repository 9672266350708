import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';
import { Link } from "react-router-dom";

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Mascotas</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>NUTRICION MASCOTAS</h1>
                        <video src="./Media/gato.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>Nuestras mascotas hace mucho que han dejado de ser un animal de compañía
                            para convertirse en un miembro más de nuestra familia.
                            En Panacea contamos con un espacio para ellas:</p>
                    </div>

                    <div className="imagestext" id="alt3">
                        <Link to="/NutricionPerros">
                            <div>
                                <img src="./Media/nutricionperro.png" alt="" />
                                <h2>Nutrición para perros</h2>
                            </div>
                        </Link>
                        <Link to="/NutricionGatos">
                            <div>
                                <img src="./Media/nutriciongato.png" alt="" />
                                <h2>Nutricion para gatos</h2>
                            </div>
                        </Link>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}