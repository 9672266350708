// GlobalMusicButton.js
import React from 'react';
import { useMusicPlayer } from './MusicPlayerContext';

const GlobalMusicButton = () => {
  const { isPlaying, togglePlay } = useMusicPlayer();

  return (
    <button 
      onClick={togglePlay} 
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '12px 24px',
        borderRadius: '8px', // Bordes redondeados
        backgroundColor: isPlaying ? '#000000' : '#ffffff', // Negro cuando reproduce, blanco cuando está en pausa
        color: isPlaying ? '#ffffff' : '#000000', // Texto blanco cuando reproduce, negro cuando está en pausa
        fontSize: '16px',
        fontWeight: 'bold',
        border: '2px solid #000000', // Borde negro
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Sombra ligera
        cursor: 'pointer',
        zIndex: 100, // Siempre visible
        transition: 'background-color 0.3s, transform 0.2s', // Transiciones suaves
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = isPlaying ? '#333333' : '#f1f1f1'; // Fondo gris oscuro cuando pasa el ratón
        e.currentTarget.style.transform = 'scale(1.05)'; // Efecto de agrandamiento
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = isPlaying ? '#000000' : '#ffffff'; // Restablece el color de fondo
        e.currentTarget.style.transform = 'scale(1)';
      }}
    >
      {isPlaying ? 'Pause' : 'Play'}
    </button>
  );
};

export default GlobalMusicButton;
