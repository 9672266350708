import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/scriptazul';


export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Sanitarios</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleblue.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>SANITARIOS</h1>
                        <video src="./Media/azul bonito.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>Especialistas y otros profesionales</p>
                        <p>relacionados con las diferentes ramas de la salud oficial:</p>
                    </div>

                    <div className="cards" id="cards">
                        <div>
                            <img src="Media/farmacia.png" alt=""/>
                                <p>Facultativos Médicos</p>
                        </div>
                        <div>
                            <img src="Media/guantes.png" alt=""/>
                                <p>Personal de enfermería</p>
                        </div>
                        <div>
                            <img src="Media/sanitarios7.png" alt=""/>
                                <p>Fisioterapeutas</p>
                        </div>
                        <div>
                            <img src="Media/farmacia2.png" alt=""/>
                                <p>Farmacéuticos</p>
                        </div>
                        <section className="sep">
                        </section>
                        <div>
                            <img src="Media/atomo.png" alt=""/>
                                <p>Dietistas</p>
                        </div>
                        <div>
                            <img src="Media/kit.png" alt=""/>
                                <p>Trabajadores sociales</p>
                        </div>
                        <div>
                            <img src="Media/corazon.png" alt=""/>
                                <p>Auxiliares de la salud</p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}