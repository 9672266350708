import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";

export default function () {
    return (
        <>
            <Header />
            <Helmet>
                <title>PANACEA - Contacto</title>
                <link rel="stylesheet" type="text/css" href="/utils/style.css" />
            </Helmet>
            <div>
                <div className="videocontainerout">
                    <div className="videocontainer">
                        <h1>CONTACTO</h1>
                        <video src="./Media/bolasnaranjas.mp4" autoPlay muted loop playsInline></video>
                    </div>
                </div>

                <div className="parrafo">
                    <p>Panacea es una solución a una necesidad social: <br />
                        A través de una plataforma online, se integra a todo el sector de la Medicina y la Salud
                        en una herramienta sencilla y accesible.</p>
                </div>

                <div className="contactform">
                    <form action="https://formsubmit.co/hola@panacea.com.es" method="POST" id="contactForm">
                    <label for="name">Nombre:</label>
                    <input type="text" id="name" name="name" required />

                    <label for="email">Tu Email:</label>
                    <input type="email" id="email" name="email" required/>

                        <label for="message">Mensaje:</label>
                        <textarea id="message" name="message" required></textarea><br />

                        <button type="submit">Enviar</button>
                    </form>
                </div>
                <Footer />
            </div>
        </>
    )
}