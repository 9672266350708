import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";


export default function () {
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Tienda</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleblue.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>TIENDA</h1>
                        <video src="./Media/piramides.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>Acuerdos con Farmacias y Parafarmacias, Herbolarios, Laboratorios,
                            Plataformas de eCommerce...<br/> Con artículos relacionados con la Salud y el
                                Bienestar.</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}