export const rotate2 = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'rotate2 2s ease infinite';
        }
    });
});

export const appearright = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'appearright 1s ease';
        }
    });
});

export const appearleft = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'appearleft 1s ease';
        }
    });
});

export const unfade = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.style.animation = 'unfade 2s ease';
        }
    });
});

export const initializeResearchAnimations = () => {
    // EMERGENCY
    const textimg = document.getElementById('textimg');
    if (textimg) {
        unfade.observe(textimg);
    }
};
