import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Investigación</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div class="parrafo">
                        <p>AVISO LEGAL</p>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <p class="demoTitle">&nbsp;</p>
                            <h2><strong><u>Datos identificativos del titular del sitio web:</u></strong></h2>
                            <p>De conformidad con lo estipulado en el art&iacute;culo 10 de la Ley 34/2002 de 11 de julio de Servicios
                                de la Sociedad de la Informaci&oacute;n y Comercio Electr&oacute;nico (LSSICE), Universo Feel, S.L.,
                                cuya l&iacute;nea de negocio principal se define bajo la denominaci&oacute;n de
                                <strong>&ldquo;PANACEA&rdquo;</strong>, y que en adelante aparece con tal nombre en cada menci&oacute;n,
                                en calidad de titular del sitio web, comunica los siguientes datos identificativos:
                            </p>
                            <ul>
                                <li>Denominaci&oacute;n social: Universo Feel, S.L.</li>
                                <li>Domicilio social: Avda. Alcalde Jos&eacute; Ram&iacute;rez Bethencourt, 13-bajo, 35004. Las Palmas
                                    de Gran Canaria. Islas Canarias &ndash; Espa&ntilde;a</li>
                                <li>NIF: B72990062</li>
                                <li>Direcci&oacute;n de correo electr&oacute;nico: hola@panacea.com.es</li>
                            </ul>
                            <p>Inscrita en el registro mercantil de Las Palmas, tomo 2312, folio 119, hoja GC-60694, inscripci&oacute;n
                                1&ordf;.&nbsp;</p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>Condiciones de uso:</u></strong></h2>
                            <p>La utilizaci&oacute;n de este sitio web implica la aceptaci&oacute;n por su parte de las condiciones de
                                uso impuestas por PANACEA.</p>
                            <p>Al acceder y usar este sitio web de cualquier forma, incluyendo, de forma enunciativa pero no limitativa,
                                la exploraci&oacute;n del sitio web, el uso de cualquier informaci&oacute;n, el uso de cualquier
                                contenido, descarga de cualquier material y/o la informaci&oacute;n o contenido que usted transmita al
                                propio sitio web mediante cualquier herramienta o formulario, usted se compromete a cumplir las
                                condiciones de uso que se exponen.</p>
                            <p>En consecuencia, el Usuari@ debe leer atentamente estas condiciones generales antes de acceder al Sitio
                                Web, ya que estas pueden sufrir modificaciones para adaptarse a cambios operativos, t&eacute;cnicos o
                                legislativos. Como consecuencia de lo anterior, PANACEA se reserva el derecho de actualizar o modificar
                                las condiciones del Sitio web.</p>
                            <p>En este sentido, se entender&aacute; por Usuari@ a la persona que acceda, navegue, utilice o participe en
                                los servicios y actividades, gratuitas u onerosas, desarrolladas en la Web.</p>
                            <p>El acceso a la Web por parte de los Usuari@s tiene car&aacute;cter libre y gratuito. No obstante, algunos
                                de los servicios y contenidos ofrecidos por PANACEA, por s&iacute; mismo, o a trav&eacute;s de terceros,
                                a trav&eacute;s de la Web, pueden encontrarse sujetos a la contrataci&oacute;n previa del servicio o
                                producto y al pago de una cantidad de dinero en la forma que se determine en las correspondientes
                                Condiciones Particulares, en cuyo caso se pondr&aacute;n a su disposici&oacute;n de forma clara.</p>
                            <p>Cuando sea necesario que el Usuario se registre o aporte datos personales para poder acceder a alguno de
                                los servicios, la recogida y el tratamiento de los datos personales de los Usuarios ser&aacute; de
                                aplicaci&oacute;n lo dispuesto en la Pol&iacute;tica de Privacidad recogida en la Web.</p>
                            <p>Queda prohibida la contrataci&oacute;n de productos y servicios a trav&eacute;s del Sitio Web por parte
                                de menores de edad legal debiendo obtener, debidamente y con anterioridad, el consentimiento de sus
                                padres, tutores o representantes legales, los cuales ser&aacute;n considerados como responsables de los
                                actos que lleven a cabo los menores a su cargo, conforme a la normativa vigente.</p>
                            <p>PANACEA podr&aacute; establecer unas condiciones particulares que regulen la utilizaci&oacute;n y/o
                                contrataci&oacute;n de servicios espec&iacute;ficos ofrecidos a los Usuarios a trav&eacute;s del Sitio
                                Web.</p>
                            <p>De igual forma, y en tanto PANACEA se dirige al sector de la Medicina y la Salud, cada profesional,
                                empresa, marca o administraci&oacute;n que se registre, debe de hacerlo siguiendo estrictamente la
                                legalidad vigente respecto al desarrollo de su trabajo o negocio, y siempre bajo las condiciones de
                                veracidad, honestidad y respeto entre s&iacute; y con cualquier usuari@.</p>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <h2><strong><u>L&iacute;mite de la responsabilidad:</u></strong></h2>
                            <p>El acceso a la Web precisa de un suministro de terceros, incluidos el transporte a trav&eacute;s de redes
                                de telecomunicaciones cuya calidad, continuidad y funcionamiento no dependen de PANACEA,
                                reserv&aacute;ndose esta el derecho a suspender o cancelar en cualquier momento el acceso a la Web y a
                                los servicios e informaciones contenidas en la misma.</p>
                            <p>El material e informaci&oacute;n contenidos en la Web no supone, en ning&uacute;n caso, asesoramiento
                                alguno. Por dicho motivo, el usuario no debe actuar sobre la informaci&oacute;n disponible en la Web sin
                                contar con anterioridad con el correspondiente consejo profesional y/o el acuerdo contractual con
                                PANACEA y sus profesionales.</p>
                            <p>El acceso a la Web no implica la obligaci&oacute;n de PANACEA de controlar la adecuaci&oacute;n,
                                actualizaci&oacute;n y veracidad de la informaci&oacute;n suministrada a trav&eacute;s de la misma, no
                                asumiendo responsabilidad de las decisiones que puedan tomarse a partir de la informaci&oacute;n
                                ofrecida en la Web, ni de los da&ntilde;os y perjuicios que puedan producirse derivados del uso de la
                                informaci&oacute;n aqu&iacute; contenida, y ser&aacute; responsabilidad de cada profesional, empresa,
                                marca o administraci&oacute;n que la haya suministrado.</p>
                            <p>En ning&uacute;n caso, la existencia de sitios web enlazados debe presuponer la existencia de acuerdos
                                con los responsables o titulares de los mismos, ni la recomendaci&oacute;n, promoci&oacute;n o
                                identificaci&oacute;n de PANACEA con las manifestaciones, contenidos o servicios prove&iacute;dos por
                                los mismos, incluso cuando sea a trav&eacute;s de la IA Generativa de PANACEA, debiendo ser confirmada
                                fehacientemente por cada profesional, marca, empresa o administraci&oacute;n. Para determinadas
                                cuestiones de tipo estrictamente de negocio ser&aacute; preciso solicitar informaci&oacute;n expresa a
                                PANACEA y a tal fin, y bajo la firma, en el caso de que as&iacute; se requiera, de un NDA o acuerdo de
                                confidencialidad.</p>
                            <p>El acceso a la Web no implica la obligaci&oacute;n de PANACEA de verificar la ausencia de virus, o
                                cualquier otro elemento inform&aacute;tico da&ntilde;ino, no respondiendo de los da&ntilde;os y
                                perjuicios que tales elementos pudieran ocasionar al usuario o a terceros. Corresponde al usuario, en
                                todo caso, la disponibilidad de herramientas adecuadas para la detecci&oacute;n y desinfecci&oacute;n de
                                programas inform&aacute;ticos da&ntilde;inos.</p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <p>PANACEA no asume ninguna responsabilidad de los da&ntilde;os productos en los equipos inform&aacute;ticos
                                de los usuarios o de terceros durante la navegaci&oacute;n de la Web.</p>
                            <p>PANACEA no se hace responsable del uso inadecuado de los contenidos de la web. En este sentido, la
                                responsabilidad recae, &uacute;nica y exclusivamente, sobre la persona, ya sea f&iacute;sica o
                                jur&iacute;dica, que accede y utiliza los contenidos que se contemplan en esta web. Por este motivo,
                                aconsejamos encarecidamente a los usuarios que accedan a la web que hagan un uso responsable de los
                                contenidos que puedan subir, compartir y/o expresar en nuestra web, teniendo en cuenta la
                                legislaci&oacute;n vigente.</p>
                            <p>PANACEA tampoco se hace responsable del contenido expuesto por l@s usuari@s dentro de la web, siendo
                                responsables dichos usuario@s, y a todos los efectos, de dicho contenido.</p>
                            <p>PANACEA tampoco se responsabiliza por la informaci&oacute;n contenida en las p&aacute;ginas web de
                                terceros a las que se pueda acceder a trav&eacute;s de enlaces o buscadores desde esta web. En este
                                sentido, PANACEA no asume responsabilidad por los da&ntilde;os y perjuicios que pudieran sufrir los
                                usuarios como consecuencia de fallos, errores, defectos, omisiones y/o falseo de la informaci&oacute;n
                                que se facilite en la web cuando proceda de terceros ajenos a PANACEA.</p>
                            <p>De ninguna manera PANACEA, por s&iacute; misma, o a trav&eacute;s sus proveedores respectivos, es
                                responsable de da&ntilde;os especiales, indirectos o consecuentes o de da&ntilde;os de cualquier
                                &iacute;ndole que se produjeran por falta de uso, p&eacute;rdida de datos o por lucro cesante, ya sea
                                que se diesen bajo contrato, por negligencia u otra acci&oacute;n, que se derivasen de o se relacionasen
                                al uso o rendimiento de la informaci&oacute;n que se encuentra en el sitio web.</p>
                            <p>Los documentos y gr&aacute;ficos relacionados y publicados en los servicios podr&iacute;an tener
                                inexactitudes t&eacute;cnicas o errores tipogr&aacute;ficos. Peri&oacute;dicamente se a&ntilde;aden
                                cambios a la informaci&oacute;n aqu&iacute; contemplada.</p>
                            <p>El sitio web tiene servicios de correo electr&oacute;nico, servicios de boletines electr&oacute;nicos,
                                &aacute;reas de chat y/u otros medios de mensajer&iacute;a y comunicaci&oacute;n dise&ntilde;ados para
                                que cualquier usuari@ se comunique con PANACEA.</p>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <p>Cada usuari@ se obliga a usar los contenidos de forma diligente, correcta y l&iacute;cita y, en
                                particular, adem&aacute;s de usar los servicios para enviar y recibir mensajes y material que se
                                consideren correctos y se compromete a abstenerse de:</p>
                            <ul>
                                <li>Utilizar los contenidos con fines o efectos contrarios a la ley, a la moral y a las buenas
                                    costumbres generalmente aceptadas o al orden p&uacute;blico.</li>
                                <li>Reproducir o copiar, distribuir, permitir el acceso del p&uacute;blico a trav&eacute;s de cualquier
                                    modalidad de comunicaci&oacute;n p&uacute;blica, transformar o modificar los contenidos, a menos que
                                    se cuente con la autorizaci&oacute;n del titular de los correspondientes derechos o ello resulte
                                    legalmente permitido, tal cual se ha explicitado en el apartado de Copyright y Cr&eacute;ditos de la
                                    Web.</li>
                                <li>Emplear los contenidos y, en particular, la informaci&oacute;n de cualquier clase obtenida a
                                    trav&eacute;s de la p&aacute;gina o de los servicios para remitir publicidad, comunicaciones con
                                    fines de venta directa o indirecta, o con cualquier otra clase de finalidad comercial, mensajes no
                                    solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, as&iacute;
                                    como a abstenerse de comercializar o divulgar de cualquier modo dicha informaci&oacute;n en
                                    encuestas, concursos, pir&aacute;mides, cartas en cadena, correo electr&oacute;nico basura, correo
                                    electr&oacute;nico de publicidad indeseada o cualquier mensaje duplicado o no solicitado (comercial
                                    o no comercial).</li>
                                <li>En ning&uacute;n caso se permitir&aacute; la publicaci&oacute;n de contenidos que de forma
                                    manifiesta fomenten el odio, el desprecio o la discriminaci&oacute;n por motivos de nacimiento,
                                    raza, sexo, religi&oacute;n, nacionalidad, opini&oacute;n o cualquier otra circunstancia personal,
                                    laboral, o social, as&iacute; como ataques contra ninguno de los miembros o colaboradores con la
                                    intenci&oacute;n de difamar, abusar, hostigar, acechar, amenazar o violar de manera alguna los
                                    derechos que la ley confiere a los dem&aacute;s (tales como el derecho a la intimidad, privacidad y
                                    la publicidad), pudiendo ser denunciados convenientemente, en tanto cada usuari@ reconoce asumir la
                                    responsabilidad de sus manifestaciones.</li>
                                <li>Tampoco se tolerar&aacute;n, en ning&uacute;n caso, sea cual sea su fin, los intentos de suplantar
                                    la identidad de terceras personas ni la publicaci&oacute;n de datos de contacto privados.</li>
                                <li>Igualmente, no se aprobar&aacute;n mensajes que contengan &lsquo;spam&rsquo; ni aquellos con enlaces
                                    a sitios que nada tengan que ver con el motivo de la conversaci&oacute;n o la utilizaci&oacute;n que
                                    se haga en el chat informativo, Telegram, WhatsApp, Signal o cualquier otra app de
                                    comunicaci&oacute;n o email.</li>
                                <li>No est&aacute; permiti&oacute; cargar o poner a disposici&oacute;n de cualquier manera, archivos que
                                    contengan im&aacute;genes, fotograf&iacute;as, software o cualquier otro material que est&eacute;
                                    amparado por las leyes de protecci&oacute;n intelectual, como son, a modo de ejemplo, pero sin
                                    limitarse a ello, las leyes de copyright o de marca comercial (o amparados por los derechos a la
                                    privacidad y la publicidad) a menos que quien lo utilice (usuari@) sea el titular de tales derechos,
                                    tenga potestad sobre ellos, o haya recibido la autorizaci&oacute;n necesaria para gozar de ellos o
                                    ponerlos a disposici&oacute;n de terceros sin violar derecho alguno de copyright, marca comercial,
                                    patente, secreto industrial, o cualquier otro derecho de propiedad de cualquiera de las partes</li>
                                <li>Est&aacute; prohibido cargar archivos que contengan virus, troyanos, insectos web, bombas de tiempo,
                                    trampas, archivos corruptos o cualquier otro software o programa similar que pudiera da&ntilde;ar la
                                    operaci&oacute;n del equipo o la propiedad de otra persona o descargar un archivo enviado por otr@
                                    usuario@ de un servicio de comunicaci&oacute;n que usted sabe, o dentro de lo razonable
                                    deber&iacute;a saber, que por ley no se puede reproducir, mostrar, ejecutar y/o distribuir de tal
                                    manera.</li>
                                <li>No est&aacute; autorizado recopilar o recoger de manera alguna informaci&oacute;n sobre otr@s,
                                    incluso direcciones de correo electr&oacute;nico, o crear una identidad falsa en la
                                    comunicaci&oacute;n con el fin de enga&ntilde;ar a PANACEA.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <p>Cada usuari@ es responsable de la totalidad de las manifestaciones en Internet, igual que en cualquier
                                otro lugar.</p>
                            <p>PANACEA se reserva el derecho de suprimir, por cualquier raz&oacute;n y sin previo aviso, cualquier
                                informaci&oacute;n o contenido generado en los espacios de participaci&oacute;n.</p>
                            <p>Cada usuari@ reconoce, declara, garantiza y acepta:</p>
                            <ul>
                                <li>Que es mayor de edad a los efectos legales.</li>
                                <li>Que, en caso de suministrar informaci&oacute;n propia, &eacute;sta es veraz y en caso de que
                                    env&iacute;e cualquier contenido es autor/a o propietari@ de dicho contenido y que no infringe
                                    ning&uacute;n derecho de propiedad intelectual, de marca o cualquier otro derecho de tercero.</li>
                            </ul>
                            <p>Puede usted rechazar el tratamiento de los datos o la informaci&oacute;n rechazando el uso de cookies
                                mediante la selecci&oacute;n de la configuraci&oacute;n apropiada de su navegador.</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>

                    <div class="parrafo">
                        <p>
                            Al utilizar este sitio Web usted consiente el tratamiento de información acerca de usted por PANACEA en la
                            forma y para los fines arriba indicados. <br />
                            Fecha de Publicación: <br />
                            3 de junio de 2024
                        </p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}