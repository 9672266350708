import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";

export default function () {
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - IA</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>IA</h1>
                        <video src="./Media/ia.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>Dania, nuestra Inteligencia Artificial Generativa, responderá a tus preguntas,
                            facilitándote información y guiándote para que puedas resolver cualquier necesidad
                            relacionada con tu salud y bienestar.</p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}