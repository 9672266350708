import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Investigación</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div class="parrafo">
                        <p>POLÍTICA DE COOKIES</p>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <p>Seg&uacute;n la Directiva de Privacidad y Comunicaciones Electr&oacute;nicas Europeas ePrivacy de 2012,
                                recogida en Espa&ntilde;a en el Real Decreto-Ley 13/2012 de 2 de marzo de 2012, y con la entrada en
                                vigor del nuevo Reglamento de Protecci&oacute;n de Datos europeo (RGPD) el 25 de mayo de 2018, mediante
                                el cual se pone en marcha el Reglamento de Privacidad y Comunicaciones electr&oacute;nicas, seg&uacute;n
                                la regulaci&oacute;n 2016/679, <strong>Universo Feel, SL</strong>, como empresa responsable de la web
                                <strong>www.panacea.com.es</strong>, de la que <strong>PANACEA </strong>es una l&iacute;nea de negocio,
                                (a partir de ahora mencionados indistintamente como la empresa, UNIVERSO FEEL y/o PANACEA) informa a sus
                                usuarios de su Pol&iacute;tica de Cookies.
                            </p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>Cookies activas en la web:</u></strong></h2>
                            <h2>Necesaria:</h2>
                            <p>Las cookies necesarias son cruciales para las funciones b&aacute;sicas del sitio web y el sitio web no
                                funcionar&aacute; de la forma prevista sin ellas.</p>
                            <p>Estas cookies no almacenan ning&uacute;n dato de identificaci&oacute;n personal.</p>
                            <table class="styled-table">
                                <thead>
                                    <tr>
                                        <td>
                                            <p><strong>Cookie</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>Duraci&oacute;n</strong></p>
                                        </td>
                                        <td>
                                            <p><strong>Descripci&oacute;n</strong></p>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>cookieyes-consent</p>
                                        </td>
                                        <td>
                                            <p>1 a&ntilde;o</p>
                                        </td>
                                        <td>
                                            <p>CookieYes establece esta cookie para recordar las preferencias de consentimiento de los
                                                usuarios, de modo que se respeten sus preferencias en sus siguientes visitas a este
                                                sitio. No recoge ni almacena ninguna informaci&oacute;n personal de los visitantes del
                                                sitio.</p>
                                            <p>//</p>
                                            <p>CookieYes sets this cookie to remember users' consent preferences so that their
                                                preferences are respected on their subsequent visits to this site. It does not collect
                                                or store any personal information of the site visitors.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <h2><strong>&iquest;Qu&eacute; es una cookie?</strong></h2>
                            <p>Una cookie es un archivo de texto que un servidor web puede guardar en el disco duro de tu equipo para
                                almacenar alg&uacute;n tipo de informaci&oacute;n sobre ti como usuario.</p>
                            <p>Las cookies se utilizan con diversas finalidades tales como:</p>
                            <ul>
                                <li>Almacenar datos para pr&oacute;ximas visitas,</li>
                                <li>Para reconocer al usuario y evitar pedir de nuevo la autentificaci&oacute;n,</li>
                                <li>Para saber que paginas visita,</li>
                                <li>o para guardar sus preferencias en &aacute;reas personalizables.</li>
                            </ul>
                            <p>Normalmente los sitios web utilizan las cookies para obtener informaci&oacute;n estad&iacute;stica sobre
                                sus p&aacute;ginas web, y analizar el comportamiento de sus clientes/usuarios.</p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>Tipos de cookies:</u></strong></h2>
                            <p>Las cookies pueden clasificarse seg&uacute;n los siguientes criterios:</p>
                            <p>Seg&uacute;n&nbsp;<strong>quien instala</strong>&nbsp;la cookie &eacute;stas pueden ser:</p>
                            <ul>
                                <li><strong>Cookies propias</strong>: Son aqu&eacute;llas que se instalan por nuestra web.</li>
                                <li><strong>Cookies de terceros</strong>: Son aqu&eacute;llas que se env&iacute;an a tu equipo bien
                                    desde nuestra web o por un tercero, pero la informaci&oacute;n que recogen las cookies es gestionada
                                    por un tercero distinto de UNIVERSO FEEL / PANACEA.</li>
                            </ul>
                            <p>Seg&uacute;n su&nbsp;<strong>plazo de conservaci&oacute;n</strong>&nbsp;las cookies pueden ser:</p>
                            <ul>
                                <li><strong>Cookies de sesi&oacute;n</strong>: Son aquellas que duran el tiempo que navegas por nuestra
                                    web y se borran cuando ya acabas la navegaci&oacute;n</li>
                                <li><strong>Cookies persistentes</strong>: Quedan almacenadas en tu equipo, por un tiempo m&aacute;s
                                    largo, facilitando as&iacute; el control de las preferencias elegidas sin tener que incorporarlas de
                                    nuevo cada vez que se visitas la web.</li>
                            </ul>
                            <p>Seg&uacute;n su finalidad las cookies pueden ser:</p>
                            <ul>
                                <li><strong>Cookies t&eacute;cnicas</strong>: Son las imprescindibles y estrictamente necesarias para
                                    que y el uso de las diversas opciones y servicios que ofrece una web. Por ejemplo, las de
                                    mantenimiento de sesi&oacute;n, las que permiten utilizar elementos de seguridad, compartir
                                    contenido con redes sociales, etc.</li>
                                <li><strong>Cookies de personalizaci&oacute;n</strong>: Permiten al usuario escoger o personalizar
                                    caracter&iacute;sticas de la p&aacute;gina Web como el idioma, configuraci&oacute;n regional o tipo
                                    de navegador.</li>
                                <li><strong>Cookies anal&iacute;ticas</strong>: Son las utilizadas por la web, para elaborar perfiles de
                                    navegaci&oacute;n y poder conocer las preferencias de los usuarios con el fin de mejorar nuestra
                                    web.</li>
                                <li><strong>Cookies publicitarias / de publicidad</strong>: Permiten la gesti&oacute;n de los espacios
                                    publicitarios en base a criterios concretos. Por ejemplo, la frecuencia de acceso, el contenido
                                    editado, etc. Las cookies de publicidad permiten a trav&eacute;s de la gesti&oacute;n de la
                                    publicidad almacenar informaci&oacute;n del comportamiento a trav&eacute;s de la observaci&oacute;n
                                    de h&aacute;bitos, estudiando los accesos y formando un perfil de preferencias del usuario, para
                                    ofrecerle publicidad relacionada con los intereses de su perfil.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <h2><strong><u>Cookies utilizadas en nuestra web:</u></strong></h2>
                            <p>PANACEA utiliza los siguientes tipos de cookies en la web:</p>
                            <ul>
                                <li><strong>Cookies t&eacute;cnicas</strong>: Permiten al usuario navegar por la Web y usar
                                    funcionalidades.</li>
                                <li><strong>Cookies de personalizaci&oacute;n</strong>: Permiten saber las preferencias del usuario:
                                    idioma, etc</li>
                                <li><strong>Cookies de an&aacute;lisis</strong>: Permiten cuantificar el n&uacute;mero de usuarios que
                                    visitan la Web. Estas cookies permiten medir y analizar la forma en que los usuarios navegan por la
                                    Web. Esta informaci&oacute;n permite a UNIVERSO FEEL / PANACEA mejorar continuamente sus servicios y
                                    la experiencia de los usuarios de la Web.</li>
                            </ul>
                            <p>Esta web, como la mayor&iacute;a de sitios web, puede incluir funcionalidades proporcionadas por
                                terceros.</p>
                            <p>Nuestra web es una web viva y se pueden incluir nuevos dise&ntilde;os o servicios de terceros. Esto puede
                                modificar ocasionalmente la configuraci&oacute;n de cookies y que aparezcan cookies no detalladas de
                                forma pormenorizada en la presente pol&iacute;tica.</p>
                            <h2><strong><u>Informaci&oacute;n y obtenci&oacute;n del consentimiento para la
                                instalaci&oacute;n:</u></strong></h2>
                            <p>UNIVERSO FEEL / PANACEA procurar&aacute; en todo momento establecer mecanismos adecuados para obtener el
                                consentimiento del Usuario para la instalaci&oacute;n de cookies que lo requieran. Cuando un usuario
                                accede a nuestra web aparece un banner en el que se informa de la existencia de cookies y de que, si
                                contin&uacute;a navegando por nuestra p&aacute;gina, presta su consentimiento para la instalaci&oacute;n
                                de cookies.</p>
                            <p>El usuario puede revocar el permiso desde el banner que aparece en la web en la zona izquierda inferior.
                            </p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>&iquest;C&oacute;mo puedo impedir la instalaci&oacute;n de cookies?:</u></strong></h2>
                            <p>El usuario puede configurar su navegador para aceptar, o no, las cookies que recibe o para que el
                                navegador le avise cuando un servidor quiera guardar una cookie. Si se deshabilitan algunas cookies
                                t&eacute;cnicas no se garantiza el correcto funcionamiento de algunas de las utilidades de la web.</p>
                            <p>El usuario podr&aacute; excluir que se almacenen en su terminal las cookies &laquo;anal&iacute;ticas y
                                publicitarias&raquo; de Google Analytics mediante los sistemas de exclusi&oacute;n facilitados por
                                Google Analytics.</p>
                            <p>A continuaci&oacute;n, tambi&eacute;n le facilitamos algunos ejemplos de c&oacute;mo deshabilitar las
                                cookies:</p>
                            <p>Mediante la&nbsp;<strong>configuraci&oacute;n del propio navegador</strong>:</p>
                            <ul>
                                <li><strong>Internet Explorer</strong>:&nbsp;<a
                                    href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies</a>
                                </li>
                                <li><strong>Firefox</strong>:&nbsp;<a
                                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web</a>
                                </li>
                                <li><strong>Safari</strong>:&nbsp;<a
                                    href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a>
                                </li>
                                <li><strong>Chrome</strong>:&nbsp;<a
                                    href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a>
                                </li>
                                <li><strong>Opera</strong>:&nbsp;<a
                                    href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
                                </li>
                            </ul>
                            <p>Para ampliar esta informaci&oacute;n acude a la p&aacute;gina de la Agencia Espa&ntilde;ola de
                                Protecci&oacute;n de Datos que ayuda a configurar la privacidad en redes sociales, navegadores y
                                sistemas operativos m&oacute;viles.</p>
                            <p>Mediante&nbsp;<strong>herramientas de terceros</strong>:</p>
                            <p>Existen herramientas de terceros, disponibles online, que permiten a los usuarios detectar las cookies en
                                cada sitio web que visita y gestionar su desactivaci&oacute;n, por ejemplo:</p>
                            <ul>
                                <li><a href="http://www.ghostery.com/">http://www.ghostery.com</a></li>
                                <li><a href="http://www.youronlinechoices.com/es/">http://www.youronlinechoices.com/es/</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <h2><strong><u>Enlaces a otros sitios web:</u></strong></h2>
                            <p>Si el usuario opta por abandonar nuestro sitio Web a trav&eacute;s de enlaces a otros sitios Web no
                                pertenecientes a nuestra entidad, no nos haremos responsables de las pol&iacute;ticas de privacidad de
                                dichos sitios Web ni de las cookies que estos puedan almacenar en el ordenador del usuario.</p>
                            <h2><strong><u>&iquest;C&oacute;mo recopilamos y usamos las direcciones IP?:</u></strong></h2>
                            <p>Los servidores de la Web podr&aacute;n detectar de manera autom&aacute;tica la direcci&oacute;n IP y el
                                nombre de dominio utilizados por los usuarios.</p>
                            <p>Una direcci&oacute;n IP es un n&uacute;mero asignado autom&aacute;ticamente a un ordenador cuando
                                &eacute;ste se conecta a Internet. Esta informaci&oacute;n permite el posterior procesamiento de los
                                datos con el fin de saber si ha prestado su consentimiento para la instalaci&oacute;n de cookies,
                                realizar mediciones &uacute;nicamente estad&iacute;sticas que permitan conocer el n&uacute;mero de
                                visitas realizadas a la Web, el orden de visitas, el punto de acceso, etc.</p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>Actualizaci&oacute;n de nuestra pol&iacute;tica de cookies:</u></strong></h2>
                            <p>Esta pol&iacute;tica se revisa peri&oacute;dicamente para asegurar su vigencia, por lo que puede ser
                                modificada. Te recomendamos que visite la p&aacute;gina con regularidad donde te informaremos de
                                cualquier actualizaci&oacute;n al respecto.</p>
                            <h2><strong><u>Pol&iacute;tica de Privacidad:</u></strong></h2>
                            <p>En cuanto a la informaci&oacute;n b&aacute;sica de protecci&oacute;n de datos, puedes consultar
                                nuestra&nbsp;<a href="https://panacea.com.es/politica-de-privacidad/">pol&iacute;tica de privacidad</a>.
                            </p>
                        </div>
                    </div>

                    <div class="parrafo">
                        <p>Fecha de Publicación: <br />
                            3 de junio de 2024
                        </p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}