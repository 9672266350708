import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1"


export default function () {
    return (
        <>
            <Header />
            <Helmet>
                <title>PANACEA - Misión</title>
                <link rel="stylesheet" type="text/css" href="/utils/style.css" />
            </Helmet>
            <div>
                <div className="videocontainerout">
                    <div className="videocontainer">
                        <h1>MISIÓN</h1>
                        <video src="./Media/videotriangulos.mp4" autoPlay muted loop playsInline></video>
                    </div>
                </div>

                <div className="parrafo">
                    <p>Unir a personas, expertos, empresas y la administración
                        en un mismo lugar, focalizando al bienestar del cuerpo y la mente, guiándose bajo los valores de:</p>
                </div>
                <div className="texttocenter">
                    <div>
                        <h2>RESPETO</h2>
                    </div>
                    <div>
                        <h3>HONESTIDAD</h3>
                    </div>
                    <div>
                        <h2>LEGALIDAD</h2>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}