// MusicPlayerContext.js
import React, { createContext, useRef, useState, useContext } from 'react';

const MusicPlayerContext = createContext();

export const MusicPlayerProvider = ({ children }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play().catch((err) => console.error("Error al reproducir:", err));
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <MusicPlayerContext.Provider value={{ isPlaying, togglePlay, audioRef }}>
      {children}
      <audio ref={audioRef} loop>
        <source src="/Media/musica2.mp3" type="audio/mpeg" />
        Tu navegador no soporta el elemento de audio.
      </audio>
    </MusicPlayerContext.Provider>
  );
};

export const useMusicPlayer = () => useContext(MusicPlayerContext);
