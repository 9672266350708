import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";

export default function () {
    return (
        <>
            <Helmet>
                <title>PANACEA - Sobre Nosotros</title>
                <link rel="stylesheet" type="text/css" href="/utils/style.css" />
            </Helmet>
            <Header />
            <div>
                <div className="videocontainerout">
                    <div className="videocontainer">
                        <h1>QUÉ ES</h1>
                        <video src="./Media/lineasmorada.mp4" autoPlay muted loop playsInline></video>
                    </div>
                </div>

                <div className="parrafo">
                    <p>Panacea es una solución a una necesidad social: <br />
                        A través de una plataforma online, se integra a todo el sector de la Medicina y la Salud
                        en una herramienta sencilla y accesible.</p>
                </div>

                <div className="videosintro">
                    <div>
                        <h2>ESPAÑOL</h2>
                        <video className="animate" id="myVideo" controls>
                            <source src="./Media/videoespañol.mp4" type="video/mp4"/>
                                Tu navegador no soporta la reproducción de videos HTML5.
                        </video>

                        <h2>ENGLISH</h2>
                        <video className="animate" id="myVideo" controls>
                            <source src="./Media/videoingles.mp4" type="video/mp4"/>
                                Tu navegador no soporta la reproducción de videos HTML5.
                        </video>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}