import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeResearchAnimations } from '../scripts/scriptmorado';

export default function () {
    useEffect(() => {
        initializeResearchAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Emergency</title>
                    <link rel="stylesheet" type="text/css" href="/utils/stylepurple.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>EMERGENCY</h1>
                        <video src="./Media/lineasmoradas.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="textimage" id="textimg">
                        <img src="./Media/emergency.png" alt="" />
                        <p>El contacto automático, y con todo nuestro historial y datos personales, en el caso de una emergencia real, procurando una atención profesional inmediata que puede salvar nuestras vidas.</p>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}