import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";

export default function () {
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Wellness</title>
                    <link rel="stylesheet" type="text/css" href="/utils/styleblue.css" />
                </Helmet>
                <div>
                    <div className="videocontainer">
                        <h1>WELLNESS</h1>
                        <video src="./Media/wellness.mp4" autoPlay muted loop playsInline></video>
                    </div>

                    <div className="parrafo">
                        <p>Espacios dirigidos al ejercicio continuado y sostenido en el tiempo,
                            además de a las personas que buscan el bienestar y la salud física,
                            a partir del desarrollo de una vida sana y la alimentación.</p>
                    </div>

                    <div className="parrafoleft" id="FISIO">
                        <h2>La salud deportiva:</h2>
                        <p>
                            La salud deportiva es una especialidad, o disciplina médica, que se ocupa tanto de la prevención como del tratamiento terapéutico de lesiones derivadas de la práctica deportiva.


                            Por lo tanto, se puede entender que estudia tanto los efectos positivos del ejercicio físico, como los problemas derivados de su uso o abuso, focalizando siempre el buen estado de la salud de las personas, entendida tal cual la define la OMS: estado de equilibrio físico, mental y social, y no sólo la ausencia de enfermedad.
                        </p>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}