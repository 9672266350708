import Header from "../components/Header1";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer1";
import { useEffect } from 'react';
import { initializeAnimations } from '../scripts/script';

export default function () {
    useEffect(() => {
        initializeAnimations();
    }, []);
    return (
        <>
            <div>
                <Header />
                <Helmet>
                    <title>PANACEA - Política de privacidad</title>
                    <link rel="stylesheet" type="text/css" href="/utils/style.css" />
                </Helmet>
                <div>
                    <div class="parrafo">
                        <p>POLÍTICA DE PRIVACIDAD</p>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <p>Universo Feel, S.L. (en adelante, &ldquo;<strong>PANACEA</strong>&rdquo; y/o &ldquo;<strong>UNIVERSO
                                FEEL</strong>&rdquo;), compa&ntilde;&iacute;a que desarrolla la l&iacute;nea de negocio sobre la que
                                se pone en marcha la web de Panacea, con domicilio social en Avda. Alcalde Jos&eacute; Ram&iacute;rez
                                Bethencourt, 13-bajo, 35004. Las Palmas de Gran Canaria. Islas Canarias &ndash; Espa&ntilde;a, con NIF
                                B72990062, en cumplimiento de lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del
                                Consejo de 27 de abril de 2016 relativo a la protecci&oacute;n de las personas f&iacute;sicas y/o
                                jur&iacute;dicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n
                                de estos datos (en adelante RGPD), la Ley Org&aacute;nica 15/1999, de 13 de diciembre, de
                                Protecci&oacute;n de Datos de Car&aacute;cter Personal (en adelante LOPD) y del Real Decreto 1720/2007,
                                de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la LOPD (en adelante RDLOPD), y
                                con el fin de garantizar un correcto uso y tratamiento de los datos personales de los usuarios de la web
                                <a href="http://www.panacea.com.es">www.panacea.com.es</a>, comprometi&eacute;ndose con ofrecer la mayor
                                de las transparencias en los tratamientos de datos, respetando los derechos de sus usuarios y
                                reconociendo la importancia de ofrecer un entorno seguro para la informaci&oacute;n que recopile, pone a
                                disposici&oacute;n de los usuarios este documento explicativo (en adelante, &ldquo;Pol&iacute;tica de
                                Privacidad&rdquo;) en el que se explica la pol&iacute;tica respecto del tratamiento y protecci&oacute;n
                                de los datos de car&aacute;cter personal de aquellas personas f&iacute;sicas y/o jur&iacute;dicas que
                                voluntariamente suministren sus datos de contacto, utilicen el correo electr&oacute;nico para ponerse en
                                contacto con PANACEA y/o UNIVERSO FEEL, utilicen el chat, rellenen los formularios de solicitud de
                                informaci&oacute;n o email sobre informaci&oacute;n en general, utilicen nuestros servicios, as&iacute;
                                como de los padres/tutores o que utilicen cualquier otro servicio que implique la comunicaci&oacute;n de
                                sus datos personales a PANACEA y/o UNIVERSO FEEL.
                            </p>
                            <p>Es responsabilidad del usuario leer de forma previa a comunicar sus datos personales a PANACEA o UNIVERSO
                                FEEL los citados textos informativos establecidos en la web de PANACEA.</p>
                            <p>Esta pol&iacute;tica es aceptada expresamente por el usuario desde el momento en que, para el uso de este
                                sitio o cualquiera de sus servicios, comunique a PANACEA y/o UNIVERSO FEEL sus datos personales dado que
                                dicha comunicaci&oacute;n es voluntaria.</p>
                            <p>El acceso y uso de la Web es gratuito, sin perjuicio de los costes de conexi&oacute;n a Internet
                                contratados por el usuario, e implica para el usuario la aceptaci&oacute;n, sin reserva, de las
                                Condiciones y Pol&iacute;tica de Privacidad y uso.</p>
                            <p>Esta web tiene por objeto principal informar de las actividades, servicios y productos prestados por
                                PANACEA y/o UNIVERSO FEEL, adem&aacute;s de proporcionar la operativa comercial y el contacto con sus
                                clientes-usuarios.</p>
                            <p>El usuario se compromete a hacer un uso correcto de la web de conformidad con la Ley y en las presentes
                                condiciones de uso, y ser&aacute; responsables de los datos e informaci&oacute;n suministrada,
                                as&iacute; como de los perjuicios que pudiesen causar a PANACEA y/o UNIVERSO FEEL, o a terceros, por
                                informaci&oacute;n falsa o inexacta que nos comunique, o el uso inadecuado o difamatorio de la Web, de
                                sus personas (f&iacute;sicas o jur&iacute;dicas), sus miembros, colaboradores o terceros relacionados
                                con los servicios y/o productos que ofrece PANACEA y/o UNIVERSO FEEL.</p>
                            <p>En caso en que el usuario no est&eacute; conforme con esta pol&iacute;tica no deber&aacute; utilizar
                                ning&uacute;n servicio de esta p&aacute;gina Web que requiera la comunicaci&oacute;n de sus datos
                                personales a PANACEA y/o UNIVERSO FEEL.</p>
                            <p>Esta pol&iacute;tica de privacidad es de aplicaci&oacute;n en las operaciones de PANACEA y/o UNIVERSO
                                FEEL en la Uni&oacute;n Europea conforme a las disposiciones de la GDPR y los derechos que en ella se
                                contemplan. En cualquier caso, PANACEA y/o UNIVERSO FEEL realizar&aacute; sus mayores esfuerzos para
                                garantizar un grado de control similar de los datos de los usuarios que no pertenezcan a la Uni&oacute;n
                                Europea.</p>
                            <p>La informaci&oacute;n personal se refiere a todos aquellos datos relacionados con una persona
                                f&iacute;sica o jur&iacute;dica identificada o identificable. En concreto, puede referir, entre otros, a
                                datos como el nombre, la direcci&oacute;n, el g&eacute;nero, la edad, el n&uacute;mero de
                                identificaci&oacute;n fiscal, el c&oacute;digo postal o los datos financieros.</p>
                            <p>Al usar este sitio web usted est&aacute; aceptando la pol&iacute;tica de protecci&oacute;n de datos de la
                                web de PANACEA y/o UNIVERSO FEEL y debe tener presente que esta pol&iacute;tica deja de ser
                                v&aacute;lida cuando accede a otros sitios web a partir de los enlaces que pueda encontrar en la
                                plataforma, ya que no tenemos control sobre las actividades de esos sitios, ni sobre su pol&iacute;tica
                                de protecci&oacute;n de datos.</p>
                            <p>PANACEA y/o UNIVERSO FEEL informa a los usuarios que la pol&iacute;tica de privacidad podr&iacute;a verse
                                modificada en caso de que as&iacute; fuese preciso y necesario. Por tanto, agradecemos que consulte este
                                documento peri&oacute;dicamente para estar al corriente de los cambios que puedan producirse. La fecha
                                de actualizaci&oacute;n muestra la &uacute;ltima vez que este documento ha sido modificado.</p>
                            <p>De igual forma, UNIVERSO FEEL y/o PANACEA informa que, tal cual se explicita en la Web, firma un contrato
                                de confidencial (NDA) con sus usuarios, cuando &eacute;stos requieren servicios espec&iacute;ficos, a
                                fin de proteger tanto la confidencialidad de PANACEA y/o UNIVERSO FEEL, como la del cliente-usuario.</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>Informaci&oacute;n personal recopilada:</u></strong></h2>
                            <p>PANACEA y/o UNIVERSO FEEL s&oacute;lo recopila sus datos personales (tanto como persona f&iacute;sica o
                                jur&iacute;dica, seg&uacute;n el caso) a partir de su consentimiento expreso e inequ&iacute;voco y en
                                cumplimiento con la legislaci&oacute;n vigente.</p>
                            <p>En este sentido, PANACEA y/o UNIVERSO FEEL recopila y procesa la informaci&oacute;n personal a
                                trav&eacute;s de:</p>
                            <ul>
                                <li><strong></strong><strong>Informaci&oacute;n que el usuario facilita</strong>: Informaci&oacute;n
                                    personal que el usuario nos env&iacute;a bien a trav&eacute;s de nuestros canales de
                                    comunicaci&oacute;n interno, mediante el chat, email o por correspondencia.</li>
                                <li><strong></strong><strong>Uso de la p&aacute;gina web</strong>: Mediante el uso de la p&aacute;gina
                                    web de PANACEA y/o UNIVERSO FEEL, podr&iacute;a obtener a trav&eacute;s de las cookies y otras
                                    tecnolog&iacute;as de rastreo, informaci&oacute;n sobre las visitas, la direcci&oacute;n IP,
                                    informaci&oacute;n geogr&aacute;fica, datos de tr&aacute;fico, datos de comunicaciones, de
                                    publicaciones internas y/o de los recursos a los que accede.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <h2><strong><u>Uso de la informaci&oacute;n personal:</u></strong></h2>
                            <p>La normativa europea de protecci&oacute;n de datos sienta las bases por las que las
                                compa&ntilde;&iacute;as pueden procesar la informaci&oacute;n personal:</p>
                            <ul>
                                <li><strong></strong><strong>Consentimiento</strong>: Se requiere un consentimiento inequ&iacute;voco
                                    por parte de los usuarios para poder usar esa informaci&oacute;n.</li>
                                <li><strong></strong><strong>Obligaci&oacute;n legal</strong>: Necesidad de usar la informaci&oacute;n
                                    de los usuarios para cumplir con obligaciones legales.</li>
                                <li><strong>Inter&eacute;s leg&iacute;timo</strong>: Se usa la informaci&oacute;n para lograr un
                                    inter&eacute;s leg&iacute;timo en la que las razones para usarla no supongan un perjuicio para los
                                    derechos de protecci&oacute;n de datos.</li>
                                <li><strong></strong><strong>Ejecutabilidad del contrato</strong>: La informaci&oacute;n es necesaria
                                    para poder ejecutar nuestro contrato con los usuarios cuando &eacute;stos requieran servicios y se
                                    conviertan en clientes-usuarios.</li>
                                <li><strong></strong><strong>Reclamaciones legales</strong>: La informaci&oacute;n es necesaria para
                                    poder defender, perseguir o realizar una reclamaci&oacute;n contra terceras partes.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <p>PANACEA y/o UNIVERSO FEEL utiliza la informaci&oacute;n proporcionada por sus usuarios:</p>
                            <ul>
                                <li><strong>Con fines estad&iacute;sticos</strong>: Los datos personales que usted env&iacute;e a
                                    PANACEA y/o UNIVERSO FEEL nos permite proporcionarle todos los servicios y productos antes descritos
                                    y, si lo desea, informarle sobre nuevos servicios o productos, directamente expuestos en la Web o
                                    con la informaci&oacute;n recopilada a trav&eacute;s del chat, Telegram, WhatsApp o emails enviados
                                    a tal efecto. Con su consentimiento tambi&eacute;n podr&iacute;amos enviar su informaci&oacute;n
                                    personal a otras personas f&iacute;sicas o jur&iacute;dicas que formen parte de PANACEA y/o UNIVERSO
                                    FEEL, o sean colaboradoras de PANACEA y/o UNIVERSO FEEL, &nbsp;y que ofrezcan productos que puedan
                                    ser de su inter&eacute;s. Esta informaci&oacute;n personal tambi&eacute;n puede usarse para generar
                                    estad&iacute;sticas internas para marketing, perfiles de consumidor, estudios demogr&aacute;ficos,
                                    etc. para adaptar nuestros servicios y productos de manera que resulten de su entera
                                    satisfacci&oacute;n. Nuestro objetivo es comprender y prestar un mejor servicio a nuestros usuarios.
                                </li>
                                <li><strong></strong><strong>Para garantizar la seguridad e integridad del sistema</strong>: Los datos
                                    que el usuario nos haya proporcionado pueden ser utilizados para prevenir el fraude y gestionar
                                    riesgos. Adem&aacute;s, informaci&oacute;n sobre direcciones IP, geolocalizaci&oacute;n y otros
                                    estados servir&aacute;n para mejorar la experiencia del usuario, garantizando un sistema estable e
                                    &iacute;ntegro.</li>
                                <li><strong>Para comunicarle productos o servicios que puedan ser de su inter&eacute;s</strong>: Siempre
                                    que el usuario haya elegido recibir las comunicaciones, se les podr&iacute;a enviar comunicaciones
                                    sobre actualizaciones, nuevos servicios o productos de UNIVERSO FEEL y/o PANACEA, y podr&iacute;a
                                    usar esta informaci&oacute;n para comercializar sus productos y servicios, mediante notificaciones
                                    en la propia Web, publicaciones, tel&eacute;fono, correo electr&oacute;nico o publicidad en redes
                                    sociales, en el caso de que as&iacute; lo determinase.</li>
                                <li><strong></strong><strong>Para monitorizar algunas actividades</strong>: Con el prop&oacute;sito de
                                    ofrecer al cliente un servicio de calidad en el que se cumplan los procedimientos, as&iacute; como
                                    los t&eacute;rminos y condiciones.</li>
                                <li><strong></strong><strong>Para informarle sobre cambios</strong>: Para notificar cambios en nuestros
                                    productos y servicios.</li>
                            </ul>
                            <p>Si el usuario ha consentido usar la informaci&oacute;n personal para alguno de los fines descritos
                                anteriormente, se le informa de que tiene la posibilidad de cambiar de opini&oacute;n en cualquier
                                momento enviando un email a&nbsp;<strong>hola@panacea.com.es</strong>, y puede darse de baja y optar a
                                no recibir m&aacute;s comunicaciones si as&iacute; nos lo comunica.</p>
                            <p>La base jur&iacute;dica del tratamiento de todos estos datos por parte de PANACEA y/o UNIVERSO FEEL son
                                tanto el consentimiento del usuario, como el inter&eacute;s leg&iacute;timo mutuo de las partes.</p>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <h2><strong><u>Destinatarios de los datos personales:</u></strong></h2>
                            <p>Los datos personales son usados &uacute;nicamente por PANACEA y/o UNIVERSO FEEL, su equipo y
                                colaboradores, para los fines descritos anteriormente, si bien su informaci&oacute;n puede ser
                                almacenada y procesada tanto en Espa&ntilde;a como en cualquier otro pa&iacute;s donde PANACEA y/o
                                UNIVERSO FEEL, su equipo, colaboradores o los representantes o afiliados de PANACEA y/o UNIVERSO FEEL
                                tengan instalaciones. Esta informaci&oacute;n est&aacute; protegida y se almacena en un entorno seguro y
                                controlado.</p>
                            <p>PANACEA y/o UNIVERSO FEEL informa que no se producen transferencias a terceros pa&iacute;ses u
                                organizaciones internacionales que no ofrezcan las garant&iacute;as adecuadas.</p>
                            <p>Asimismo, PANACEA y/o UNIVERSO FEEL podr&iacute;a realizar comunicaciones a otras entidades, ya sea por
                                obligaci&oacute;n o imperativo legal, como podr&iacute;a ser a:</p>
                            <ul>
                                <li>Las Fuerzas y Cuerpos de Seguridad del Estado;</li>
                                <li>Juzgados y Tribunales;</li>
                                <li>Administraciones P&uacute;blicas o Autoridades que as&iacute; lo requieran, y siempre dentro del
                                    ejercicio de sus competencias.</li>
                                <li>Proveedores de servicios tecnol&oacute;gicos e inform&aacute;ticos.</li>
                                <li>Proveedores y colaboradores externos relacionados con marketing y publicidad.</li>
                                <li>Proveedores de servicios de log&iacute;stica.</li>
                                <li>Entidades financieras.</li>
                                <li>Proveedores de servicios de asesoramiento jur&iacute;dico, fiscal o contable.</li>
                                <li>Empresas de medici&oacute;n de audiencias.</li>
                                <li>Empresas cuya finalidad es realizar perfiles de la navegaci&oacute;n.</li>
                            </ul>
                            <p>Para conocer los principales proveedores que acceden a datos puede visitar nuestra&nbsp;<a
                                href="https://panacea.com.es/politica-de-cookies/">pol&iacute;tica de Cookies</a>.</p>
                            <p>Igualmente, para la creaci&oacute;n de esta Web se han utilizado recursos t&eacute;cnicos de WordPress y
                                su ecosistema de plugins, adem&aacute;s de fotograf&iacute;as y v&iacute;deos gratuitos de Freepik,
                                Pixabay, Veedzy y Unsplash.</p>
                            <h2>Derechos de los usuarios sobre la informaci&oacute;n personal:</h2>
                            <ul>
                                <li><strong></strong><strong>Derecho de Acceso</strong>: Derecho que tienen los usuarios a obtener
                                    informaci&oacute;n sobre si sus datos est&aacute;n siendo objeto de tratamiento, la finalidad del
                                    tratamiento, el origen de los datos y las comunicaciones/cesiones realizadas o previstas.</li>
                                <li><strong></strong><strong>Derecho de Rectificaci&oacute;n</strong>: Derecho que tienen los usuarios a
                                    pedir que se modifiquen aquellos datos que resulten ser inexactos o incompletos.</li>
                                <li><strong></strong><strong>Derecho de Oposici&oacute;n</strong>: Derecho de los usuarios a solicitar
                                    que no se lleve a cabo un determinado tratamiento de datos de car&aacute;cter personal. Los
                                    interesados s&oacute;lo podr&aacute;n oponerse a tratamientos de datos que se realicen por
                                    inter&eacute;s leg&iacute;timo.</li>
                                <li><strong></strong><strong>Derecho de Portabilidad</strong>: Derecho de los usuarios a solicitar que
                                    se facilite sus datos a otra entidad, o a s&iacute; mismo, si as&iacute; lo solicitase, en un
                                    formato estructurado, de uso com&uacute;n y lectura mec&aacute;nica.</li>
                                <li><strong></strong><strong>Derecho de Limitaci&oacute;n de Tratamientos</strong>: Derecho a solicitar
                                    por parte del interesado que no se apliquen a sus datos personales las operaciones de tratamiento
                                    que en cada caso corresponder&iacute;an. Este derecho s&oacute;lo podr&aacute; ejercitarse en
                                    algunos casos restrictivos.</li>
                                <li><strong></strong><strong>Derecho de Supresi&oacute;n</strong>: Derecho que permite a las personas
                                    dirigirse a la entidad para que supriman sus datos personales cuando ya no sean necesarios, cuando
                                    se retire el consentimiento, hayan sido tratados il&iacute;citamente, etc.</li>
                            </ul>
                            <p>PANACEA y/o UNIVERSO FEEL informa al usuario de la posibilidad del ejercicio de los derechos antes
                                expuestos, en los t&eacute;rminos dispuestos en la legislaci&oacute;n vigente. Para ello, de conformidad
                                con lo dispuesto en RGPD y la LOPD podr&aacute; ejercer sus derechos a trav&eacute;s de correo ordinario
                                a la direcci&oacute;n: Avda. Alcalde Jos&eacute; Ram&iacute;rez Bethencourt, 13-bajo, 35004. Las Palmas
                                de Gran Canaria. Islas Canarias &ndash; Espa&ntilde;a, o a trav&eacute;s de un correo electr&oacute;nico
                                a&nbsp;<strong>hola@panacea.com.es</strong>&nbsp;con el asunto &ldquo;<strong>Derecho de Privacidad y
                                    Datos Personales</strong>&rdquo;, aportando fotocopia del DNI o documento equivalente y concretando
                                su solicitud.</p>
                        </div>
                    </div>

                    <div class="parrafoformat2">
                        <div id="format">
                            <h2><strong><u>Tiempos de conservaci&oacute;n de los datos:</u></strong></h2>
                            <p>PANACEA y/o UNIVERSO FEEL conservar&aacute; los datos personales durante el tiempo necesario para cumplir
                                las finalidades descritas.</p>
                            <p>Una vez finalizadas las mismas, y siempre y cuando el usuario no haya ejercido su derecho de su
                                supresi&oacute;n, los datos ser&aacute;n conservados durante los plazos legales que en cada caso
                                resulten de aplicaci&oacute;n en base a, tipo de datos y la finalidad de los mismos.</p>
                        </div>
                    </div>

                    <div class="parrafoformat">
                        <div id="format">
                            <h2><strong><u>Contratos celebrados a trav&eacute;s de la Web o por medios digitales:</u></strong></h2>
                            <p>La LSSI en su art&iacute;culo 23 resuelve que los contratos celebrados por v&iacute;a electr&oacute;nica
                                producen todos los efectos previstos por el ordenamiento jur&iacute;dico, cuando concurren el objeto, la
                                causa y el consentimiento y se rigen por el C&oacute;digo Civil, de Comercio y el resto de normas de
                                aplicaci&oacute;n aplicables al tr&aacute;fico comercial, incluidas las normas de car&aacute;cter fiscal
                                y tributario, las de consumo y las de ordenaci&oacute;n de la actividad comercial.</p>
                            <p>Por ello PANACEA y/o UNIVERSO FEEL expone que, en el caso de oferta de productos y/o servicios a
                                trav&eacute;s de nuestro sitio Web o por medios digitales, se explicitan las condiciones generales para
                                el cliente-usuario.</p>
                            <p>En tanto los productos y/o servicios ofrecidos por PANACEA y/o UNIVERSO FEEL dependen de las exigencias
                                del cliente-usuario en dicho contrato, y seg&uacute;n sus necesidades, se expondr&aacute;
                                informaci&oacute;n clara y detallada de los precios, descripci&oacute;n del producto y/o servicio
                                ofrecido, y los distintos tr&aacute;mites que deben seguirse para celebrar el contrato, entre otros:</p>
                            <ul>
                                <li>Condiciones de la contrataci&oacute;n (plazos, entrega, forma de pago&hellip;).</li>
                                <li>Caracter&iacute;sticas principales de los productos y/o servicios.</li>
                                <li>Identidad del responsable, direcci&oacute;n completa del establecimiento y el n&uacute;mero de
                                    tel&eacute;fono y direcci&oacute;n de correo electr&oacute;nico del mismo.</li>
                                <li>Precio total de los productos y/o servicios, incluidos los impuestos y tasas, o, si el precio no
                                    puede calcularse razonablemente de antemano por la naturaleza de los bienes o de los servicios, la
                                    forma en que se determina el precio.</li>
                                <li>Cualquier gasto asociado.</li>
                                <li>Formas de pago y entrega.</li>
                                <li>Garant&iacute;a legal de los productos y servicios.</li>
                                <li>Idioma.</li>
                                <li>Indicaci&oacute;n expresa si el cliente no tiene derecho de desistimiento seg&uacute;n el
                                    art&iacute;culo 103 de la Ley General para la Defensa de los Consumidores y Usuarios.</li>
                                <li>Si la empresa est&aacute; acogida a c&oacute;digos de conducta y la posibilidad de recurrir a
                                    mecanismos extrajudiciales de soluci&oacute;n de conflictos.</li>
                            </ul>
                            <p>Igualmente, y siguiendo las directrices marcadas por la LSSI, se deber&aacute; confirmar la
                                contrataci&oacute;n del servicio, a trav&eacute;s de la firma real o digital del contrato, si bien se
                                considerar&aacute; el inicio del trabajo en el momento en que sea confirmada la aceptaci&oacute;n del
                                servicio y/o su petici&oacute;n de forma expl&iacute;cita a trav&eacute;s de cualquiera de los medios de
                                comunicaci&oacute;n habitual (email, chat, Telegram, WhatsApp&hellip;) o mediante un correo
                                electr&oacute;nico de confirmaci&oacute;n o pantalla de confirmaci&oacute;n en el chat.</p>
                            <h2><strong><u>Cambios en la pol&iacute;tica de privacidad:</u></strong></h2>
                            <p>Desde PANACEA y/o UNIVERSO FEEL informamos que podemos cambiar el contenido de nuestra p&aacute;gina web
                                y, consecuentemente, nuestra pol&iacute;tica de privacidad o la forma en que usamos las cookies, cada
                                cierto tiempo. Por este motivo recomendamos a nuestros usuarios que visiten nuestra&nbsp;<a
                                    href="https://panacea.com.es/politica-de-privacidad/">Pol&iacute;tica de Privacidad</a>&nbsp;y
                                la&nbsp;<a href="https://panacea.com.es/politica-de-cookies/">Pol&iacute;tica de Cookies</a>&nbsp;de
                                forma peri&oacute;dica.</p>
                        </div>
                    </div>

                    <div class="parrafo">
                        <p>
                            En cualquier caso, si los cambios fueran materiales se informará a los usuarios a través de nuestra página web. <br />
                            Fecha de Publicación: <br />
                            3 de junio de 2024
                        </p>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}